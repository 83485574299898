import { useState } from "react";
import { SelectType } from "../../QueryConstructor.types";
import { CSSExpressionContainer } from "../../QueryConstructor.styles";
import { SelectModal } from "./SelectModal";

export const Select = ({
  data,
  onSaveChanges,
  tableName,
  deleteHandler,
  dataSourceId,
  widgetType,
}: {
  data: SelectType;
  onSaveChanges: (arg: SelectType, initialState: SelectType) => void;
  tableName: string;
  deleteHandler: (arg: SelectType) => void;
  dataSourceId: string;
  widgetType: "Diagram" | "Default" | "Filter" | "Table";
}) => {
  const [open, setOpen] = useState(
    !data.isExtended && data.parts[0].column === null ? true : false
  );
  const handleOpen = () => setOpen(true);

  return (
    <>
      <CSSExpressionContainer onClick={handleOpen}>
        Expr:
        <span>{data.alias}</span>
      </CSSExpressionContainer>

      <SelectModal
        data={data}
        onSaveChanges={onSaveChanges}
        deleteHandler={deleteHandler}
        open={open}
        setOpen={setOpen}
        tableName={tableName}
        dataSourceId={dataSourceId}
        widgetType={widgetType}
      />
    </>
  );
};
