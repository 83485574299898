import { useState, useEffect, useReducer, useMemo } from "react";
import {
  DbaButton,
  DbaQueryCommands,
  DbaCheckbox,
  DbaSnackbar,
} from "../../../DbaComponents";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { CSSDataContainer, CSSStepsContainer } from "../StyledComponents";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { MapLayerType } from "../../../features/serviceSlices/MapLayer/Types";
import {
  ENUM,
  QueryCommandObject,
  TFilterAddressRestriction,
} from "../../../features/serviceSlices/SharedTypes";
import { WidgetTypes } from "../../../features/serviceSlices/Widget/Types";
import {
  widgetLinksReducer,
  initialWidgetLinksState,
} from "./reducers/widgetLinksReducer";
import {
  widgetRelatedReportsReducer,
  initialWidgetRelatedReportsState,
} from "./reducers/widgetRelatedReportsReducer";
import {
  useCreateDataWidgetMutation,
  useUpdateDataWidgetMutation,
  useCreateDiagramWidgetMutation,
  useUpdateDiagramWidgetMutation,
  useCreateTableWidgetMutation,
  useUpdateTableWidgetMutation,
  useGetDataWidgetMutation,
  useCreateFilterWidgetMutation,
  useUpdateFilterWidgetMutation,
  useLazyGetFilterWidgetQuery,
  useCreateMapWidgetMutation,
  useUpdateMapWidgetMutation,
  useLazyGetMapWidgetQuery,
  useGetAllReferenceHeadersQuery,
  useCreateVideoWidgetMutation,
  useUpdateVideoWidgetMutation,
  useGetVideoWidgetMutation,
  useGetDiagramWidgetMutation,
  useGetTableWidgetMutation,
} from "../../../features/serviceSlices/serviceHooks";
import { AddWidgetToDashboard } from "../components/AddWidgetToDashboard";
import { AddButton } from "../../../components/common";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DoneIcon from "@mui/icons-material/Done";
import { FirstStepBaseSettings } from "../Steps/FirstStepBaseSettings";
import { MapWidgetSecondStep } from "../Steps/SecondStep/MapWidgetSecondStep";
import { Stack } from "@mui/material";
import { WidgetLinksGenerator } from "../WidgetLinks/WidgetLinksGenerator";
import { WidgetRelatedReportsGenerator } from "../WidgetRelatedReports/WidgetRelatedReportsGenerator";
import { getReferenceHeaderResponse } from "../../../features/serviceSlices/ReferenceHeader/Types";
import constants from "../../../utils/constants";
import { TFilterDefaultValueFromJson } from "../../../features/serviceSlices/SharedTypes";
import { parseJSON } from "../../../utils/helpers/functions";

const createWidgetStepsConfig: { [key: string]: string[] } = {
  Common: ["Основные настройки", "Параметры команд запроса", "Выбор дашборда"],
  Map: ["Основные настройки", "Объекты на карте", "Выбор дашборда"],
  Video: ["Основные настройки", "Выбор дашборда"],
  Filter: ["Основные настройки", "Выбор дашборда"],
};

export const Widget = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const type = searchParams.get("widgetType");
  const params = useParams<Record<string, string | undefined>>();
  const editMode = Boolean(params.id);

  const [createDataWidget, createDataWidgetResponse] =
    useCreateDataWidgetMutation();
  const [updateDataWidget, updateDataWidgetResponse] =
    useUpdateDataWidgetMutation();
  const [getDataWidget, dataWidgetResponse] = useGetDataWidgetMutation();

  const [createDiagramWidget, createDiagramWidgetResponse] =
    useCreateDiagramWidgetMutation();
  const [updateDiagramWidget, updateDiagramWidgetResponse] =
    useUpdateDiagramWidgetMutation();
  const [getDiagramWidget, diagramWidgetResponse] =
    useGetDiagramWidgetMutation();

  const [createTableWidget, createTableWidgetResponse] =
    useCreateTableWidgetMutation();
  const [updateTableWidget, updateTableWidgetResponse] =
    useUpdateTableWidgetMutation();
  const [getTableWidget, tableWidgetResponse] = useGetTableWidgetMutation();

  const [createFilterWidget, createFilterWidgetResponse] =
    useCreateFilterWidgetMutation();
  const [updateFilterWidget, updateFilterWidgetResponse] =
    useUpdateFilterWidgetMutation();
  const [getFilterWidget, getFilterWidgetResponse] =
    useLazyGetFilterWidgetQuery();

  const [createMapWidget, createMapWidgetResponse] =
    useCreateMapWidgetMutation();
  const [updateMapWidget, updateMapWidgetResponse] =
    useUpdateMapWidgetMutation();
  const [getMapWidget, mapWidgetReponse] = useLazyGetMapWidgetQuery();

  const [createVideoWidget, createVideoWidgetResponse] =
    useCreateVideoWidgetMutation();
  const [updateVideoWidget, updateVideoWidgetResponse] =
    useUpdateVideoWidgetMutation();
  const [getVideoWidget, videoWidgetResponse] = useGetVideoWidgetMutation();

  const referenceHeaders = useGetAllReferenceHeadersQuery();

  const [activeStep, setActiveStep] = useState(0);

  //1st step
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [description, setDescription] = useState("");
  const [widgetType, setWidgetType] = useState<{
    id: WidgetTypes;
    name: string;
  } | null>(null);
  const referenceHeaderInitialValue = useMemo<getReferenceHeaderResponse>(
    () => ({
      id: "",
      name: "",
      headers: [{}],
    }),
    []
  );
  const [referenceHeader, setReferenceHeader] =
    useState<getReferenceHeaderResponse>(referenceHeaderInitialValue);
  const [widgetRelatedReports, dispatchWidgetRelatedReports] = useReducer(
    widgetRelatedReportsReducer,
    initialWidgetRelatedReportsState
  );
  const [roundToNumber, setRoundToNumber] = useState(0);
  const [measure, setMeasure] = useState<ENUM | null>(null);
  const [filterType, setFilterType] = useState<string | null>(null);
  const [filterDefaultValue, setFilterDefaultValue] =
    useState<TFilterDefaultValueFromJson>();
  const [filterRestrictions, setFiltersRestrictions] = useState<
    TFilterAddressRestriction[]
  >([]);

  const [variable, setVariable] = useState("");
  const [clustering, setClustering] = useState(constants.mapDefaultClustering);
  const [isDynamicPositioning, setIsDynamicPositioning] =
    useState<boolean>(true);
  const [isShowTooltips, setIsShowTooltips] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState(constants.mapDefaultZoomLevel);
  const [latitude, setLatitude] = useState(constants.mapDefaultLatitude);
  const [longitude, setLongitude] = useState(constants.mapDefaultLongitude);
  const [mapLayers, setMapLayers] = useState<MapLayerType[]>([]);
  const [activeMapLayer, setActiveMapLayer] = useState<MapLayerType | null>(
    null
  );
  const [videoSourceUrl, setVideoSourceUrl] = useState<string>("");
  //2nd step
  // queriesCommands может быть также и на первом шаге для некоторых типов виджетов
  const [queriesCommands, setQueriesCommands] = useState<
    QueryCommandObject[] | []
  >([]);
  const [useQueryForPrevValue, setUseQueryForPrevValue] = useState(false);
  const [queryCommandPrevValue, setQueryCommandPrevValue] = useState<
    QueryCommandObject[] | []
  >([]);

  //3rd step
  const [widgetID, setWidgetID] = useState(null);
  const [widgetLinks, dispatchWidgetLinks] = useReducer(
    widgetLinksReducer,
    initialWidgetLinksState
  );

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  useEffect(() => {
    if (editMode && params.id && type) {
      const id = params.id;

      switch (type as WidgetTypes) {
        case "Default":
          getDataWidget({ id });
          break;
        case "Diagram":
          getDiagramWidget({ id });
          break;
        case "Table":
          getTableWidget({ id });
          break;
        case "Filter":
          getFilterWidget({ id });
          break;
        case "Map":
          getMapWidget({ widgetID: id });
          break;
        case "Video":
          getVideoWidget({ id });
          break;
        default:
          return;
      }
    }
  }, [
    editMode,
    getDataWidget,
    getDiagramWidget,
    getTableWidget,
    getFilterWidget,
    getMapWidget,
    getVideoWidget,
    location.hash,
    params.id,
    type,
  ]);

  useEffect(() => {
    if (mapLayers) {
      const mapLayer = mapLayers?.find(
        (mapLayer) => mapLayer?.id === activeMapLayer?.id
      );
      mapLayer === undefined && setActiveMapLayer(null);
    }
  }, [mapLayers, activeMapLayer?.id]);

  useEffect(() => {
    switch (type as WidgetTypes) {
      case "Default":
        if (dataWidgetResponse.data) {
          setName(dataWidgetResponse.data?.name);
          setLabel(dataWidgetResponse.data?.label);
          setDescription(dataWidgetResponse.data?.description);
          setQueriesCommands(dataWidgetResponse.data?.queriesCommands);
          setUseQueryForPrevValue(
            dataWidgetResponse.data?.useQueryForPrevValue
          );
          setQueryCommandPrevValue(
            dataWidgetResponse.data.queryCommandPrevValue
          );
          setRoundToNumber(dataWidgetResponse.data.roundToNumber);
          dispatchWidgetLinks({
            type: "loadWidgetLinks",
            payload: dataWidgetResponse.data.widgetLinks,
          });
          dispatchWidgetRelatedReports({
            type: "loadWidgetRelatedReports",
            payload: dataWidgetResponse.data?.widgetReports ?? [],
          });
        }
        break;
      case "Diagram":
        if (diagramWidgetResponse.data) {
          setName(diagramWidgetResponse.data?.name);
          setLabel(diagramWidgetResponse.data?.label);
          setDescription(diagramWidgetResponse.data?.description);
          setQueriesCommands(diagramWidgetResponse.data?.queriesCommands);
          setRoundToNumber(diagramWidgetResponse.data.roundToNumber);
          dispatchWidgetLinks({
            type: "loadWidgetLinks",
            payload: diagramWidgetResponse.data.widgetLinks,
          });
          dispatchWidgetRelatedReports({
            type: "loadWidgetRelatedReports",
            payload: diagramWidgetResponse.data?.widgetReports ?? [],
          });
        }
        break;
      case "Table":
        // нет выхода, напутано с типами, нужно разбираться, зато referenceHeaderFromList работает с подсказками TS
        // @ts-ignore
        const referenceHeaderFromList: getReferenceHeaderResponse | undefined =
          referenceHeaders.data?.find(
            (item) => item.id === tableWidgetResponse.data?.referenceHeaderID
          );
        if (tableWidgetResponse.data) {
          setName(tableWidgetResponse.data?.name);
          setLabel(tableWidgetResponse.data?.label);
          setDescription(tableWidgetResponse.data?.description);
          setReferenceHeader(
            referenceHeaderFromList ?? referenceHeaderInitialValue
          );
          setQueriesCommands(tableWidgetResponse.data?.queriesCommands);
          dispatchWidgetLinks({
            type: "loadWidgetLinks",
            payload: tableWidgetResponse.data.widgetLinks,
          });
          dispatchWidgetRelatedReports({
            type: "loadWidgetRelatedReports",
            payload: tableWidgetResponse.data?.widgetReports ?? [],
          });
        }
        break;
      case "Filter":
        if (getFilterWidgetResponse.data) {
          setName(getFilterWidgetResponse.data?.name);
          setLabel(getFilterWidgetResponse.data?.label);
          setDescription(getFilterWidgetResponse.data?.description);
          setQueriesCommands(getFilterWidgetResponse.data?.queriesCommands);
          setVariable(getFilterWidgetResponse.data.variable);
          setFilterType(getFilterWidgetResponse.data?.filterType);
          setFilterDefaultValue(
            parseJSON(getFilterWidgetResponse.data?.defaultValue)
          );
          dispatchWidgetLinks({
            type: "loadWidgetLinks",
            payload: getFilterWidgetResponse.data.widgetLinks,
          });
          dispatchWidgetRelatedReports({
            type: "loadWidgetRelatedReports",
            payload: getFilterWidgetResponse.data?.widgetReports ?? [],
          });
          setFiltersRestrictions(
            getFilterWidgetResponse.data?.addressRestrictions
          );
        }
        break;
      case "Map":
        if (mapWidgetReponse.data) {
          setName(mapWidgetReponse.data?.name);
          setLabel(mapWidgetReponse.data?.label);
          setDescription(mapWidgetReponse.data?.description);
          setClustering(mapWidgetReponse.data.clustering);
          setIsDynamicPositioning(mapWidgetReponse.data.isPositioningEnable);
          setIsShowTooltips(mapWidgetReponse.data?.isShowTooltips);
          setZoomLevel(mapWidgetReponse.data.zoomLevel);
          setLatitude(mapWidgetReponse.data.latitude);
          setLongitude(mapWidgetReponse.data.longitude);
          setMapLayers(mapWidgetReponse.data.mapLayers);
          setActiveMapLayer(
            mapWidgetReponse.data.mapLayers.find(
              (mapLayer) => mapLayer.isMain === true
            )
          );
          dispatchWidgetLinks({
            type: "loadWidgetLinks",
            payload: mapWidgetReponse.data.widgetLinks,
          });
          dispatchWidgetRelatedReports({
            type: "loadWidgetRelatedReports",
            payload: mapWidgetReponse.data?.widgetReports ?? [],
          });
        }
        break;
      case "Video":
        if (videoWidgetResponse.data) {
          setName(videoWidgetResponse.data?.name);
          setLabel(videoWidgetResponse.data?.label);
          setDescription(videoWidgetResponse.data?.description);
          // setReferenceHeader(
          //   referenceHeaderFromList ?? referenceHeaderInitialValue
          // );
          // setQueriesCommands(videoWidgetResponse.data?.queriesCommands);
          setVideoSourceUrl(videoWidgetResponse.data?.sourceUrl);
          dispatchWidgetLinks({
            type: "loadWidgetLinks",
            payload: videoWidgetResponse.data.widgetLinks,
          });
          dispatchWidgetRelatedReports({
            type: "loadWidgetRelatedReports",
            payload: videoWidgetResponse.data?.widgetReports ?? [],
          });
        }
        break;
      default:
        return;
    }
  }, [
    dataWidgetResponse.data,
    diagramWidgetResponse.data,
    tableWidgetResponse.data,
    referenceHeaderInitialValue,
    referenceHeaders.data,
    getDataWidget,
    getDiagramWidget,
    getTableWidget,
    getVideoWidget,
    getFilterWidgetResponse.data,
    mapWidgetReponse.data,
    videoWidgetResponse.data,
    type,
  ]);

  const saveHandler = () => {
    switch (widgetType?.id) {
      case "Default":
        const defaultWidgetObj = {
          label: label.trim(),
          name: name.trim(),
          description: description.trim(),
          measureID: measure?.id!,
          queriesCommands,
          queryCommandPrevValue,
          useQueryForPrevValue,
          roundToNumber,
          widgetLinks: widgetLinks ?? [],
          widgetReports: (widgetRelatedReports ?? []).map((item) => item.id),
        };
        editMode
          ? updateDataWidget({ ...defaultWidgetObj, id: params.id! })
          : createDataWidget(defaultWidgetObj);
        break;
      case "Diagram":
        const diagramWidgetObj = {
          label: label.trim(),
          name: name.trim(),
          description: description.trim(),
          measureID: measure?.id!,
          queriesCommands,
          roundToNumber,
          widgetLinks: widgetLinks ?? [],
          widgetReports: (widgetRelatedReports ?? []).map((item) => item.id),
        };
        editMode
          ? updateDiagramWidget({ ...diagramWidgetObj, id: params.id! })
          : createDiagramWidget(diagramWidgetObj);
        break;
      case "Table":
        const tableWidgetObj = {
          label: label.trim(),
          name: name.trim(),
          description: description.trim(),
          queriesCommands,
          referenceHeaderID: referenceHeader?.id ? referenceHeader?.id : null,
          widgetLinks: widgetLinks ?? [],
          widgetReports: (widgetRelatedReports ?? []).map((item) => item.id),
        };
        editMode
          ? updateTableWidget({ ...tableWidgetObj, id: params.id! })
          : createTableWidget(tableWidgetObj);
        break;
      case "Filter":
        const filterWidgetObj = {
          label: label.trim(),
          name: name.trim(),
          description: description.trim(),
          queriesCommands,
          variable,
          widgetLinks: widgetLinks ?? [],
          widgetReports: (widgetRelatedReports ?? []).map((item) => item.id),
          filterType: filterType!,
          defaultValue: filterDefaultValue?.value
            ? JSON.stringify(filterDefaultValue)
            : undefined,
          addressRestrictions: filterRestrictions,
        };
        editMode
          ? updateFilterWidget({ ...filterWidgetObj, id: params.id! })
          : createFilterWidget(filterWidgetObj);
        break;
      case "Map":
        const mapObject = {
          label: label.trim(),
          name: name.trim(),
          description: description.trim(),
          clustering,
          isPositioningEnable: isDynamicPositioning,
          isShowTooltips,
          zoomLevel,
          latitude,
          longitude,
          mapLayerTypes: mapLayers?.map((mapLayer) => mapLayer.id),
          mainMapLayerType: activeMapLayer?.id,
          widgetLinks: widgetLinks ?? [],
          widgetReports: (widgetRelatedReports ?? []).map((item) => item.id),
        };
        editMode
          ? updateMapWidget({ ...mapObject, id: params.id! })
          : createMapWidget(mapObject);
        break;
      case "Video":
        const videoWidgetObj = {
          label: label.trim(),
          name: name.trim(),
          description: description.trim(),
          // queriesCommands,
          // referenceHeaderID: referenceHeader?.id ? referenceHeader?.id : null,
          sourceUrl: videoSourceUrl,
          widgetLinks: widgetLinks ?? [],
          widgetReports: (widgetRelatedReports ?? []).map((item) => item.id),
        };
        editMode
          ? updateVideoWidget({ ...videoWidgetObj, id: params.id! })
          : createVideoWidget(videoWidgetObj);
        break;
      default:
        return "";
    }
  };

  const isFirstStepDisabled = () => {
    if (!widgetType?.id || !name.trim() || !label.trim()) {
      return true;
    }
    switch (widgetType.id) {
      case "Default":
        return measure === null || !(roundToNumber >= 0 && roundToNumber <= 7);
      case "Diagram":
        return measure === null;
      case "Filter":
        return !Boolean(filterType) || variable === "";
      case "Map":
        return (
          mapLayers?.length === 0 ||
          activeMapLayer?.id.length === undefined ||
          !latitude ||
          !longitude
        );
      case "Video":
        return !videoSourceUrl;
      default:
        return false;
    }
  };

  const isShownQueryCommand = (): boolean =>
    !(
      widgetType?.id === "Filter" &&
      (filterType === "Input" || filterType === "Address")
    ) && widgetType?.id !== "Video";

  const disabledCheck = () => {
    switch (activeStep) {
      case 0:
        return activeStep === 0 && isFirstStepDisabled();
      case 1:
        return !isShownQueryCommand()
          ? false
          : (activeStep === 1 &&
              useQueryForPrevValue === true &&
              queryCommandPrevValue.length === 0) ||
              queriesCommands.length === 0;

      default:
        return false;
    }
  };

  const createOrUpdateWidgetSuccess =
    createDataWidgetResponse.isSuccess ||
    createDiagramWidgetResponse.isSuccess ||
    updateDiagramWidgetResponse.isSuccess ||
    createTableWidgetResponse.isSuccess ||
    updateTableWidgetResponse.isSuccess ||
    updateDataWidgetResponse.isSuccess ||
    updateDataWidgetResponse.isSuccess ||
    createFilterWidgetResponse.isSuccess ||
    updateFilterWidgetResponse.isSuccess ||
    createMapWidgetResponse.isSuccess ||
    updateMapWidgetResponse.isSuccess ||
    createVideoWidgetResponse.isSuccess ||
    updateVideoWidgetResponse.isSuccess;

  const savedWidgetId =
    createDataWidgetResponse.data?.id ||
    createDiagramWidgetResponse.data?.id ||
    createTableWidgetResponse.data?.id ||
    updateDataWidgetResponse.data?.id ||
    updateDiagramWidgetResponse.data?.id ||
    updateTableWidgetResponse.data?.id ||
    createFilterWidgetResponse.data?.id ||
    updateFilterWidgetResponse.data?.id ||
    createMapWidgetResponse.data?.id ||
    updateMapWidgetResponse.data?.id ||
    createVideoWidgetResponse.data?.id ||
    updateVideoWidgetResponse.data?.id;

  // Тут линтер неправильно отрабатывает, ругается что useEffect используется внутри условия, хотя это
  // очевидно не так.
  // Если удалить одну из строк сравнения тут
  // {activeStep === 1 &&
  // widgetType?.id !== "Map" &&
  // widgetType?.id !== "Video" &&
  // widgetType?.id !== "Filter" ? (
  //   <AddButton
  // то ошибка пропадает.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (createOrUpdateWidgetSuccess) {
      setWidgetID(savedWidgetId);
      setActiveStep((prevActiveStep) =>
        ["Video", "Filter"].includes(widgetType?.id!) ? 2 : prevActiveStep + 1
      );
    }
  }, [widgetType?.id, createOrUpdateWidgetSuccess, savedWidgetId]);

  const dataFetchError =
    diagramWidgetResponse.isError ||
    tableWidgetResponse.isError ||
    dataWidgetResponse.isError ||
    getFilterWidgetResponse.isError ||
    mapWidgetReponse.isError ||
    videoWidgetResponse.isError;

  const widgetTypeResponse =
    diagramWidgetResponse.data?.widgetType ||
    tableWidgetResponse.data?.widgetType ||
    dataWidgetResponse.data?.widgetType ||
    getFilterWidgetResponse.data?.widgetType ||
    mapWidgetReponse.data?.widgetType ||
    videoWidgetResponse.data?.widgetType;

  const measureResponse =
    diagramWidgetResponse.data?.measureID || dataWidgetResponse.data?.measureID;

  const dataFetchSuccess =
    !editMode ||
    diagramWidgetResponse.isSuccess ||
    tableWidgetResponse.isSuccess ||
    dataWidgetResponse.isSuccess ||
    getFilterWidgetResponse.isSuccess ||
    mapWidgetReponse.isSuccess ||
    videoWidgetResponse.isSuccess;

  const dataLoading =
    diagramWidgetResponse.isLoading ||
    tableWidgetResponse.isLoading ||
    dataWidgetResponse.isLoading ||
    getFilterWidgetResponse.isLoading ||
    mapWidgetReponse.isLoading ||
    videoWidgetResponse.isLoading;

  const createOrUpdateWidgetError =
    createDataWidgetResponse.isError ||
    createDiagramWidgetResponse.isError ||
    createTableWidgetResponse.isError ||
    updateDataWidgetResponse.isError ||
    updateDiagramWidgetResponse.isError ||
    updateTableWidgetResponse.isError ||
    createFilterWidgetResponse.isError ||
    updateFilterWidgetResponse.isError ||
    createMapWidgetResponse.isError ||
    updateMapWidgetResponse.isError ||
    createVideoWidgetResponse.isError ||
    updateVideoWidgetResponse.isError;

  const steps =
    createWidgetStepsConfig[widgetType?.id!] ??
    createWidgetStepsConfig["Common"];
  return (
    <CSSDataContainer>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {
        <>
          <Stack direction="row" spacing={2} sx={{ mt: "1rem" }}>
            {activeStep === 0 ? (
              <DbaButton
                variant="contained"
                color="error"
                text="exit"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIcon />}
              />
            ) : null}
            {activeStep === 0 && widgetType?.id === "Map" ? (
              <AddButton
                status={
                  updateMapWidgetResponse.status ||
                  createMapWidgetResponse.status
                }
                onClick={saveHandler}
                text="save"
                icon={<SaveIcon />}
                disabled={disabledCheck()}
              />
            ) : activeStep === 1 && widgetType?.id === "Map" ? (
              <DbaButton
                variant="contained"
                text="forward"
                onClick={handleNext}
                endIcon={<ArrowForwardIcon />}
              />
            ) : activeStep === 2 && widgetType?.id === "Map" ? (
              <DbaButton
                text="done"
                onClick={() => navigate("/widgets")}
                startIcon={<DoneIcon />}
                color="success"
              />
            ) : null}
            {activeStep === 0 &&
            ["Video", "Filter"].includes(widgetType?.id!) ? (
              <AddButton
                status={
                  updateVideoWidgetResponse.status ||
                  createVideoWidgetResponse.status
                }
                onClick={saveHandler}
                text="save"
                icon={<SaveIcon />}
                disabled={disabledCheck()}
              />
            ) : activeStep === 2 &&
              ["Video", "Filter"].includes(widgetType?.id!) ? (
              <DbaButton
                text="done"
                onClick={() => navigate("/widgets")}
                startIcon={<DoneIcon />}
                color="success"
              />
            ) : null}
            {activeStep === 1 &&
            widgetType?.id !== "Map" &&
            widgetType?.id !== "Video" &&
            widgetType?.id !== "Filter" ? (
              <AddButton
                status={
                  widgetType?.id === "Default"
                    ? editMode
                      ? updateDataWidgetResponse.status
                      : createDataWidgetResponse.status
                    : widgetType?.id === "Diagram"
                    ? editMode
                      ? updateDiagramWidgetResponse.status
                      : createDiagramWidgetResponse.status
                    : widgetType?.id === "Table"
                    ? editMode
                      ? updateTableWidgetResponse.status
                      : createTableWidgetResponse.status
                    : widgetType?.id === "Filter"
                    ? editMode
                      ? updateFilterWidgetResponse.status
                      : createFilterWidgetResponse.status
                    : "uninitialized"
                }
                onClick={saveHandler}
                text="save"
                icon={<SaveIcon />}
                disabled={disabledCheck()}
              />
            ) : activeStep === steps.length - 1 && widgetType?.id !== "Map" ? (
              <DbaButton
                text="done"
                onClick={() => navigate("/widgets")}
                disabled={disabledCheck()}
                startIcon={<DoneIcon />}
                color="success"
              />
            ) : widgetType?.id !== "Map" &&
              widgetType?.id !== "Video" &&
              widgetType?.id !== "Filter" ? (
              <DbaButton
                variant="contained"
                text="forward"
                onClick={handleNext}
                disabled={disabledCheck()}
                endIcon={<ArrowForwardIcon />}
              />
            ) : null}
          </Stack>
          <CSSStepsContainer>
            {activeStep === 0 ? (
              <>
                <FirstStepBaseSettings
                  editMode={editMode}
                  widgetType={widgetType}
                  setWidgetType={setWidgetType}
                  name={name}
                  setName={setName}
                  referenceHeader={referenceHeader}
                  setReferenceHeader={setReferenceHeader}
                  label={label}
                  setLabel={setLabel}
                  description={description}
                  setDescription={setDescription}
                  widgetTypeResponse={widgetTypeResponse as WidgetTypes}
                  measureResponse={measureResponse as string}
                  measure={measure}
                  setMeasure={setMeasure}
                  roundToNumber={roundToNumber}
                  setRoundToNumber={setRoundToNumber}
                  filterType={filterType}
                  setFilterType={setFilterType}
                  filterDefaultValue={filterDefaultValue}
                  setFilterDefaultValue={setFilterDefaultValue}
                  filterRestrictions={filterRestrictions}
                  setFilterRestrictions={setFiltersRestrictions}
                  error={editMode && dataFetchError}
                  success={dataFetchSuccess}
                  loading={dataLoading}
                  variable={variable}
                  setVariable={setVariable}
                  clustering={clustering}
                  setClustering={setClustering}
                  isDynamicPositioning={isDynamicPositioning}
                  setIsDynamicPositioning={setIsDynamicPositioning}
                  isShowTooltips={isShowTooltips}
                  setIsShowTooltips={setIsShowTooltips}
                  zoomLevel={zoomLevel}
                  setZoomLevel={setZoomLevel}
                  latitude={latitude}
                  setLatitude={setLatitude}
                  longitude={longitude}
                  setLongitude={setLongitude}
                  mapLayers={mapLayers}
                  setMapLayers={setMapLayers}
                  activeMapLayer={activeMapLayer!}
                  setActiveMapLayer={setActiveMapLayer}
                  videoSourceUrl={videoSourceUrl}
                  setVideoSourceUrl={setVideoSourceUrl}
                  queriesCommands={queriesCommands}
                  setQueriesCommands={setQueriesCommands}
                />
                <WidgetLinksGenerator
                  widgetLinks={widgetLinks}
                  dispatchWidgetLinks={dispatchWidgetLinks}
                />
                <WidgetRelatedReportsGenerator
                  widgetRelatedReports={widgetRelatedReports}
                  dispatchWidgetRelatedReports={dispatchWidgetRelatedReports}
                />
              </>
            ) : activeStep === 1 ? (
              widgetType?.id === "Map" && widgetID ? (
                <MapWidgetSecondStep widgetID={widgetID} />
              ) : (
                <>
                  {widgetType?.id && isShownQueryCommand() && (
                    <DbaQueryCommands
                      widgetType={widgetType.id as "Diagram" | "Default"}
                      label={label}
                      data={queriesCommands}
                      setData={setQueriesCommands}
                    />
                  )}
                  {widgetType?.id === "Default" && (
                    <>
                      <DbaCheckbox
                        checked={useQueryForPrevValue}
                        setChecked={setUseQueryForPrevValue}
                        label="useQueryForPrevValue"
                      />
                      {useQueryForPrevValue && (
                        <DbaQueryCommands
                          widgetType={widgetType.id}
                          label={label}
                          data={queryCommandPrevValue}
                          setData={setQueryCommandPrevValue}
                        />
                      )}
                    </>
                  )}
                </>
              )
            ) : activeStep === 2 && widgetID !== null ? (
              <AddWidgetToDashboard widgetID={widgetID} />
            ) : (
              ""
            )}
          </CSSStepsContainer>
        </>
      }
      <DbaSnackbar
        successMessage={
          editMode ? "updateWidgetSuccessMessage" : "saveWidgetSuccessMessage"
        }
        errorMessage="saveWidgetErrorMessage"
        error={createOrUpdateWidgetError}
        success={createOrUpdateWidgetSuccess}
      />
    </CSSDataContainer>
  );
};
