import React, { useState, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreatePlaylistMutation,
  useUpdatePlaylistMutation,
  useLazyGetPlaylistQuery,
} from "../../../features/serviceSlices/serviceHooks";
import { SaveButton, LoadingScreen } from "../../../components/common";
import { Stack, Button, Box, Divider, Snackbar, Alert } from "@mui/material";
import {
  DbaDataFetchSelect,
  DbaSelect,
  DbaTextField,
} from "../../../DbaComponents";
import { TPeriodOption, TPlaylistDashboardTableUsage } from "./Types";
import { DashboardResponse } from "../../../features/serviceSlices/Dashboards/Types";
import { getPeriodOptions } from "./periodOptions";
import { DashboardsTable } from "./components/DashboardsTable/DashboardsTable";
import { isFetchBaseQueryErrorTypeGuard } from "../../../utils/TypeGuards";
import { useSnackbar } from "../../../utils/hooks/useSnackbar";

const SHOW_CHOSEN_DASHBOARD_PERIOD = 800;

export const Playlist: React.FC = () => {
  const params = useParams<Record<string, string | undefined>>();
  const navigate = useNavigate();
  const editMode = !!params.id;
  const intl = useIntl();
  const PERIOD_OPTIONS = useMemo(() => getPeriodOptions(intl), [intl]);

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [dashboardShownPeriod, setDashboardShownPeriod] = useState<
    TPeriodOption | undefined
  >();
  const [selectedDashboard, setSelectedDashboard] =
    useState<DashboardResponse | null>(null);
  const [playlistDashboards, setPlaylistDashboards] = useState<
    TPlaylistDashboardTableUsage[]
  >([]);
  const [error, setError] = useState(false);
  const [isShowChosenDashboard, setIsShowChosenDashboard] =
    useState<boolean>(false);

  const [getPlaylistData, playlistData] = useLazyGetPlaylistQuery();
  const [addPlaylist, createResponse] = useCreatePlaylistMutation();
  const [updatePlaylist, updateResponse] = useUpdatePlaylistMutation();

  const { isShowSnackbar, snackbarMessage, snackbarSeverity, blinkSnackbar } =
    useSnackbar();

  const isUpdating = createResponse.isLoading || updateResponse.isLoading;

  const onSubmitFormHandler = () => {
    if (
      editMode
        ? updateResponse.isLoading || updateResponse.isSuccess
        : createResponse.isLoading || createResponse.isSuccess
    ) {
      return;
    }
    if (!name.trim() || !dashboardShownPeriod) {
      setError(true);
    } else {
      setError(false);
      editMode ? callUpdatePlaylist() : callAddPlaylist();
    }
  };

  const callAddPlaylist = () => {
    addPlaylist({
      replacementPeriod: dashboardShownPeriod!.value,
      name: name.trim(),
      description: description?.trim() ?? "",
      dashboards: playlistDashboards.map((dashboard) => ({
        dashboardId: dashboard.dashboardId,
        periodType: dashboard.periodType,
        period: dashboard.period,
      })),
    })
      .unwrap()
      .catch((error) => {
        blinkSnackbar(
          isFetchBaseQueryErrorTypeGuard(error)
            ? error.data.message
            : (intl.messages["errorMessage"] as string),
          "error"
        );
      });
  };

  const callUpdatePlaylist = () => {
    updatePlaylist({
      id: params.id!,
      replacementPeriod: dashboardShownPeriod!.value,
      name: name.trim(),
      description: description?.trim() ?? "",
      dashboards: playlistDashboards.map((dashboard) => ({
        dashboardId: dashboard.dashboardId,
        periodType: dashboard.periodType,
        period: dashboard.period,
      })),
    })
      .unwrap()
      .catch((error) => {
        blinkSnackbar(
          isFetchBaseQueryErrorTypeGuard(error)
            ? error.data.message
            : (intl.messages["errorMessage"] as string),
          "error"
        );
      });
  };

  const onDashboardChoose = (dashboard: DashboardResponse) => {
    setSelectedDashboard(dashboard);
    setPlaylistDashboards([
      ...playlistDashboards,
      {
        dashboardId: dashboard.id,
        periodType: "Day",
        period: null,
        name: dashboard.name,
        dashboardIdForTable: `${dashboard.id}_${Math.random()}`,
        isCurrent: true,
      },
    ]);

    blinkSnackbar(
      `${intl.messages["dashboard"]} «${dashboard.name}» ${(
        intl.messages["hasBeenAddedToPlaylist"] as string
      ).toLowerCase()}`
    );

    setIsShowChosenDashboard(true);
    setTimeout(() => {
      setSelectedDashboard(null);
      setIsShowChosenDashboard(false);
    }, SHOW_CHOSEN_DASHBOARD_PERIOD);
  };

  useEffect(() => {
    if (editMode) {
      getPlaylistData(params.id!);
    }
  }, [editMode, params.id, getPlaylistData]);

  useEffect(() => {
    if (playlistData.data) {
      setName(playlistData.data?.name);
      setDescription(playlistData.data?.description ?? "");
      const shownPeriod = PERIOD_OPTIONS.find(
        (option) =>
          option.value ===
          `${playlistData.data?.replacementPeriod.hours}h ${playlistData.data?.replacementPeriod.minutes}m ${playlistData.data?.replacementPeriod.seconds}s`
      );
      setDashboardShownPeriod(shownPeriod);
      setPlaylistDashboards(
        playlistData.data?.dashboards.map((dashboard) => ({
          dashboardId: dashboard.dashboardId,
          periodType: dashboard.periodType,
          period: dashboard.period ? new Date(dashboard.period) : null,
          name: dashboard.name ?? "",
          dashboardIdForTable: `${dashboard.dashboardId}_${Math.random()}`,
          isCurrent: !dashboard.period,
        }))
      );
    }
  }, [PERIOD_OPTIONS, playlistData.data]);

  const dashboardIsAddedSnackbar = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isShowSnackbar}
      autoHideDuration={null}
    >
      <Alert elevation={6} severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  return editMode && playlistData.isLoading ? (
    <LoadingScreen />
  ) : (
    <Box component="form">
      <Stack direction="row" spacing={2} sx={{ mb: "1rem" }}>
        <Button
          onClick={() => navigate(-1)}
          sx={{ width: "fit-content" }}
          color="error"
          variant="contained"
          disabled={isUpdating}
        >
          {intl.messages["cancel"]}
        </Button>
        <SaveButton
          redirectUrl="/playlists"
          onClick={onSubmitFormHandler}
          status={editMode ? updateResponse.status : createResponse.status}
        />
      </Stack>
      <Stack direction="column" spacing={2}>
        <DbaTextField
          disabled={isUpdating}
          required
          error={error && !name.trim()}
          value={name}
          setValue={setName}
          size="medium"
          label="label"
          helperText="fieldIsEmptyError"
        />
        <DbaTextField
          disabled={isUpdating}
          value={description}
          setValue={setDescription}
          size="medium"
          label="description"
        />
        <DbaSelect
          disabled={isUpdating}
          required
          error={error && !dashboardShownPeriod}
          selectedValue={dashboardShownPeriod}
          setSelectedValue={setDashboardShownPeriod}
          options={PERIOD_OPTIONS}
          label={intl.messages["dashboardChangePeriod"] as string}
        />
        <Box>
          <Divider sx={{ my: "10px" }} />
        </Box>
        <DbaDataFetchSelect
          disabled={isUpdating || isShowChosenDashboard}
          label="addDashboard"
          url="api/dashboard/getavailable"
          selectedValue={selectedDashboard}
          setSelectedValue={onDashboardChoose}
        />
        <DashboardsTable
          playlistDashboards={playlistDashboards}
          setPlaylistDashboards={setPlaylistDashboards}
          isUpdating={isUpdating}
        />
      </Stack>
      {dashboardIsAddedSnackbar}
    </Box>
  );
};
