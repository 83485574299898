import { useState, useLayoutEffect, useEffect } from "react";
import {
  useUpdateSettingsMutation,
  useGetColumnTypesQuery,
  useCreateSettingsMutation,
  useGenerateColumnsMutation,
  useGetDatasetMutation,
} from "../../features/serviceSlices/serviceHooks";
import {
  GenerateColumnsResponse,
  TGeneratedColumnRow,
  DatasetType,
} from "../../features/serviceSlices/DataSet/Types";
import {
  QueryCommandObject,
  TRequestStatus,
} from "../../features/serviceSlices/SharedTypes";
import { useParams, useNavigate } from "react-router";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  DbaTextField,
  DbaCheckbox,
  DbaDataRangePicker,
  DbaCronGenerator,
  DbaButton,
  DbaQueryCommands,
  FetchSelect,
  DbaAlert,
} from "../../DbaComponents";
import {
  AddButton,
  LoadingScreen,
  SaveButton,
  ErrorMessage,
} from "../../components/common";
import {
  CSSRequestFieldsContainer,
  CSSSettingsContainer,
  CSSDataContainer,
  CSSTableContainer,
  CSSTableTitle,
} from "./Datasets.styles";
import { EditableTable } from "./EditableTable";
import Skeleton from "@mui/material/Skeleton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Alert, Snackbar, Stack } from "@mui/material";
import { isFetchBaseQueryErrorTypeGuard } from "../../utils/TypeGuards";
import { useSnackbar } from "../../utils/hooks/useSnackbar";
import { useIntl } from "react-intl";

const steps = [
  "Основные настройки",
  "Запрос данных",
  "Параметры таблицы в хранилище",
  "Параметры запуска задания",
];
const SHOW_SNACKBAR_PERIOD = 10000;

export const Dataset = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams<Record<string, string | undefined>>();
  const editMode = Object.keys(params).length > 0;
  const columnTypes = useGetColumnTypesQuery();
  const [getDataset, DatasetResponse] = useGetDatasetMutation();
  const [id, setId] = useState("");
  const [responseStatus, setResponseStatus] =
    useState<TRequestStatus>("uninitialized");
  //1st step
  const [name, setName] = useState("");
  const [nameValidationError, setNameValidationError] =
    useState<boolean>(false);
  const [isCumulative, setIsCumulative] = useState(false);
  //2nd step
  const [queriesCommands, setQueriesCommands] = useState<
    QueryCommandObject[] | []
  >([]);
  //3th step
  const [tableName, setTableName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [getColumnsData, columnsData] = useGenerateColumnsMutation();
  const [tableData, setTableData] = useState<GenerateColumnsResponse | []>([]);
  //4th step
  const [updateType, setUpdateType] = useState("Auto");
  const [cronExpression, setCronExpression] = useState("* * * * *");
  const [cronExpressionError, setCronExpressionError] = useState(false);
  const [saveSetting] = useCreateSettingsMutation();
  const [updateSettings] = useUpdateSettingsMutation();

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (editMode && params.id) {
      getDataset(params.id);
    }
  }, [editMode, params.id, getDataset]);

  useLayoutEffect(() => {
    if (editMode && DatasetResponse.data) {
      setId(DatasetResponse.data.id!);
      setName(DatasetResponse.data.name);
      setIsCumulative(DatasetResponse.data.isCumulative);
      setTableName(DatasetResponse.data.tableSettings.name);
      setQueriesCommands(DatasetResponse.data.queriesCommands);
      if (DatasetResponse.data.cronExpression) {
        setCronExpression(DatasetResponse.data.cronExpression);
      }
      setTableData(DatasetResponse.data.tableSettings.columns);
      setUpdateType(DatasetResponse.data.updateType);
    }
  }, [editMode, params.id, DatasetResponse.data]);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const getErrorMessageFromResponse = (error: any) => {
    return isFetchBaseQueryErrorTypeGuard(error)
      ? `${error.data.message}
        ${
          error.data.innerExceptionMessage !== null &&
          error.data.innerExceptionMessage.length !== 0
            ? error.data.innerExceptionMessage
            : ""
        }`
      : (intl.messages["errorMessage"] as string);
  };

  const saveHandler = () => {
    setResponseStatus("pending");
    const dataSettingObj: DatasetType = {
      name: name.trim(),
      queriesCommands: queriesCommands,
      isCumulative,
      cronExpression: updateType === "Manual" ? null : cronExpression,
      tableSettings: {
        name: tableName,
        columns: tableData,
      },
      updateType,
    };
    if (editMode) {
      updateSettings({ ...dataSettingObj, id })
        .unwrap()
        .then(async () => {
          setResponseStatus("fulfilled");
        })
        .catch((error) => {
          blinkSnackbar(
            getErrorMessageFromResponse(error),
            "error",
            SHOW_SNACKBAR_PERIOD
          );
          setResponseStatus("rejected");
        });
    } else {
      saveSetting(dataSettingObj)
        .unwrap()
        .then(async () => {
          setResponseStatus("fulfilled");
        })
        .catch((error) => {
          blinkSnackbar(
            getErrorMessageFromResponse(error),
            "error",
            SHOW_SNACKBAR_PERIOD
          );
          setResponseStatus("rejected");
        });
    }
  };

  const handleBack = () => {
    setResponseStatus("uninitialized");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const zeroKeys =
    tableData.length > 0 &&
    tableData.find((row: TGeneratedColumnRow) => row.key === true) === undefined;
  const isTypesMissed =
    tableData.length > 0 &&
    !!tableData.find((row: TGeneratedColumnRow) => !row.type);

  const disabledCheck = () => {
    switch (activeStep) {
      case 0:
        return activeStep === 0 && !name.trim();
      case 1:
        if (queriesCommands.length > 0) {
          return false;
        }
        return true;
      case 2:
        if (tableData.length === 0 || zeroKeys || isTypesMissed) {
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  const getColumnsDataHandler = () => {
    getColumnsData({
      queriesCommands: queriesCommands,
      beginDate: startDate,
      endDate: endDate,
    });
  };

  useEffect(() => {
    if (columnsData.data) {
      setTableData(columnsData.data);
    }
  }, [columnsData.data]);


  const setNameWithValidation = (value: string) => {
    setNameValidationError(!value.trim());
    setName(value);
  };

  const { isShowSnackbar, snackbarMessage, snackbarSeverity, blinkSnackbar } =
    useSnackbar();

  const getSnackbar = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isShowSnackbar}
      autoHideDuration={null}
    >
      <Alert elevation={6} severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  return (
    <>
      <CSSDataContainer>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Stack direction="row" spacing={2} sx={{ mt: "1rem" }}>
          {activeStep === 0 ? (
            <DbaButton
              variant="contained"
              color="error"
              text="exit"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIcon />}
            />
          ) : (
            <DbaButton
              variant="contained"
              text="back"
              onClick={handleBack}
              disabled={activeStep === 0}
              startIcon={<ArrowBackIcon />}
            />
          )}
          {activeStep === steps.length - 1 ? (
            <SaveButton
              redirectUrl="/datasets"
              status={responseStatus}
              onClick={saveHandler}
              disabled={cronExpressionError}
            />
          ) : (
            <DbaButton
              variant="contained"
              text="forward"
              onClick={handleNext}
              disabled={disabledCheck()}
              endIcon={<ArrowForwardIcon />}
            />
          )}
        </Stack>
        {
          <>
            <CSSSettingsContainer>
              {activeStep === 0 ? (
                columnTypes.isLoading ? (
                  <LoadingScreen />
                ) : columnTypes.isError ? (
                  <ErrorMessage />
                ) : (
                  <>
                    <DbaTextField
                      disabled={editMode}
                      value={name}
                      setValue={setNameWithValidation}
                      required
                      label="label"
                      error={!name.trim() && nameValidationError}
                      helperText="fieldIsEmptyError"
                    />
                    <DbaCheckbox
                      checked={isCumulative}
                      setChecked={setIsCumulative}
                      label="isCumulative"
                    />
                  </>
                )
              ) : activeStep === 1 ? (
                <DbaQueryCommands
                  label={name}
                  data={queriesCommands}
                  setData={setQueriesCommands}
                />
              ) : activeStep === 2 ? (
                <>
                  <CSSRequestFieldsContainer>
                    <DbaDataRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                    <AddButton
                      text="generate"
                      status={columnsData.status}
                      onClick={getColumnsDataHandler}
                      showIcon={false}
                    />
                  </CSSRequestFieldsContainer>
                  {zeroKeys && (
                    <DbaAlert
                      severity="info"
                      text="datasetTableKeyRequirement"
                    />
                  )}
                  {columnTypes.isSuccess ? (
                    <CSSTableContainer>
                      <CSSTableTitle>{tableName}</CSSTableTitle>
                      <EditableTable
                        tableData={tableData}
                        setTableData={setTableData}
                        columnTypes={columnTypes.data}
                      />
                    </CSSTableContainer>
                  ) : columnsData.isError ? (
                    <ErrorMessage />
                  ) : columnsData.isLoading ? (
                    <>
                      <Skeleton variant="rectangular" width={100} height={24} />
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={544}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : activeStep === 3 ? (
                <>
                  <FetchSelect
                    required
                    label="chooseTaskLaunchOption"
                    url="api/Types/Get?Name=UpdateType"
                    optionLabel="name"
                    optionValue="id"
                    selectedValue={updateType}
                    setSelectedValue={(value: any) => setUpdateType(value.id)}
                    size="small"
                    disableClearable
                  />

                  {updateType === "Auto" && (
                    <DbaCronGenerator
                      setError={setCronExpressionError}
                      value={cronExpression}
                      setValue={setCronExpression}
                    />
                  )}
                </>
              ) : (
                ""
              )}
            </CSSSettingsContainer>
          </>
        }
      </CSSDataContainer>
      {getSnackbar}
    </>
  );
};
