import { useContext } from "react";
import { ThemeContext } from "../../utils/ThemeContext";
import { periodTypes } from "../../utils/Periods";
import styled from "@emotion/styled";
import colors from "../../Variables.module.scss";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { DbaDatepicker, DbaDataRangePicker } from "..";
import { PeriodSelectorProps } from "./Types";
import { useAppSelector } from "../../utils/reduxHooks";
import { useMediaQuery } from "@mui/material";

const CSSPeriodButton = styled(ToggleButton)((theme: any) => {
  return {
    color: theme.theme.palette.mode === "dark" ? "" : colors.darkBlue,
    border:
      theme.theme.palette.mode === "dark"
        ? `1px solid ${colors.purple500}`
        : `1px solid ${colors.darkBlue}`,
    "&.Mui-selected": {
      color: theme.theme.palette.mode === "dark" ? colors.light : "",
      background: theme.theme.palette.mode === "dark" ? colors.purple500 : "",
    },
  };
});

const CSSDatePickerContainer = styled.div<{ mobileView: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.mobileView ? "column" : "row")};
  padding: ${(props) => (props.mobileView ? "8px 0 0 16px" : null)};
  gap: 1rem;
`;

export const PeriodSelector = ({
  type,
  onStartDateChange,
  onEndDateChange,
  onPeriodChange,
  startDate,
  endDate,
  isShowAllPeriods,
}: PeriodSelectorProps) => {
  const { darkMode } = useContext(ThemeContext);
  const { role } = useAppSelector((state) => state.app);
  const mobileView = useMediaQuery("(max-width:1000px)");

  const disabled = !type || type === "NotSet";

  const getPeriodButton = (period: string) => {
    return (
      <CSSPeriodButton key={period} value={period}>
        {(periodTypes as any)[period]}
      </CSSPeriodButton>
    );
  };

  const getAllPeriodButtons = () => {
    if (isShowAllPeriods) {
      return Object.keys(periodTypes).map((period) => getPeriodButton(period));
    } else if (role.availablePeriods) {
      return role.availablePeriods.map((period) => getPeriodButton(period));
    }
  };

  const availabilePeriodsIsNotNullAndNotEmpty =
    role?.availablePeriods && role?.availablePeriods?.length > 0;

  if (availabilePeriodsIsNotNullAndNotEmpty || isShowAllPeriods) {
    return (
      <CSSDatePickerContainer mobileView={mobileView}>
        {type === "Day" ? (
          <DbaDatepicker
            width="110px"
            isDashboar
            label="chooseDay"
            setDate={onStartDateChange}
            date={startDate}
          />
        ) : type === "Month" ? (
          <DbaDatepicker
            width="140px"
            isDashboar
            filter="month"
            dateFormat="LLLL yyy"
            label="month"
            setDate={onStartDateChange}
            date={startDate}
          />
        ) : type === "Year" ? (
          <DbaDatepicker
            width="64px"
            isDashboar
            filter="year"
            dateFormat="yyy"
            label="year"
            setDate={onStartDateChange}
            date={startDate}
          />
        ) : type === "Custom" ? (
          <DbaDataRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={onStartDateChange}
            setEndDate={onEndDateChange}
            mobileView={mobileView}
          />
        ) : (
          <DbaDatepicker
            width="110px"
            isDashboar
            label="chooseDay"
            setDate={onStartDateChange}
            date={startDate}
            disabled={disabled}
          />
        )}
        <ToggleButtonGroup
          color="primary"
          value={type}
          exclusive
          onChange={onPeriodChange}
          disabled={disabled}
          size="small"
          sx={{
            background: darkMode ? colors.darkBlue : "",
            width: "fit-content",
          }}
        >
          {getAllPeriodButtons()}
        </ToggleButtonGroup>
      </CSSDatePickerContainer>
    );
  }
  return null;
};
