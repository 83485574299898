import { useLazyGetSelectOptionsQuery } from "../../features/serviceSlices/serviceHooks";
import { useEffect, useState, memo } from "react";
import { DbaSelect } from "./DbaSelect";
import { DbaDataFetchSelectProps } from "./Types";

export const DbaDataFetchSelect = memo(
  ({
    label,
    selectedValue,
    selectedIndex,
    setSelectedValue,
    url,
    optionLabel,
    labelIndex, //это поле
    keyIndex, //это значение поля
    error,
    helperText = "",
    required,
    size = "medium",
    disableClearable,
    filterOptions,
    multiple,
    width,
    minWidth,
    disabled,
    isDynamicHeight = false,
  }: DbaDataFetchSelectProps) => {
    const [getOptions, optionsResponse] = useLazyGetSelectOptionsQuery();
    const [open, setOpen] = useState(false);

    useEffect(() => {
      getOptions(url);
    }, [getOptions, url]);

    useEffect(() => {
      if (!optionsResponse.data) {
        getOptions(url);
      }
    }, [getOptions, url, optionsResponse.data]);

    useEffect(() => {
      if (optionsResponse.data && labelIndex && keyIndex) {
        setSelectedValue(
          optionsResponse.data.find((item) => item[labelIndex] === keyIndex) ??
            optionsResponse.data.find((item) => item["id"] === keyIndex)
        );
      }
    }, [labelIndex, setSelectedValue, keyIndex, optionsResponse.data]);

    useEffect(() => {
      if (optionsResponse.isError && open && !optionsResponse.data) {
        getOptions(url);
      }
    }, [optionsResponse.isError, open, url, getOptions, optionsResponse.data]);

    const showedValue = selectedValue
      ? selectedValue
      : optionsResponse.data?.find((option) => option.id === selectedIndex);

    const getSelectOptions = () => {
      if (optionsResponse.isSuccess) {
        if (filterOptions) {
          return optionsResponse.data.filter(
            (option) => !filterOptions.find((el) => option.id === el.id)
          );
        }
        return optionsResponse.data;
      }
      return [];
    };

    return (
      <DbaSelect
        multiple={multiple}
        options={getSelectOptions()}
        label={label}
        selectedValue={selectedIndex ? showedValue : selectedValue}
        setSelectedValue={setSelectedValue}
        loadingData={optionsResponse.isLoading}
        loadingError={optionsResponse.isError}
        optionLabel={optionLabel}
        open={open}
        setOpen={setOpen}
        error={error}
        helperText={helperText}
        required={required}
        size={size}
        disableClearable={disableClearable}
        width={width}
        minWidth={minWidth}
        disabled={disabled}
        isDynamicHeight={isDynamicHeight}
      />
    );
  }
);
