import { useState } from "react";
import { OrderType } from "../QueryConstructor.types";
import { DbaDataFetchSelect, DbaRadioGroup } from "../../../DbaComponents";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { CSSExpressionContainer } from "../QueryConstructor.styles";
import { QueryConstructorModal } from "./QueryConstructorModal";

const sortLabels = ["asc", "desc"];

export const Order = ({
  data,
  onSaveChanges,
  tableName,
  deleteHandler,
  dataSourceId,
}: {
  data: OrderType;
  onSaveChanges: (arg: OrderType, initialState: OrderType) => void;
  tableName: string;
  deleteHandler: (arg: OrderType) => void;
  dataSourceId: string;
}) => {
  const [column, setColumn] = useState<any>(data.column);
  const [sort, setSort] = useState<any>(data.sort);
  const intl = useIntl();

  const [open, setOpen] = useState(data.column === "" ? true : false);
  const [error, setError] = useState(false);
  const handleOpen = () => setOpen(true);

  const onSaveExpressionHandler = () => {
    if (column?.name) {
      onSaveChanges(
        {
          column: column.name,
          sort,
        },
        data
      );
      setOpen(false);
    } else {
      setError(true);
    }
  };

  const onDeleteOrderHandler = () => {
    deleteHandler(data);
    setOpen(false);
  };

  return (
    <>
      <CSSExpressionContainer onClick={handleOpen}>
        Col: <span> {data.column}</span> <span>Sort:{data.sort}</span>
      </CSSExpressionContainer>
      <QueryConstructorModal
        open={open}
        title="Order"
        saveHandler={onSaveExpressionHandler}
        deleteHandler={onDeleteOrderHandler}
      >
        <DbaDataFetchSelect
          required
          error={error && !column?.name}
          size="small"
          url={`api/Query/GetFields?DataSourceID=${dataSourceId}&TableName=${tableName}`}
          label="fields"
          selectedValue={column ? column : ""}
          setSelectedValue={setColumn}
          labelIndex="name"
          keyIndex={typeof column === "string" ? column : column?.name}
          disableClearable
        />
        <Typography>{intl.messages["orderBy"]}: </Typography>
        <DbaRadioGroup
          row
          value={sort}
          setValue={setSort}
          labels={sortLabels}
        />
      </QueryConstructorModal>
    </>
  );
};
