import React, {
  useState,
  useMemo,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { useIntl } from "react-intl";
import { useParams, useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import {
  DbaSelect,
  DbaTable,
  DbaSnackbar,
  DbaTransferList,
  DbaRadioGroup,
  DbaButton,
  DbaAlert,
} from "../../DbaComponents";
import {
  useLazyGetDashboardQuery,
  useGetDashboardWidgetsQuery,
  useUpdateDashboardMutation,
  useAddWidgetToDashboardMutation,
  useDeleteWidgetFromDashboardMutation,
  useGetWidgetsQuery,
  useGetTypesQuery,
  useCreateDashboardMutation,
} from "../../features/serviceSlices/serviceHooks";
import {
  AddButton,
  ErrorMessage,
  LoadingScreen,
  SaveButton,
} from "../../components/common";
import Box from "@mui/material/Box";
import { FormControlLabel, Stack, Switch, Link } from "@mui/material";
import { DbaTextField, DbaCopyToClipboardButton } from "../../DbaComponents";
import Divider from "@mui/material/Divider";
import { WidgetDashboard } from "../../features/serviceSlices/Dashboards/Types";
import { WidgetResponse } from "../../features/serviceSlices/Widget/Types";
import { TimePeriods } from "../../features/serviceSlices/SharedTypes";
import {
  CSSPeriodsContainer,
  CSSDefaultPeriodSelectContainer,
  CSSSwitch,
} from "./DashboardForm.styles";
import constants from "../../utils/constants";
import { ColumnDef } from "@tanstack/react-table";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

const BIG_SIZE_TO_PUSH_EDIT_COLUMN = 2000;
const ACTION_COLUMN_SIZE = 44;

export const DashboardForm = () => {
  const { id } = useParams<Record<string, string | undefined>>(); //current dashboardID
  const editMode = Boolean(id);
  const navigate = useNavigate();
  const intl = useIntl();

  //Редактирование полей дашборда
  const [errorDashboard, setErrorDashboard] = useState(false);
  const [createDashboard, createDashboardResponse] =
    useCreateDashboardMutation();
  const [getDashboardData, dashboardData] = useLazyGetDashboardQuery();
  const [updateDashboard, updateDashboardResponse] =
    useUpdateDashboardMutation();
  const options = useGetTypesQuery("DashboardPeriodType");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isPublicAccess, setIsPublicAccess] = useState<boolean>(false);
  const [periods, setPeriods] = useState<TimePeriods[]>([]);
  const [defaultPeriod, setDefaultPeriod] = useState<TimePeriods>("");
  //Вкладка виджетов
  const [errorWidget, setErrorWidget] = useState(false);
  const widgetDashboards = useGetDashboardWidgetsQuery(id!);
  const widgets = useGetWidgetsQuery();
  const [addWidget, addWidgetResponse] = useAddWidgetToDashboardMutation();
  const [deleteWidget, deleteWidgetResponse] =
    useDeleteWidgetFromDashboardMutation();
  const [selectedWidget, setSelectedWidget] = useState<WidgetResponse | null>(
    null
  );
  const [showWidgetErrorMessage, setShowWidgetErrorMessage] = useState(false);

  const onDeleteWidgetHandler = useCallback(
    (widget: WidgetDashboard) => {
      if (id) {
        deleteWidget({ widgetID: widget.widgetID, dashboardID: id });
      }
    },
    [deleteWidget, id]
  );

  const widgetDashboardsColumns: ColumnDef<WidgetDashboard>[] = useMemo(
    () => [
      {
        id: intl.messages["label"] as string,
        accessorFn: (row) => row.name,
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["description"] as string,
        accessorFn: (row) => row.description,
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton
            row={props.row}
            deleteDataHandler={onDeleteWidgetHandler}
          />
        ),
        size: ACTION_COLUMN_SIZE,
      },
    ],
    [intl.messages, onDeleteWidgetHandler]
  );

  useEffect(() => {
    if (addWidgetResponse.status === "fulfilled") {
      setSelectedWidget(null);
    }
  }, [addWidgetResponse.status]);

  const onAddWidgetHandler = () => {
    if (!selectedWidget?.id || id === undefined) {
      setErrorWidget(true);
    } else if (
      widgetDashboards?.data &&
      widgetDashboards?.data.find((i) => i.widgetID === selectedWidget.id)
    ) {
      setShowWidgetErrorMessage(true);
    } else {
      setShowWidgetErrorMessage(false);
      setErrorWidget(false);
      addWidget({
        widgetID: selectedWidget.id,
        dashboardID: id,
      });
      setSelectedWidget(null);
    }
  };

  const onSubmitFormHandler = () => {
    if (
      editMode
        ? updateDashboardResponse.status === "pending" ||
          updateDashboardResponse.status === "fulfilled"
        : createDashboardResponse.status === "pending" ||
          createDashboardResponse.status === "fulfilled"
    )
      return;
    if (!name.trim() || !periods.length || !defaultPeriod) {
      setErrorDashboard(true);
      return;
    }
    const dashboardObj = {
      name: name.trim(),
      description: description.trim(),
      isPublic: isPublicAccess,
      availablePeriods: periods.length === 0 ? [] : periods,
      defaultPeriod: periods.length === 0 ? null : defaultPeriod,
    };
    if (editMode && id) {
      updateDashboard({ ...dashboardObj, id });
      return;
    }
    createDashboard(dashboardObj);
  };

  useLayoutEffect(() => {
    if (id) {
      getDashboardData(id);
    }
  }, [getDashboardData, id]);

  useEffect(() => {
    if (dashboardData.data) {
      setName(dashboardData.data?.name);
      setDescription(dashboardData.data?.description);
      setIsPublicAccess(dashboardData.data?.isPublic);
      setPeriods(dashboardData.data.availablePeriods);
      setDefaultPeriod(dashboardData.data.defaultPeriod);
    }
  }, [dashboardData.data]);

  useEffect(() => {
    if (periods.indexOf(defaultPeriod) === -1) {
      setDefaultPeriod("");
    }
  }, [defaultPeriod, periods]);

  const dashboardUrl = `${window.location.origin}/public-dashboard/${id}`;

  const linkToDashboard = (
    <Link
      sx={{ marginTop: "0 !important" }}
      to={{ pathname: `/${id}` }}
      component={RouterLink}
      onClick={(event) => event.preventDefault()}
    >
      {dashboardUrl}
    </Link>
  );

  const fakeLinkToDashboard = (
    <Link
      sx={{
        marginTop: "0 !important",
        filter: "blur(2.5px)",
        textDecoration: "none",
        cursor: "default",
      }}
      to={{ pathname: `/` }}
      component={RouterLink}
      onClick={(event) => event.preventDefault()}
    >
      {window.location.origin}
      /public-dashboard/cf899b67-d1a5-4b2a-8b35-a7c0658e529c
    </Link>
  );

  if (
    (editMode &&
      (widgetDashboards.isLoading || widgets.isLoading || options.isLoading)) ||
    (!editMode && options.isLoading)
  ) {
    return <LoadingScreen />;
  }
  if (
    (editMode &&
      (widgetDashboards.isError || widgets.isError || options.isError)) ||
    (!editMode && options.isError)
  ) {
    return <ErrorMessage />;
  }
  if (
    (editMode &&
      widgetDashboards.isSuccess &&
      widgets.isSuccess &&
      options.isSuccess) ||
    (!editMode && options.isSuccess)
  ) {
    return (
      <Box sx={{ width: "100%" }}>
        <Stack direction="row" spacing={2} sx={{ mb: "1rem" }}>
          <DbaButton
            text="cancel"
            onClick={() => navigate(-1)}
            sx={{ width: "fit-content" }}
            color="error"
            variant="contained"
          />

          <SaveButton
            redirectUrl="/dashboards"
            onClick={onSubmitFormHandler}
            status={
              editMode
                ? updateDashboardResponse.status
                : createDashboardResponse.status
            }
          />
        </Stack>
        <Stack direction="column" spacing={2}>
          <Divider>{intl.messages["changeFields"]}</Divider>
          <DbaTextField
            required
            error={errorDashboard && !name.trim()}
            value={name}
            setValue={setName}
            label={"label"}
            helperText={intl.messages["fieldIsEmptyError"] as string}
          />
          <DbaTextField
            value={description}
            setValue={setDescription}
            label={"description"}
          />
          {constants.experimentalPublicDashboards ? (
            <>
              <CSSSwitch isMarginBottom={isPublicAccess}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isPublicAccess}
                      onChange={(e) => setIsPublicAccess(e.target.checked)}
                    />
                  }
                  label={
                    (isPublicAccess
                      ? intl.messages["isPublicAccessEnabled"]
                      : intl.messages["isPublicAccessDisabled"]) as string
                  }
                />
              </CSSSwitch>
              {isPublicAccess && editMode ? (
                <Box>
                  <Box>
                    {intl.messages["dashboardIsAvailableForEveryOneByLink"]}:
                  </Box>
                  <DbaCopyToClipboardButton
                    text={dashboardUrl}
                    beforeComponent={linkToDashboard}
                  />
                </Box>
              ) : null}
              {isPublicAccess && !editMode ? (
                <Box sx={{ marginBottom: "8px" }}>
                  <Box>
                    {intl.messages["dashboardIsAvailableForEveryOne"]}.{" "}
                    {
                      intl.messages[
                        "linkWillAppearAfterDashboardCreation"
                      ] as string
                    }
                    :
                  </Box>
                  <Box>{fakeLinkToDashboard}</Box>
                </Box>
              ) : null}
            </>
          ) : null}
          <Divider>{intl.messages["selectingAvailablePeriods"]}</Divider>
          {errorDashboard && periods.length === 0 && (
            <DbaAlert
              severity="error"
              text={intl.messages["dashboardsDefaultPeriodAlert"] as string}
            />
          )}
          <CSSPeriodsContainer>
            <DbaTransferList
              options={options.data}
              availableColumnTitle="availablePeriods"
              chosenColumnTitle="chosenPeriods"
              selectedOptions={periods}
              setSelectedOptions={setPeriods}
            />
            <CSSDefaultPeriodSelectContainer>
              {periods.length > 0 && (
                <DbaRadioGroup
                  labels={options.data.filter((option) =>
                    periods.includes(option.id)
                  )}
                  value={defaultPeriod}
                  setValue={setDefaultPeriod}
                  formLabel="defaultPeriod"
                  error={errorDashboard && defaultPeriod === ""}
                />
              )}
            </CSSDefaultPeriodSelectContainer>
          </CSSPeriodsContainer>
          {editMode && (
            <>
              <Divider>{intl.messages["addWidgetToDashboard"]}</Divider>
              <DbaSelect
                required
                error={errorWidget && !selectedWidget}
                selectedValue={selectedWidget}
                setSelectedValue={setSelectedWidget}
                options={widgets.data?.filter(
                  (w) =>
                    !widgetDashboards.data
                      ?.map((wd) => wd.widgetID)
                      .includes(w.id)
                )}
                label="chooseWidget"
              />
              <Stack direction="row" spacing={2}>
                <AddButton
                  status={addWidgetResponse.status}
                  onClick={onAddWidgetHandler}
                />
              </Stack>
              {showWidgetErrorMessage && (
                <ErrorMessage title="addNewItemError" />
              )}
              <DbaTable
                data={widgetDashboards.data ?? []}
                columns={widgetDashboardsColumns}
                stylesSettings={{ autoColumnsWidth: false }}
                fixControls={{ enableFixControls: false }}
              />
            </>
          )}
        </Stack>

        <DbaSnackbar
          error={
            addWidgetResponse.isError ||
            deleteWidgetResponse.isError ||
            createDashboardResponse.isError
          }
          success={false}
        />
      </Box>
    );
  }
  return null;
};
