import styled from "@emotion/styled";
import colors from "../../Variables.module.scss";

const CSSExpressionContainer = styled.div`
  border: 1px solid white;
  cursor: pointer;
  align-items: center;
  border: none;
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
  margin-right: 4px;
  padding: 0 8px;
  gap: 4px;
  min-width: 50px;
  max-width: fit-content;
  height: auto;
  min-height: 40px;
  background: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? colors.darkBlue : colors.white};
  color: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? colors.light : colors.darkBlue};
  box-shadow: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? "none" : colors.bsCardLight};
`;

const CSSExpressionValuesContainer = styled.div`
  word-break: break-all;
`;

const CSSExpressionModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const style = {
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const CSSButtonBlockModal = styled.div`
  display: flex;
  justify-content: space-between;
`;

//QueryConstructorComponents:
const CSSQueryConstructorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const CSSQueryConstructorTitle = styled.h3``;
const CSSQueryConstructorRow = styled.h3`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
const CSSQueryConstructorRowLabel = styled.p`
  font-weight: 500;
  align-items: center;
  border: none;
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  height: auto;
  min-height: 40px;
  justify-content: space-between;
  line-height: 32px;
  margin-right: 4px;
  padding: 0 8px;
  width: 110px;
  background: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? colors.darkBlue : colors.white};
  color: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? colors.light : colors.darkBlue};
  box-shadow: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? "none" : colors.bsCardLight};
`;
const CSSQueryConstructorAddButton = styled.h3`
  max-height: 32px;
  max-width: 32px;
`;
export {
  CSSExpressionContainer,
  CSSExpressionModal,
  style,
  CSSButtonBlockModal,
  CSSQueryConstructorContainer,
  CSSQueryConstructorTitle,
  CSSQueryConstructorRow,
  CSSQueryConstructorRowLabel,
  CSSQueryConstructorAddButton,
  CSSExpressionValuesContainer,
};
