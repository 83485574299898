import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DbaWidgetInfoModalProps } from "./DbaWidgetInfoMofal.types";
import { CSSModal, CSSDialogContainer, CSSTextContainer } from "./StyledComponents";
import { DbaIconButton } from "../DbaIconButton";
import { useTheme } from "@mui/material/styles";
import { useIntl } from "react-intl";

export const DbaWidgetInfoModal = ({
  isOpen,
  onClose,
  data,
}: DbaWidgetInfoModalProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <CSSModal fullScreen={fullScreen} open={isOpen} onClose={onClose}>
      <CSSDialogContainer>
        <InfoIcon />
        <CSSTextContainer>
          <Typography variant="h6" component="h2">
            {data?.label && data?.label}
          </Typography>
          <Typography>{`${intl.messages["period"]}:`}</Typography>
          <Typography>{`${intl.messages["datasources"]}:`}</Typography>
          <Typography>{`${intl.messages["description"]}: ${
            data?.description && data?.description
          }`}</Typography>
        </CSSTextContainer>
        <DbaIconButton size="medium" icon={<CloseIcon />} onClick={onClose} />
      </CSSDialogContainer>
    </CSSModal>
  );
};
