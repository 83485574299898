import { createIntl, createIntlCache } from "react-intl"; //your messages translated with id

const locale = "ru-RU";

const messagesInRussian = {
  forgotPassword: "Забыли пароль",
  login: "Войти",
  userLogin: "Логин",
  welcome: "Добро пожаловать в ЦИАС",
  userGroups: "Группы пользователей",
  createNewUserGroup: "Создание новой группы пользователей",
  editUsergroup: "Редактирование группы пользователей",
  users: "Пользователи",
  createUser: "Создание нового пользователя",
  editUser: "Редактирование пользователя",
  registrationDate: "Зарегистрирован",
  user: "Пользователь",
  firstName: "Имя",
  secondName: "Отчество",
  lastName: "Фамилия",
  fullName: "ФИО",
  userName: "Логин",
  password: "Пароль",
  emailAddress: "Адрес электронной почты",
  email: "Почта",
  sendPassword: "Отправить пароль на почту",
  generatePassword: "Сгенерировать случайный пароль",
  send: "Отправить",
  dontSend: "Не отправлять",
  forgetSuccess: "Письмо успешно отправлено на почту",
  recoverySuccess: "Пароль успешно восстановлен",
  passwordLength: "Длина пароля должна быть не менее 5 символов",
  passwordMatch: "Пароли должны совпадать",
  registration: "Регистрация",
  successRegistration: "Успешная регистрация",
  role: "Роль",
  resetPassword: "Сбросить пароль",
  revokeRefreshToken: "Отозвать токен",
  recovery: "Восстановить",
  recoveryPassword: "Восстановление пароля",
  setExpirationDate: "Выберите дату окончания доступа",
  expirationDate: "Доступ до",
  noLimits: "Не ограничен",
  enterSearchQuery: "Введите поисковый запрос",
  mainFields: "Основные поля",
  dataSourceInMapObjTooltip:
    "Выберите источник данных для редактирования команд",
  queriesCommands: "Настройки запросов",
  commands: "Команды",
  dalQuery: "Запрос DataAccessLanguage",
  createQueryCommand: "Создать новый запрос",
  saveQueryCommand: "Сохранить запрос",
  deleteQueryCommand: "Удалить запрос",
  dashboards: "Дашборды",
  dashboard: "Дашборд",
  numOfWidgets: "Количество виджетов",
  description: "Описание",
  chooseUser: "Выберите пользователя",
  chooseUserRole: "Выберите роль пользователя",
  chooseAccessPeriod: "Выберите период доступа",
  chooseDashboard: "Выберите дашборд",
  addDashboard: "Добавьте дашборд",
  errorMessage:
    "Произошла ошибка при выполнении запроса. При повторении ошибки обратитесь к системному администратору.",
  errorDataFetching: "Произошла ошибка при запросе данных",
  noOptions: "Не найдено",
  requiredField: "Обязательное поле",
  goBack: "Назад",
  add: "Добавить",
  adding: "Добавление...",
  added: "Добавлено!",
  error: "Ошибка",
  addNewItemError: "Добавляемый объект уже существует в списке",
  chooseDay: "Выберите день",
  chooseStartDate: "Выберите дату начала",
  chooseEndDate: "Выберите дату окончания",
  year: "Год",
  month: "Месяц",
  day: "День",
  today: "Сегодня",
  minute: "Минута",
  minuteGenitive: "Минуты",
  minutesGenitive: "Минут",
  secondsGenitive: "Секунд",
  currentYear: "Текщий год",
  currentMonth: "Текущий месяц",
  current: "Текущий",
  cancel: "Отмена",
  delete: "Удалить",
  confirmDeletion: "Подтвердите удаление записи",
  label: "Название",
  no: "Нет",
  yes: "Да",
  mapObjects: "Объекты на карте",
  layers: "Слои",
  measures: "Единицы измерения",
  createMeasure: "Создание новой единицы измерения",
  editMeasure: "Редактирование единицы измерения",
  datasets: "Наборы данных",
  createDataset: "Создание нового набора данных",
  isCumulative: "Накопительный",
  plugin: "Тип плагина",
  chooseTaskLaunchOption: "Выберите вариант запуска задачи:",
  schedule: "По расписанию",
  singleTime: "Единоразово",
  immediately: "Немедленно",
  miningStartDate: "Дата и время запуска задания",
  editDataset: "Редактирование набора данных",
  name: "Наименование",
  dataSource: "Источник данных",
  back: "Назад",
  forward: "Вперед",
  isNullable: "Возможен null",
  partitionBy: "Порционирование",
  orderBy: "Порядок",
  type: "Тип",
  clearInput: "Очистить",
  clearFilter: "Очистить фильтр",
  cronExpression: "СRON-выражение",
  createDashboard: "Создание нового дашборда",
  editDashboard: "Редактирование дашборда",
  widgets: "Виджеты",
  widgetCreating: "Создание нового виджета",
  widgetEditing: "Редактирование виджета",
  widgetType: "Тип виджета",
  ownedByDashboards: "Принадлежит дашбордам",
  chooseWidget: "Выберите виджет",
  datasources: "Источники данных",
  datasource: "Создание нового источника данных",
  editDatasource: "Редактирование источника данных",
  loading: "Загрузка...",
  changeFields: "Изменение полей",
  addWidgetToDashboard: "Добавление виджетов к дашборду",
  showedName: "Отображаемое название",
  createMapObject: "Создание объекта на карте",
  editMapObject: "Редактирование объекта на карте",
  save: "Сохранить",
  queryCommandError:
    "Необходимо добавить минимум одну команду для выполнения запросов",
  dalScript: "Data Access Language скрипт",
  generate: "Генерировать",
  success: "Успешно",
  tableName: "Название таблицы",
  useQueryForPrevValue:
    "Использовать команды запросов для значений за предыдущий период",
  dataWidgetSettings: "Настройки текстового виджета",
  showBackground: "Отображать бекгрануд виджета",
  showHeader: "Отображать название виджета",
  showValue: "Отображать значение виджета",
  showPreviousValue: "Отображать значение виджета за предыдущий период",
  showMeasure: "Отображать единицы измерения",
  showDifference: "Отображать разницу",
  roundTo: "Округлять до",
  background: "Фон",
  value: "Значение",
  difference: "Разница",
  previousValue: "Пред. значение",
  measuresShorthand: "Ед. измерения",
  hide: "Скрыть",
  reset: "Сброс",
  resetInfinitive: "Сбросить",
  opacity: "Прозрачность",
  diagramType: "Тип диаграммы",
  legends: "Легенда",
  legendSettings: "Настройки легенд",
  baseSettings: "Общие",
  baseSettingsTitle: "Общие настройки",
  styles: "Стили",
  styleSettings: "Настройки стилей",
  arcLabels: "Обозначения",
  arcLabelsSettings: "Настройки обозначений",
  arcLinkLabels: "Выноски",
  arcLinkLabelsSettings: "Настройки выносок",
  interactivity: "Интерактивность", //(motion+interactivity)
  interactivitySettings: "Настройки интерактивности",
  idWidgetSettings: "Указатель для идентификатора",
  keyWidgetSettings: "Указатель для значения",
  displayedValues: "Отображаемые значения",
  absolute: "Абсолютные",
  percentage: "Проценты",
  percentageabsolute: "%+абсолютные",
  displayOverallValue: "Отображать итоговое значение",
  keysWidgetSettings: "Указатели для значений",
  valueFormat: "Форматирование значения",
  borderRadius: "Радиус границ",
  enableLabel: "Отображать значение",
  arcLabelsSkipAngle: "Скрывать если угол меньше:",
  labelSkipWidth: "Скрывать при ширине меньше:",
  labelSkipHeight: "Скрывать при высоте меньше:",
  axes: "Оси",
  enableGridX: "Сетка осей по оси Х",
  enableGridY: "Сетка осей по оси Y",
  axisTop: "Ось сверху",
  axisRight: "Ось справа",
  axisBottom: "Ось снизу",
  axisLeft: "Ось слева",
  tickSize: "Размер выносок",
  tickPadding: "Отступ текста",
  tickRotation: "Наклон выносок",
  legendOffset: "Отступ легенды",
  xScaleConf: "Конфигурация по оси Х",
  xFormat: "Формат значений по оси Х",
  yScaleConf: "Конфигурация по оси Y",
  yFormat: "Формат значений по оси Y",
  stacked: "Группировать",
  min: "Минимальное значение",
  max: "Максимальное значение",
  curve: "Изгиб",
  lineWidth: "Толщина линий",
  borderWidth: "Толщина границ",
  enableArea: "Отображать область под графиками",
  areaBaselineValue: "Исходный уровень",
  enableSlices: "Сводка значений при наведении",
  enableCrosshair: "Отображать перекрестие",
  crosshairType: "Тип перекрестия",
  points: "Точки",
  fraction: "Доля",
  animate: "Анимировать",
  isInteractive: "Интерактивность",
  motionConfig: "Варианты анимации",
  enablePoints: "Отображать точки",
  pointSize: "Размер точки",
  pointBorderWidth: "Толщина границ точки",
  enablePointLabel: "Отображать значения",
  pointLabelFormat: "Формат значения",
  colorScheme: "Цветовая схема",
  gridLevels: "Количество осей",
  gridShape: "Форма контура",
  gridLabelOffset: "Отступ от осей",
  circular: "Круглая",
  linear: "Линейная",
  smoothInterpolation: "плавная",
  direction: "Направление",
  horizontal: "Горизонтальное",
  vertical: "Вертикальное",
  interpolation: "Интерполяция",
  smooth: "Сглаживание",
  spacing: "Зазоры",
  shapeBlending: "Скругления",
  borderOpacity: "Прозрачность границ",
  enableBeforeSeparators: "Оси слева",
  beforeSeparatorLength: "Длина осей слева",
  beforeSeparatorOffset: "Отступ от осей слева",
  enableAfterSeparators: "Оси справа",
  afterSeparatorOffset: "Отступ от осей слева",
  afterSeparatorLength: "Длина осей справа",
  currentPartSizeExtension: "Увеличение диаграммы",
  currentBorderWidth: "Увеличение толщины границ",
  forceSquare: "Квадратные ячейки",
  sizeVariation: "Ячейки разных размеров",
  paddingHeatMap: "Расстояния между ячейками",
  hoverTarget: "Выделять при наведении",
  cellHoverOpacity: "Прозрачность выделенных ячеек при наведении",
  cellHoverOthersOpacity: "Прозрачность невыделенных ячеек при наведении",
  cellShape: "Форма ячейки",
  rect: "Прямоугольник",
  circle: "Круг",
  cellBorderWidth: "Толщина границ ячейки",
  startDate: "Дата начала",
  endDate: "Дата окончания",
  align: "Выравнивание",
  print: "Печать",
  emptyCellsColor: "Фон пустых ячеек",
  dayRadius: "Радиус ячеек",
  dayBorderColor: "Цвет границ ячеек",
  monthLegendPosition: "Расположение подписей месяцев",
  monthLegendOffset: "Отступы до подписей месяцев",
  weekdayLegendOffset: "Отступы до подписей дней недели",
  monthSpacing: "Расстояние между месяцами",
  monthBorderWidth: "Толщина границ месяцев",
  monthBorderColor: "Цвет границ месяцев",
  yearSpacing: "Расстояние между годами",
  yearLegendPosition: "Расположение подписей годов",
  yearLegendOffset: "Отступы до подписей годов",
  months: "Месяцы",
  years: "Года",
  before: "до",
  after: "после",
  transitionMode: "Варианты перехода*",
  labelFormat: "Формат значения",
  labelsSkipAngle: "Скрывать при угле",
  labelsRadiusOffset: "Отступы",
  enableTracks: "Треки",
  tracksColor: "Цвет треков",
  enableRadialGrid: "Радиальные оси",
  enableCircularGrid: "Поперечные оси",
  radialAxisStart: "Радиальные оси в начале",
  radialAxisEnd: "Радиальные оси в конце",
  circularAxisInner: "Внутренние радиальные оси",
  circularAxisOuter: "Наружные радиальные оси",
  xPadding: "Отступы от вертикальных осей",
  activeFillOpacity: "Прозрачность при наведении",
  inactiveFillOpacity: "Прозрачность фона при наведении",
  activeBorderWidth: "Прозрачность границ при наведении",
  startLabelPadding: "Отступы слева от графика",
  endLabelPadding: "Отступы справа от графика",
  groupMode: "Варианты группировки",
  layoutMode: "Расположение",
  valueScale: "Масштабирование значений",
  reverseMode: " График в обратном направлении",
  innerPadding: "Отступы внтури графика",
  padding: "Отступы между графиками",
  margin: "Отступы",
  sortByValue: "Cортировать по величине",
  startAngle: " Начальный угол",
  endAngle: " Конечный угол",
  fillFreeSpace: "Заполнять свободное пространство",
  fillFreeSpaceHint: "если пирог меньше половины окружности",
  innerRadius: "Внутренний радиус",
  padAngle: "Расстояние между дольками",
  cornerRadius: "Радиус закругления",
  row: "Ряд",
  column: "Колонна",
  linearScale: "Линейное",
  symlog: "Симметричное",
  legendTextLocation: "Расположение текста",
  horizontalLegendLocation: "Расположение по горизонтали",
  verticalLegendLocation: "Расположение по вертикали",
  width: "Ширина",
  height: "Высота",
  symbolSize: "Размер обозначения",
  itemsSpacing: "Расстояние между обозначением и текстом",
  enableArcLinkLabels: "Отображать выноски",
  arcLinkLabelsSkipAngle: "Скрывать если угол меньше:",
  activeInnerRadiusOffset: "Увеличивать внутренний контур при наведении",
  activeOuterRadiusOffset: "Увеличивать наружный контур при наведении",
  fontSize: "Размер шрифта",
  fontWeight: "Толщина",
  BrBG: "Зелено-коричневая",
  PRGn: "Пурпурно-зеленая",
  PiYG: "Зелено-розовая",
  PuOr: "Пурпурно-оранжевая",
  RdBu: "Красно-синяя",
  RdGy: "Красно-серая",
  RdYlGn: "Красно-зеленая",
  RdYlBu: "Красно-голубая",
  blues: "Синяя",
  greens: "Зеленая",
  greys: "Серая",
  oranges: "Оранжевая",
  purples: "Пурпурная",
  reds: "Красная",
  BuGn: "Темно-зеленая",
  BuPu: "Темно-пурпурная",
  GnBu: "Сине-зеленая",
  OrRd: "Красно-оранжевая",
  PuBuGn: "Сине-зеленая",
  PuBu: "Сине-голубая",
  PuRd: "Розово-фиолетовая",
  RdPu: "Бордово-фиолетовая",
  YlGnBu: "Желто-синяя",
  YlGn: "Желто-зеленая",
  YlOrBr: "Желто-коричневая",
  YlOrRd: "Желто-красная",
  default: "По умолчанию",
  gentle: "Плавная",
  wobbly: "Шаткая",
  slow: "Медленная",
  stiff: "Жесткая",
  molasses: "Натянутая",
  nivoColorScheme: "Основная",
  spectral: "Спектральная",
  purpleRed_green: "Пурпурно-зеленый",
  brown_blueGreen: "Коричнево-голубой",
  pink_yellowGreen: "Розово-желто-зеленый",
  purple_orange: "Пурпурно-оранжевый",
  red_blue: "Красно-голубой",
  red_grey: "Красно-серый",
  red_yellow_blue: "Красно-желто-синий",
  red_yellow_green: "Красно-желто-зеленый",
  blue_green: "Сине-зеленый",
  blue_purple: "Сине-фиолетовый",
  green_blue: "Зелено-синий",
  orange_red: "Оранжево-красный",
  purple_blue_green: "Пурпурно-сине-зеленый",
  purple_blue: "Пурпурно-голубой",
  purple_red: "Пурпурно-красный",
  red_purple: "Красно-пурпурный",
  yellow_green_blue: "Желто-зелено-синий",
  yellow_green: "Желто-зеленый",
  yellow_orange_brown: "Желто-оранжево-коричневый",
  yellow_orange_red: "Желто-оранжево-красный",
  linearClosed: "Линейный",
  basisClosed: "Базовый",
  cardinalClosed: "Резкий",
  catmullRomClosed: "Плавный",
  natural: "Естественный",
  basis: "Базовый",
  cardinal: "Резкий",
  catmullRom: "Плавный",
  monotoneX: "Монотонный по оси X",
  monotoneY: "Монотонный по оси Y",
  step: "Ступенчатый",
  stepAfter: "Плоский после точки",
  stepBefore: "Плоский до точки",
  category10: "Яркая",
  accent: "Акцент",
  dark2: "Темная",
  paired: "Парные цвета",
  pastel1: "Пастельная 1",
  pastel2: "Пастельная 2",
  set1: "Набор 1",
  set2: "Набор 2",
  set3: "Набор 3",
  widgetConstructor: "Конструктор виджетов",
  editSuccesResponse: "Изменения успешно сохранены",
  deleteSuccesResponse: "Объект успешно удален!",
  deleteSuccessMessagePlural: "Объекты успешно удалены!",
  saveWidgetSuccessMessage: "Виджет успешно сохранен!",
  updateWidgetSuccessMessage: "Изменения виджета успешно сохранены!",
  saveWidgetErrorMessage: "Произошла ошибка при сохранении виджета",
  done: "Завершить",
  isDone: "Готово",
  addWidgetSuccessMessage: "Виджет успешно добавлен к дашборду ",
  addWidgetErrorMessage: "Произошла ошибка при добавлении виджета",
  exit: "Выйти",
  template: "Шаблон",
  templates: "Шаблоны",
  nameTemplate: "Название шаблона",
  addTemplateTitle: "Создание шаблона",
  addTemplateBtn: "Сохранить шаблон",
  edit: "Редактировать",
  fullScreenMode: "Полноэкранный режим",
  groupingFunction: "Группировка",
  pageNotFound: "Запрашиваемого адреса не существует",
  forbiddenError:
    "Извините, у вас недостаточно прав для просмотра содержимого этого дашборда.\n" +
    "Рекомендуем обратиться к администратору.",
  goToMain: "Вернуться на главную",
  emptyDataArrayWarningWidgetEditor: "Данные за выбранный период отсутствуют.",
  emptyDataSuggestionWidgetEditor:
    "Выберите более раннюю дату или промежуток времени.",
  positiveTrend: "Позитивный тренд",
  sign: "Знак",
  symbol: "Символ",
  accuracy: "Точность",
  fill: "Заполнить",
  alignText: "Выравнить",
  withoutIndentation: "Без отступов",
  delimiter: "Разделитель",
  withoutZeros: "Без нулей",
  roundingOff: "Закругление",
  blur: "Размытие",
  editDashboardMode: "Режим редактирования дашборда",
  closeEditDashboard: "Закрыть режим редактирования",
  getData: "Получить данные",
  chooseGetDataPeriod: "Выберите период запроса данных:",
  queryCommandsManagement: "Управление командами запроса данных",
  data: "Данные",
  requests: "Запросы",
  table: "Таблица",
  alias: "Alias",
  functions: "Функция",
  operator: "Оператор",
  fields: "Поля",
  apply: "Применить",
  missingField: "Не заполнено необходимое поле",
  request: "Запрос",
  dataSourceIdMissing: "Не выбран источник данных",
  dataBaseNotSelected: "Необходимо ввести название базы данных",
  expression: "Выражение",
  macros: "Макрос",
  noData: "Нет данных",
  checkDataRequest: "Проверьте правильность выполнения запроса",
  filterType: "Тип фильтра",
  filterDataType: "Тип данных",
  variable: "Переменная",
  selectErrorMessageForFilterWidget:
    'Для типа виджета "Фильтр" необходимо указать 2 объекта для параметра "SELECT" ',
  filters: "Фильтры",
  filtersConstructorInfoMessage: "Для работы с фильтрами создайте запрос",
  nameLogin: "Имя (Логин)",
  info: "Информация",
  period: "Период",
  applyToAll: "Применить ко всем",
  noDashboards: "Дашборды отсутствуют",
  visualSettings: "Визуальные настройки",
  assignedGroups: "Назначен группам",
  objects: "Объекты",
  MapObjectActionType: "Тип отображения",
  point: "Точка",
  line: "Линия",
  polygon: "Полигон",
  object: "Объект",
  filter: "Фильтр",
  queriesCommandsInfo: "Запрос информации по объекту",
  showByDefault: "Отображать по умолчанию",
  errorSettingDefaultMapObject:
    "Произошла ошибка при удалении объектов на карте",
  layer: "Слой",
  MapObjectsLoadingError: "Произошла ошибка при загрузке объектов карты",
  zoomLevel: "Уровень приближения",
  clustering: "Кластеризация",
  latitude: "Широта",
  longitude: "Долгота",
  noChanges: "Без изменений",
  mapLayers: "Подложки карты",
  createNewMapLayer: "Добавление подложки карты",
  editMapLayer: "Редактирование подложки карты",
  chooseMapLayer: "Выберите подложку",
  typeMapsLayer: "Тип подложки карты",
  activeMapLayer: "Выберите активную подложку для карты",
  apiKey: "API-ключ",
  languageInscriptionsMap: "Язык надписей на карте",
  ratioMapLayer:
    "Коэффициент для увеличения или уменьшения изображения тайла (от 0.1 до 4)",
  invalidFormat: "Неверный формат",
  apiKeyFormat:
    "Формат API-key: 8(#)-4(#)-4(#)-4(#)-12(#), где '#' - любой символ",
  scaleValue: "Введите число в диапозоне от 0.1 до 4",
  rangeAacceptableValues: "Диапазон допустимых значений от 0 до 7",
  right: "Справа",
  left: "Слева",
  bottom: "Снизу",
  top: "Сверху",
  tableField: "Поле таблицы",
  time: "Время:",
  successMessage: "Запрос успешно выполнен",
  mapObjectInfo: "Информация об объекте",
  verticalModal: "Вертикальное отображение информации",
  wrongDataType: "Некорректный тип данных: объект вместо текстового значения",
  references: "Справочники",
  editReference: "Редактирование справочника",
  createNewReference: "Создание нового справочника",
  tableFieldType: "Тип поля справочника",
  addHeaders: "Добавить заголовок",
  noReferenceHeaders: "Необходимо добавить минимум один заголовок",
  link: "Ссылка",
  links: "Ссылки",
  addingNewReferenceHeader: "Создание заголовка",
  changeReferenceHeader: "Редактирование заголовка",
  managingWidgetTemplates: "Управление шаблонами виджетов",
  creator: "Автор",
  status: "Статус",
  confirmDeleteAgain: "Шаблон будет удален безвозвратно. Вы уверены?",
  showDeletedWidget: " Показать удаленные",
  active: "Активный",
  deleted: "Удален",
  tableDataFormatError:
    "Формат данных несовместим с табличной формой отоборажения информации. Получен массив объектов с вложенными объектами. Необходим массив объектов с ключами типа 'текстовая строка'",
  tableDataTypeObjectError:
    "Формат данных несовместим с табличной формой отоборажения информации. Получен объект. Необходим массив объектов с ключами типа 'текстовая строка'",
  readOnly: "Доступно только для чтения",
  emptyData: "Отсутствуют данные для отображения",
  extendedMode: "Расширенный режим",
  regularMode: "Обычный режим",
  field: "Поле",
  const: "Константа",
  repeatRequest: "Повторить запрос",
  bracketsError: "Некорректное расположение скобок",
  undefined: "Не задано",
  undefined2: "Не определено",
  queryCommand: "Команда запроса",
  runTime: "Дата обновления данных",
  view: "Просмотр",
  truncate: "Очистить",
  export: "Экспорт",
  updates: "Обновления",
  update: "Обновление",
  confirmTruncateMessage: "Подтвердите очистку данных",
  loaded: "Загружен",
  waitingForLoading: "Ожидает загрузки",
  testConnection: "Протестировать соединение",
  selectingAvailablePeriods: "Выбор доступных периодов",
  availablePeriods: "Доступные периоды",
  chosenPeriods: "Выбранные периоды",
  periods: "Периоды",
  selected: "Выбрано",
  moveToTheAvailable: "Переместить в доступные варианты",
  moveToTheSelected: "Переместить в выбранные варианты",
  selectAllOptions: "Выбрать все варианты",
  defaultPeriod: "Период по умолчанию",
  axisTicksFontSize: "Подписи - размер шрифта",
  axisTicksFill: "Подписи - цвет",
  axisLegendFill: "Легенды осей - цвет",
  axisLegendFontSize: "Легенды осей - размер шрифта",
  axisLineStroke: "Линии - цвет",
  axisLineStrokeWidth: "Линии - ширина",
  radioButtonGroupError: "Необходимо выбрать вариант",
  labelTextFill: "Значения - цвет",
  labelTextFontSize: "Значения - размер шрифта",
  legendTextFill: "Подпись легенды - цвет",
  lastUpdateDate: "Дата последнего обновления",
  January: "Янв",
  February: "Фев",
  March: "Мар",
  April: "Апр",
  May: "Май",
  June: "Июнь",
  July: "Июль",
  August: "Авг",
  September: "Сен",
  October: "Окт",
  November: "Нояб",
  December: "Дек",

  addLink: "Добавить ссылку",
  url: "Адрес",
  openInNewTab: "Открывать в новой вкладке",
  noLinks: "Ссылки отсутствуют",
  arcLinkLabelsTextColor: "Цвет текста выносок",
  arcLinkLabelsColor: "Цвет выносок",
  inherit: "Наследовать",
  theme: "Тема",
  thresholds: "Пороговые значения",
  addThreshold: "Добавить порог",
  baseColor: "Базовый цвет",
  palettes: "Палитры",
  color: "Цвет",
  red: "Красный",
  orange: "Оранжевый",
  yellow: "Желтый",
  green: "Зеленый",
  blue: "Синий",
  purple: "Фиолетовый",
  select: "Выбрать",
  chooseColor: "Выбор цвета",
  showThresholds: "Отображать пороговые значения",
  thresholdAxe: "Вдоль оси",
  dashedLine: "Пунктирная линия",
  key: "Ключ",
  marker: "Маркер",
  datasetTableKeyRequirement:
    "Для продолжения создания команды запроса задайте минимум 1 ключ",
  queryCommandTitleError: "Команда с таким названием уже существует",
  variableType: "Тип переменной",
  report: "Отчет",
  reports: "Отчеты",
  addReport: "Добавить отчет",
  noReports: "Отчеты отсутствуют",
  editReport: "Редактирование отчета",
  createNewReport: "Создание нового отчета",
  deleteDataByPeriod: "Удалить данные за период",
  fromDate: "С",
  toDate: "По",
  youSureYouWantToDeleteDataByPeriod:
    "Вы действительно хотите удалить данные за период",
  square: "Квадрат",
  triangleUp: "Треугольник (указатель вверх)",
  triangleDown: "Треугольник (указатель вниз)",
  rhombus: "Ромб",
  cross: "Крест",
  markerSizeMultiplier: "Множитель размера маркера",
  thickness: "Толщина",
  individualObjectsColor: "Индивидуальный цвет объектов",
  fieldNameWithColor: "Наименование поля содержащего цвет",
  dynamicPositioningEnabled: "Динамическое позиционирование включено",
  dynamicPositioningDisabled: "Динамическое позиционирование выключено",
  isShowTooltipsEnabled:
    "Постоянное отображение подсказок для объектов карты включено",
  isShowTooltipsDisabled:
    "Постоянное отображение подсказок для объектов карты выключено",
  category: "Категория",
  fileCategories: "Категории файлов",
  editFileCategory: "Редактирование категории файлов",
  createNewFileCategory: "Создание новой категории файлов",
  videoStreamSourceUrl: "Источник видеопотока (URL)",
  files: "Файлы",
  file: "Файл",
  editFile: "Редактирование файла",
  createNewFile: "Загрузка нового файла",
  chooseFile: "Выбрать файл",
  chooseAnotherFile: "Выбрать другой файл",
  maxFileSizeMustNotExceed: "Максимальный размер файла не должен превышать",
  fileMustHaveExtension: "Файл должен иметь расширение",
  fileUploadDate: "Дата загрузки файла",
  updateFile: "Обновление файла",
  fileSuccessUpload: "Файл успешно загружен!",
  pin1: "Указатель тип 1",
  pin2: "Указатель тип 2",
  deleteFilter: "Удалить фильтр",
  addFilter: "Добавить фильтр",
  updateGroupName: "Изменить имя группы",
  isPublicAccessEnabled: "Публичный доступ включен",
  isPublicAccessDisabled: "Публичный доступ выключен",
  dashboardIsAvailableForEveryOne: "Дашборд доступен любым пользователям",
  dashboardIsAvailableForEveryOneByLink:
    "Дашборд доступен любым пользователям по ссылке",
  linkWillAppearAfterDashboardCreation:
    "Ссылка появится после создания дашборда",
  copyToClipboard: "Скопировать в буфер обмена",
  copied: "Скопировано",
  result: "Результат",
  dataQuality: "Качество данных",
  dataType: "Тип данных",
  dataTypes: "Типы данных",
  datasetPreprocessing: "Предобработка набора данных",
  notDefined: "Не определено",
  awaitingСonfirmation: "Ожидает подтверждения пользователем",
  confirmed: "Подтверждено",
  creation: "Создание",
  dataTypesDetection: "Определение типов данных",
  dataQualityCheck: "Проверка качества данных",
  completed: "Завершено",
  inWork: "В работе",
  inQueue: "В очереди",
  define: "Определить",
  confirm: "Подтвердить",
  preProcessingStage: "Этап предобработки",
  preProcessingStageTooltip:
    "Этап, на котором находится обработка набора данных. Все этапы: создание, определение типов данных, проверка качества, завершено, ошибка",
  taskStatus: "Статус задачи",
  taskStatusTooltip:
    "Статус задачи (в очереди или в работе), которая выполняется над набором данных",
  countOfRows: "Количество строк",
  countOfRowsTooltip: "Количество строк в исходном наборе данных",
  showDataSetData: "Отобразить данные",
  dataTypesStatusTooltip:
    "Этап определения типов данных. Типы данных должны быть подтверждены пользователем",
  duplicates: "Дубли",
  duplicate: "Дубль",
  duplicatesStatusTooltip:
    "Количество записей, которые содержат идентичные значения одного признака",
  find: "Найти",
  passes: "Пропуски",
  pass: "Пропуск",
  passesStatusTooltip:
    "Количество записей, которые не содержат значений (например, пустые строки или пустые ячейки)",
  typesMismatch: "Несовпадение типов",
  typeMismatch: "Несовпадение типа",
  typesMismatchStatusTooltip:
    "Количество записей, значения которых не совпадают по их типу (например, число представлено строкой)",
  outliers: "Выбросы",
  outlier: "Выброс",
  outliersStatusTooltip:
    "Количество записей, значения в которых сильно отличаются от основной массы записей общей выборки и возможно являются ошибками",
  runDataQualityCheck: "Оценить качество данных",
  duplicatesTooltip:
    "Определение записей набора данных, которые содержат идентичные значения одного признака",
  passesTooltip:
    "Определение записей, которые не содержат значений (например, пустые строки или пустые ячейки)",
  typesmismatchTooltip:
    "Определение записей, значения которых не совпадают по их типу (например, число представлено строкой)",
  outliersTooltip:
    "Определение записей, значения в которых сильно отличаются от основной массы записей общей выборки и возможно являются ошибками",
  outlier_quantileTooltip:
    "Определение значений, которые не попадают в интервал, содержащий центральные 50% наблюдений выборки, т.е. интервал между 25-м и 75-м процентилями",
  outlier_stdTooltip:
    "Значения, которые можно считать выбросами относительно стандартного отклонения",
  emptyValueForOutliersCheck:
    "Не установлено значение порога для типа проверки «Выбросы»",
  checkTypeMinCount: "Необходимо выбрать хотя бы один тип проверки",
  runMlTaskButtonTooltip:
    "Определение качества данных с выбранными типами проверки и параметрами",
  runMlTaskButtonParamsTooltip:
    "Невозможно запустить задачу из-за некорректных настроек параметров задачи",
  runMlTaskButtonTypesUndefinedTooltip:
    "Невозможно запустить задачу для набора данных, типы данных которого не подтверждены",
  runMlTaskButtonInWorkTooltip:
    "Невозможно запустить задачу, которая находится на этапе «В работе»",
  runMlTaskButtonInQueueTooltip:
    "Невозможно запустить задачу, которая находится на этапе «В очереди»",
  messageWithError: "Сообщение ошибки",
  messageWithErrorTooltip:
    "Ошибка, которая возникла на каком-либо этапе выполнения предобработки набора данных",
  removeMlTasks: "Отменить задачи",
  isUsed: "Используется",
  detectDataTypesAutomatically: "Определить типы данных автоматически",
  hintAutomaticDataTypeDetection:
    "Система автоматически определит типы данных, вам останется только скорректировать результат и подтвердить свой выбор",
  hintConfirmDataTypeDetection:
    "Подтвердите выбранные типы данных. Перед подтверждением данные можно скорректировать. Этап доступен после автоматического определения типов данных",
  taskIsAddedToQueue: "Задача добавлена в очередь",
  confirmedMessage: "Типы данных подтверждены",
  taskIsWhileHandlingByBackendPleaseWait:
    "Прямо сейчас идет обработка задачи для этого набора данных. Продолжить предобработку можно будет после окончания задачи",
  learnProcessingStatusFully: "Узнать полный статус обработки данных",
  dataTypesAreConfirmedInfoMessage:
    "Типы данных выбраны и подтвержены. Можно продолжать предобработку данных",
  continuePreprocessing: "Продолжить предобработку",
  hintIfTheFieldIsUsedInPreprocessing:
    "Будет ли это поле использоваться в автоматической предобработке данных сейчас и на всех последующих этапах",
  hintDataTypeInPreprocessing:
    "Тип, который используется на всех последующих этапах при обработке данных. Поле становится доступным после автоматического определения типов данных. Перед подтверждением результат можно скорректировать",
  areYouSureYouWantStartDataTypeDetectionMessage:
    "Перед автоматической проверкой данных убедитесь, что все поля в столбце «Используется» выбраны верно. Изменить это на последующих этапах невозможно.",
  startAutoTypesDetection: "Начать автоматическое определение типов?",
  areYouSureYouWantConfirmTypesMessage:
    "Перед подтверждением типов данных убедитесь, что все типы данных выбраны верно. Изменить это на последующих этапах невозможно.",
  confirmTypesMessage: "Подтвердить типы данных и перейти к следующему шагу?",
  start: "Начать",
  dataQualityCheckIsRunning:
    "Задачи для выбранных типов проверок запущены. Ожидайте результатов после их выполнения",
  userAddedSuccessfully: "Пользователь успешно добавлен",
  userRemovedSuccessfully: "Пользователь успешно удален",
  dashboardAddedSuccessfully: "Дашборд успешно добавлен",
  dashboardRemovedSuccessfully: "Дашборд успешно удален",
  userAddedError: "Произошла ошибка при добавлении пользователя",
  userRemovedError: "Произошла ошибка при удалении пользователя",
  dashboardAddedError: "Произошла ошибка при добавлении дашборда",
  dashboardRemovedError: "Произошла ошибка при удалении дашборда",
  dashboardChangePeriod: "Интервал смены дашбордов",
  playlists: "Плейлисты",
  editPlaylist: "Редактирование плейлиста",
  createNewPlaylist: "Создание нового плейлиста",
  hasBeenAddedToPlaylist: "Был добавлен в плейлист",
  dashboardsDefaultPeriodAlert: "Необходимо выбрать хотя бы один период",
  choosePlaylist: "Выберите плейлист",
  playlistAddedSuccessfully: "Плейлист успешно добавлен",
  playlistRemovedSuccessfully: "Плейлист успешно удален",
  playlistAddedError: "Произошла ошибка при добавлении плейлиста",
  playlistRemovedError: "Произошла ошибка при удалении плейлиста",
  startPlaylistPlay: "Начать воспроизведение плейлиста",
  notEnoughDashboardsToStartPlaylistMessage:
    "Недостаточно дашбордов для начала воспроизведения плейлиста. Нужно еще",
  playlistsNotFound: "Плейлистов не найдено",
  deleteTableAdvice:
    "Таблица будет удалена вместе с данными. Данное действие невозможно отменить.",
  deleteTableActionMessage: "Вы уверены, что хотите удалить таблицу?",
  stopPlaylist: "Остановить плейлист",
  timeZone: "Часовой пояс",
  labelRowsPerPage: "Записей на странице",
  fieldIsEmptyError: "Поле не может быть пустым",
  download: "Скачать",
  imageMissing: "Изображение отсутствует",
  generatingPasswordWithoutSendingErrorMessage:
    'Выберите "отправить пароль на почту" или введите пароль. Аккаунт не ' +
    "будет доступен для входа, т.к. сгенерированный пароль не поступит пользователю без указания адреса электронной почты",
  defaultValue: "Значение по умолчанию",
  defaultVariableValue: "Значение переменной по умолчанию",
  noConditionsForTheSearch: "Нет условий, удовлетворяющих поиску",
  oldWidgetDataDueToFilterDefaultValue:
    "Внимание! Данные на виджетах могут быть не актуальны, т.к. значение фильтра по умолчанию устарело. Рекомендуем обратиться к администратору.",
  widgetErrorMessage:
    "Для отображения данного виджета необходим набор данных с датами. #splitОтредактируйте#link#split команду запроса данных.",
  addressRestriction: "Добавление ограничений по адресу",
  addRestrictionsAlert:
    "Ограничения позволяют искать адреса только в определенном регионе, районе, городе или улице. Ограничений может быть несколько. Например, Барнаул и Новосибирск",
  restrictionAlreadyExists: "Такое ограничение уже было добавлено",
  unusedRestrictionAddeddMessage:
    "Выбранная территория входит в состав ранее добавленной",
  somethingWentWrong: "Что-то пошло не так:",
  visualEditorError:
    "Проверьте правильность выполнения #splitкоманды запроса данных#link#split или выберите другой тип диаграммы.",
  filterByCondition: "Фильтровать по условию",
  textContains: "Текст содержит",
  textNotContains: "Текст не содержит",
  notSelected: "Не выбрано",
  more: "Больше",
  equalsOrMore: "Больше или равно",
  less: "Меньше",
  equalsOrLess: "Меньше или равно",
  equals: "Равно",
  notEquals: "Не равно",
  between: "Между",
  notBetween: "Не между",
  from: "От",
  to: "До",
  dateExact: "Дата совпадает",
  dateAfter: "Дата после",
  dateBefore: "Дата до",
  alertDefaultValueWillReset:
    "Добавление нового ограничения приведет к обнулению значения по умолчанию",
};

// * To use intl outside of react components
// * https://stackoverflow.com/questions/42414416/react-intl-define-messages-outside-of-react
const cache = createIntlCache();
const intl = createIntl({ locale, messages: messagesInRussian }, cache);

const getLocalizedMonths = (year: number, month: number, date: Date) => {
  switch (new Date(date).getMonth()) {
    case 0:
      return intl.messages["January"];
    case 1:
      return intl.messages["February"];
    case 2:
      return intl.messages["March"];
    case 3:
      return intl.messages["April"];
    case 4:
      return intl.messages["May"];
    case 5:
      return intl.messages["June"];
    case 6:
      return intl.messages["July"];
    case 7:
      return intl.messages["August"];
    case 8:
      return intl.messages["September"];
    case 9:
      return intl.messages["October"];
    case 10:
      return intl.messages["November"];
    case 11:
      return intl.messages["December"];
    default:
      return "";
  }
};

export { messagesInRussian, intl, locale, getLocalizedMonths };

//Требуют локализации но не попали в intl:
//- DataLoadingSetting.tsx: шаги(steps), плейсхолдер таблицы  "Наименование"
