import React, { useMemo, useState, useCallback } from "react";
import { WidgetRelatedReportProps } from "./Types";
import { useAppSelector } from "../../../../utils/reduxHooks";
import { useGetReportDataQuery } from "../../../../features/serviceSlices/serviceHooks";
import { DbaTable } from "../../../../DbaComponents";
import { LoadingScreen } from "../../../common";
import { DbaAlert } from "../../../../DbaComponents";
import { isFetchBaseQueryErrorTypeGuard } from "../../../../utils/TypeGuards";
import { ColumnDef } from "@tanstack/react-table";
import { convertFilterTableDatesToISOUsingExternalTimeZone, convertISODatesInCollectionToLocalStringByTimezonesShift } from "../../../../utils/helpers/dateTime";
import {
  TFilterData,
  TSortOptions,
} from "../../../../DbaComponents/DbaTable/Types";
import { store } from "../../../../utils/store";

export const WidgetRelatedReport = ({
  report,
  dashboardId,
}: WidgetRelatedReportProps) => {
  const {
    type: periodType,
    startDate,
    endDate,
    filters,
    timezone,
  } = useAppSelector((state) => state.app);
  const [tableFilters, setTableFilters] = useState<TFilterData[]>([]);
  const [sortOptions, setSortOptions] = useState<TSortOptions>();

  const { data, isLoading, isFetching, isError, error } = useGetReportDataQuery(
    {
      reportID: report.id,
      dashboardID: dashboardId!,
      startDate,
      endDate,
      periodType,
      filters,
      sortOptions,
      filterOptions: convertFilterTableDatesToISOUsingExternalTimeZone(
        tableFilters,
        store.getState().app.timezone.id
      ) ?? [],
    }
  );

  const onTableFiltersChange = useCallback((data: TFilterData[]) => {
    setTableFilters(data);
  }, []);

  const onSortingChange = useCallback((options: TSortOptions) => {
    setSortOptions(options);
  }, []);

  const columns: ColumnDef<any>[] = useMemo(() => {
    return (
      data?.headers
        ?.map((column) => ({
          id: column.field,
          header: column.headerName,
          accessorFn: (row: any) => row[column.field],
          order: column.order,
          meta: {
            dataType: column.type,
          },
        }))
        .sort((a, b) => a.order - b.order) ?? []
    );
  }, [data]);

  const tableData = useMemo(
    () =>
      data
        ? convertISODatesInCollectionToLocalStringByTimezonesShift({
            collection: data?.data[0]?.data ?? [],
            headers: data?.headers ?? [],
            originalTZ: data?.timeZoneId,
            targetTZ: timezone.id,
          })
        : [],
    [data, timezone.id]
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError && isFetchBaseQueryErrorTypeGuard(error)) {
    return <DbaAlert severity="error" text={error.data.message} />;
  }

  if (!columns.length) {
    return <DbaAlert severity="warning" text="noReferenceHeaders" />;
  }

  if (!tableData.length && !tableFilters.length) {
    return <DbaAlert severity="warning" text="emptyData" />;
  }

  return (
    <DbaTable
      id={report.id}
      columns={columns}
      data={tableData}
      headToolbar={{ showHeadToolbar: false }}
      pagination={{
        rowsPerPageSelector: {
          defaultValue: 25,
          options: [25, 50, 100, "all"],
        },
      }}
      sorting={{
        enableServerSorting: !!onSortingChange,
        onSortingChange: onSortingChange,
      }}
      filters={{
        enableFilters: true,
        filtersList: tableFilters,
        onFiltersListChange: onTableFiltersChange,
      }}
      isLoading={isFetching}
    />
  );
};
