import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { AuthenticateResponse } from "./Authentication/Types";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: window.__env__.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("x-access-token");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: "api/User/RefreshToken",
            method: "Get",
            credentials: "include",
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          let refreshResponse = refreshResult.data as AuthenticateResponse;
          localStorage.setItem("x-access-token", refreshResponse.jwtToken);
          result = await baseQuery(args, api, extraOptions);
        } else {
          console.log("logout");
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const rootApi = createApi({
  reducerPath: "rootApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "UserGroups",
    "groupDashboards",
    "GroupUsers",
    "Measures",
    "Measure",
    "Dashboards",
    "WidgetsInDashboards",
    "Widgets",
    "MapObjects",
    "MapObject",
    "DataSources",
    "ProviderInfo",
    "selectOptions",
    "dashboardWidgets",
    "dataWidget",
    "widgetSettings",
    "diagramWidget",
    "tableWidget",
    "videoWidget",
    "filterWidget",
    "WidgetDashboards",
    "layouts",
    "datasets",
    "roles",
    "mapWidget",
    "mapLayers",
    "referenceHeaders",
    "referenceHeader",
    "WidgetTemplates",
    "Reports",
    "Users",
    "FileCategories",
    "Files",
    "MLDateSetSummary",
    "Playlists",
  ],
  endpoints: (builder) => ({}),
});
