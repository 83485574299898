import React, {
  useContext,
  forwardRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import { ThemeContext } from "../../utils/ThemeContext";
import { Default, Diagram, GeoMap, Table, Filter, Video } from "./Widgets";
import { WidgetContainer } from "./common";
import colors from "../../Variables.module.scss";
import { DbaErrorBoundary } from "../../DbaComponents";
import { useAppSelector } from "../../utils/reduxHooks";

const getWidget = (widgetType, id, setBody, setResizeHandlerColor, ref) => {
  switch (widgetType) {
    case "Default":
      return (
        <Default
          id={id}
          setBody={setBody}
          setResizeHandlerColor={setResizeHandlerColor}
        />
      );
    case "Diagram":
      return (
        <Diagram
          id={id}
          setBody={setBody}
          setResizeHandlerColor={setResizeHandlerColor}
        />
      );
    case "Filter":
      return (
        <Filter
          id={id}
          setBody={setBody}
          setResizeHandlerColor={setResizeHandlerColor}
        />
      );
    case "Table":
      return (
        <Table
          id={id}
          setBody={setBody}
          setResizeHandlerColor={setResizeHandlerColor}
        />
      );
    case "Map":
      return (
        <GeoMap
          id={id}
          ref={ref}
          setBody={setBody}
          setResizeHandlerColor={setResizeHandlerColor}
        />
      );
    case "Video":
      return (
        <Video
          id={id}
          setBody={setBody}
          setResizeHandlerColor={setResizeHandlerColor}
        />
      );
    default:
      return "";
  }
};

const Widget = forwardRef(
  (
    { style, className, children, item, widgetType, id, layout, ...rest },
    ref
  ) => {
    const { darkMode } = useContext(ThemeContext);
    const isPlayingMode = useAppSelector((store) => store.playlist.isPlaying);
    const [body, setBody] = useState({
      borderRadius: 8,
      backgroundOpacity: 1,
      blur: 0,
      visible: true,
    });
    const [resizeHandlerColor, setResizeHandlerColor] = useState(
      darkMode ? colors.white : colors.darkBlue
    );

    const zIndex = useMemo(() => {
      let calculatedZIndex = 0;
      if (layout && layout.md) {
        const maxPosY = Math.max(
          ...layout.md.map((x) => {
            return x.y ?? 0;
          })
        );
        const widgetPos = layout.md.find((x) => x.i === id);
        const widgetPosY = widgetPos !== undefined ? widgetPos.y ?? 0 : 0;
        calculatedZIndex = maxPosY - widgetPosY;
      }
      return {
        zIndex: calculatedZIndex,
      };
    }, [id, layout]);

    const styleObj = useMemo(() => {
      return { ...style, ...zIndex };
    }, [style, zIndex]);

    useEffect(() => {
      if (
        resizeHandlerColor === colors.white ||
        resizeHandlerColor === colors.darkBlue
      ) {
        setResizeHandlerColor(darkMode ? colors.white : colors.darkBlue);
      }
    }, [darkMode, resizeHandlerColor]);

    return (
      <WidgetContainer
        body={body}
        darkMode={darkMode}
        ref={ref}
        className={className}
        style={styleObj}
        resizeHandlerColor={resizeHandlerColor}
        blockUI={isPlayingMode}
        {...rest}
      >
        <DbaErrorBoundary>
          {getWidget(
            widgetType,
            id,
            setBody,
            setResizeHandlerColor,
            (ref = { ref })
          )}
          {children}
        </DbaErrorBoundary>
      </WidgetContainer>
    );
  }
);

export default React.memo(Widget);
