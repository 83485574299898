import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

type DbaTextFieldType = {
  label: string;
  required?: boolean;
  value: string;
  setValue: (value: string) => void;
  type?: "text" | "number" | "password";
  size?: "small" | "medium";
  error?: boolean;
  setError?: (arg: string) => void;
  helperText?: string;
  disabled?: boolean;
  readyIcon?: boolean;
  maxLength?: number;
  [key: string]: any;
};

export const DbaTextField = ({
  value,
  setValue,
  label,
  required,
  type = "text",
  size,
  error = false,
  setError,
  helperText = "",
  disabled,
  readyIcon,
  maxLength = 256,
  ...rest
}: DbaTextFieldType) => {
  const [showPassword, setShowpassword] = useState(false);
  const intl = useIntl();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  const textFieldHelperText =
    error && required && (value === "" || !value)
      ? intl.messages["requiredField"]
      : intl.messages[helperText] || helperText;

  const passwordInputIcon = showPassword ? <VisibilityOff /> : <Visibility />;
  return (
    <TextField
      className="DbaTextField"
      type={type === "password" ? (showPassword ? "text" : "password") : type}
      value={value}
      onChange={handleChange}
      onBlur={(evt) => setError && setError(evt.target.value)}
      required={required}
      label={intl.messages[label] || label}
      error={error}
      helperText={error && textFieldHelperText}
      inputProps={{ maxLength: 256 }}
      InputProps={{
        endAdornment:
          type === "password" ? (
            <InputAdornment
              sx={{ cursor: "pointer" }}
              position="end"
              onClick={() => setShowpassword(!showPassword)}
            >
              {passwordInputIcon}
            </InputAdornment>
          ) : (
            ""
          ),
      }}
      size={size}
      fullWidth
      disabled={disabled}
      {...rest}
    />
  );
};
