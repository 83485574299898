import { useEffect } from "react";
import { DbaDatepicker } from "..";
import styled from "@emotion/styled";
import { DbaDataRangePickerProps } from "./Types";

const CSSDbaDataRangePickerContainer = styled.div<{ mobileView?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.mobileView ? "column" : "row")};
  gap: 1rem;
  width: fit-content;
`;

export const DbaDataRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  mobileView,
  startDateLabel,
  endDateLabel,
  disabled,
}: DbaDataRangePickerProps) => {
  useEffect(() => {
    if (endDate && endDate < startDate) {
      setEndDate(startDate);
    }
  }, [endDate, setEndDate, startDate]);

  const datePickerWidth = mobileView ? "225px" : "160px";
  return (
    <CSSDbaDataRangePickerContainer mobileView={mobileView}>
      <DbaDatepicker
        width={datePickerWidth}
        dateFormat="dd.MM.yyyy HH:mm"
        selected={startDate}
        label={startDateLabel ? startDateLabel : "chooseStartDate"}
        setDate={(date: any) => setStartDate(date)}
        date={startDate}
        disabled={disabled}
      />
      <DbaDatepicker
        width={datePickerWidth}
        dateFormat="dd.MM.yyyy HH:mm"
        selected={endDate}
        label={endDateLabel ? endDateLabel : "chooseEndDate"}
        setDate={(date: any) => setEndDate(date)}
        date={endDate}
        disabled={disabled}
      />
    </CSSDbaDataRangePickerContainer>
  );
};
