import { useState } from "react";
import { ExpressionType } from "../QueryConstructor.types";

import {
  CSSExpressionContainer,
  CSSExpressionValuesContainer,
} from "../QueryConstructor.styles";
import { DbaDataFetchSelect, DbaTextField } from "../../../DbaComponents";
import { QueryConstructorModal } from "./QueryConstructorModal";

export const Expression = ({
  data,
  onSaveChanges,
  tableName,
  deleteHandler,
  dataSourceId,
}: {
  data: ExpressionType;
  onSaveChanges: (arg: ExpressionType, initialState: ExpressionType) => void;
  tableName: string;
  deleteHandler: (arg: ExpressionType) => void;
  dataSourceId: string;
}) => {
  const [field, setField] = useState<any>(data.field);
  const [values, setValues] = useState(data.values);
  const [conditionalOperator, setConditionalOperator] = useState<any>(
    data.condition
  );
  const [open, setOpen] = useState(data.field === "" ? true : false);
  const handleOpen = () => setOpen(true);
  const [error, setError] = useState(false);

  const onSaveExpressionHandler = () => {
    if (
      field?.name &&
      conditionalOperator?.id &&
      typeof values === "string" &&
      values.length > 0
    ) {
      onSaveChanges(
        {
          field: field.name,
          condition: conditionalOperator.id ? conditionalOperator.id : null,
          values,
        },
        data
      );
      setOpen(false);
    } else {
      setError(true);
    }
  };

  const onDeleteOrderHandler = () => {
    deleteHandler(data);
    setOpen(false);
  };

  return (
    <>
      <CSSExpressionContainer onClick={handleOpen}>
        Expr:
        <span>{field?.name ?? data.field}</span>
        <span>{data.condition}</span>
        <CSSExpressionValuesContainer>
          <span>{data.values}</span>
        </CSSExpressionValuesContainer>
      </CSSExpressionContainer>
      <QueryConstructorModal
        open={open}
        title="WHERE - Expression"
        saveHandler={onSaveExpressionHandler}
        deleteHandler={onDeleteOrderHandler}
      >
        <DbaDataFetchSelect
          required
          error={error && !field}
          disableClearable
          size="small"
          url={`api/Query/GetFields?DataSourceID=${dataSourceId}&TableName=${tableName}`}
          label="fields"
          selectedValue={field}
          setSelectedValue={setField}
          labelIndex="name"
          keyIndex={typeof field === "string" ? field : field ? field.name : ""}
        />
        <DbaDataFetchSelect
          required
          error={error && !conditionalOperator}
          disableClearable
          size="small"
          url="api/Types/Get?Name=ConditionalOperators"
          label="operator"
          selectedValue={conditionalOperator}
          setSelectedValue={setConditionalOperator}
          labelIndex="id"
          keyIndex={
            typeof conditionalOperator === "string"
              ? conditionalOperator
              : conditionalOperator
              ? conditionalOperator.id
              : ""
          }
        />
        <DbaTextField
          required
          error={error && values === ""}
          size="small"
          label="value"
          value={values}
          setValue={setValues}
        />
      </QueryConstructorModal>
    </>
  );
};
