import { rootApi } from "../serviceRoot";
import{
  TemplateType,
  WidgetTemplatesResponse,
  GetQueryCommandsDataRequest,
  GetWidgetSettingsRequest,
  GetWidgetSettingsResponse,
  saveWidgetSettingsRequest,
} from "./Types"

import { WidgetTypes } from "../../serviceSlices/Widget/Types"
import { DeleteWidgetFromDashboardRequest } from "../Dashboards/Types"

export const widgetSettingsApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getWidgetSettings: builder.query<
      GetWidgetSettingsResponse,
      GetWidgetSettingsRequest
    >({
      query: (payload) => ({
        url: "api/WidgetSettings/GetById",
        method: "POST",
        body: payload,
      }),
      providesTags: ["widgetSettings"],
    }),
    saveWidgetSettings: builder.mutation<any, saveWidgetSettingsRequest>({
      query: (payload) => ({
        url: "api/widgetSettings/Save",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        switch (result.widgetType) {
          case "Diagram":
            return [
              { type: "diagramWidget", id: result.widgetID },
              "widgetSettings",
            ];
          case "Table":
            return [
              { type: "tableWidget", id: result.widgetID },
              "widgetSettings",
            ];
          case "Default":
            return [
              { type: "dataWidget", id: result.widgetID },
              "widgetSettings",
            ];
          case "Map":
            return [
              { type: "mapWidget", id: result.widgetID },
              "widgetSettings",
            ];
          default:
            return [];
        }
      },
    }),
    applySettings: builder.mutation<
      { widgetType: WidgetTypes },
      DeleteWidgetFromDashboardRequest
    >({
      query: (payload) => ({
        url: "api/widgetSettings/ApplySettings",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        switch (result?.widgetType) {
          case "Diagram":
            return ["diagramWidget"];
          case "Table":
            return ["tableWidget"];
          case "Default":
            return ["dataWidget"];
          case "Filter":
            return ["filterWidget"];
          case "Map":
            return ["mapWidget"];
          // case "Video":
          //   return ["videoWidget"];
          default:
            return [];
        }
      },
    }),
    saveLayout: builder.mutation<any, { dashboardID: string; layout: string }>({
      query: (payload) => ({
        url: "api/Dashboard/SetLayout",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["layouts"],
    }),
    getWidgetTemplate: builder.query<WidgetTemplatesResponse, boolean>({
      query: (payload) => ({
        url: `api/SettingsTemplate/GetAll?IsShowDeleted=${payload}`,
      }),
      providesTags: ["WidgetTemplates"],
    }),
    deleteWidgetTemplate: builder.mutation<boolean, string>({
      query: (id) => ({
        url: "api/SettingsTemplate/Delete",
        method: "POST",
        body: { templateID: id },
      }),
      invalidatesTags: ["WidgetTemplates"],
    }),
    addTemplateWidget: builder.mutation<any, TemplateType>({
      query: (payload) => ({
        url: "api/settingsTemplate/save",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["selectOptions"],
    }),
    getCurrentTemplate: builder.mutation<any, string>({
      query: (payload) => ({
        url: "api/settingsTemplate/load",
        method: "POST",
        body: {
          templateID: payload,
        },
      }),
    }),
    getQueryCommandsData: builder.query<any, GetQueryCommandsDataRequest>({
      query: (payload) => ({
        url: "api/data/get",
        method: "POST",
        body: payload,
      }),
    }),
    createQuery: builder.mutation<{ query: string }, { queryObject: string }>({
      query: (payload) => ({
        url: "api/query/CreateQuery",
        method: "POST",
        body: payload,
      }),
    }),
    getOperandsDescription: builder.query<
      { id: string; name: string; symbol: string }[],
      void
    >({
      query: () => `api/Query/GetOperandsDescription`,
    }),
  }),
});

export const {
  useCreateQueryMutation,
  useGetWidgetSettingsQuery,
  useSaveWidgetSettingsMutation,
  useSaveLayoutMutation,
  useAddTemplateWidgetMutation,
  useGetCurrentTemplateMutation,
  useLazyGetQueryCommandsDataQuery,
  useApplySettingsMutation,
  useLazyGetWidgetTemplateQuery,
  useDeleteWidgetTemplateMutation,
  useGetOperandsDescriptionQuery,
} = widgetSettingsApiSlice;
