import React, { useEffect, useMemo } from "react";
import { LoadingScreen } from "../../../../../common";
import { ModalDataForm } from "./ModalDataForm";
import { DbaAlert, DbaTable } from "../../../../../../DbaComponents";
import { TMapObjectInfoModalContentProps } from "./Types";
import { useLazyGetMapObjectInfoQuery } from "../../../../../../features/serviceSlices/serviceHooks";
import { useAppSelector } from "../../../../../../utils/reduxHooks";
import { TTableHeaderColumn } from "../../../../../../features/serviceSlices/SharedTypes";
import { convertISODatesInCollectionToLocalStringByTimezonesShift } from "../../../../../../utils/helpers/dateTime";
import { useIntl } from "react-intl";
import { isFetchBaseQueryErrorTypeGuard } from "../../../../../../utils/TypeGuards";
import { ColumnDef } from "@tanstack/react-table";

export const MapObjectInfoModalContent: React.FC<
  TMapObjectInfoModalContentProps
> = ({
  isModalVertical,
  featureObjectId,
  mapObjectId,
  dashboardId,
  appTimezone,
}) => {
  const { type, startDate, endDate } = useAppSelector((state) => state.app);

  const intl = useIntl();

  const [getMapObjectInfo, mapObjectInfoResponse] =
    useLazyGetMapObjectInfoQuery();

  useEffect(() => {
    if (featureObjectId || featureObjectId === undefined) {
      getMapObjectInfo({
        startDate,
        endDate,
        periodType: type,
        objectId: featureObjectId ? featureObjectId : null,
        mapObjectID: mapObjectId,
        dashboardID: dashboardId!,
      });
    }
  }, [
    featureObjectId,
    getMapObjectInfo,
    startDate,
    endDate,
    type,
    mapObjectId,
    dashboardId,
  ]);

  const getHeaderColumn = (item: TTableHeaderColumn) => {
    switch (item.type) {
      case "Image":
        return {
          id: item.field,
          header: item.headerName,
          accessorFn: (row: any) => row[item.field],
          cell: (props: any) => {
            return (
              <a
                href={props.row.original[item.field]}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={props.row.original[item.field]}
                  height="100px"
                  alt={intl.messages["imageMissing"] as string}
                />
              </a>
            );
          },
          size: item.width,
        };
      case "Video":
      case "File":
        return {
          id: item.field,
          header: item.headerName,
          accessorFn: (row: any) => row[item.field],
          cell: (props: any) => {
            return (
              <a
                href={props.row.original[item.field]}
                target="_blank"
                rel="noreferrer"
              >
                {`${intl.messages["download"]}`}
              </a>
            );
          },
          size: 10,
        };
      default:
        return {
          id: item.field,
          header: item.headerName,
          accessorFn: (row: any) => row[item.field],
        };
    }
  };

  const getColumns = (): ColumnDef<any>[] => {
    if (mapObjectInfoResponse?.data) {
      if (mapObjectInfoResponse.data.headers) {
        //если есть заголовки справочника
        const columns = mapObjectInfoResponse.data.headers.map(
          (item: TTableHeaderColumn) => {
            return getHeaderColumn(item);
          }
        );
        return columns;
      } // если нет заголовокв справочника
      else {
        const rowToDefineHeader =
          mapObjectInfoResponse.data?.data?.[0]?.data?.[0];
        if (rowToDefineHeader) {
          const columnsKeys = Object.keys(rowToDefineHeader);
          return columnsKeys.map((key) => ({
            id: key,
            header: key.toUpperCase(),
            accessorFn: (row: any) => row[key],
          }));
        } else {
          return [];
        }
      }
    } else {
      return [];
    }
  };

  const columns = getColumns();

  const modalDataItems = useMemo(
    () =>
      mapObjectInfoResponse?.data
        ? convertISODatesInCollectionToLocalStringByTimezonesShift({
            collection: mapObjectInfoResponse?.data?.data?.[0]?.data ?? [],
            headers: mapObjectInfoResponse?.data?.headers ?? [],
            originalTZ: mapObjectInfoResponse?.data?.timeZoneId,
            targetTZ: appTimezone,
          })
        : [],
    [appTimezone, mapObjectInfoResponse.data]
  );

  const resultData = useMemo(
    () => ({
      ...mapObjectInfoResponse.data,
      data:
        [
          {
            ...((mapObjectInfoResponse?.data?.data ?? [])[0] ?? {}),
            data: modalDataItems,
          },
        ] ?? [],
    }),
    [mapObjectInfoResponse?.data, modalDataItems]
  );

  const modalData = resultData.data[0].data ?? undefined;

  if (
    mapObjectInfoResponse.isLoading ||
    mapObjectInfoResponse.isUninitialized
  ) {
    return <LoadingScreen />;
  } else {
    if (mapObjectInfoResponse.isError) {
      if (isFetchBaseQueryErrorTypeGuard(mapObjectInfoResponse.error)) {
        return (
          <DbaAlert
            severity="error"
            text={mapObjectInfoResponse.error.data.message}
          />
        );
      }
      return (
        <DbaAlert
          severity="error"
          text={intl.messages["errorDataFetching"] as string}
        />
      );
    }

    if (modalData) {
      return isModalVertical ? (
        <ModalDataForm data={modalData} headers={resultData.headers} />
      ) : (
        <DbaTable
          id="map-objects-info-modal-form-table"
          data={modalData}
          columns={columns}
          pagination={{
            autoResetPagination: false,
          }}
          isLoading={mapObjectInfoResponse.isFetching}
        />
      );
    } else {
      return <DbaAlert severity="warning" text="emptyData" />;
    }
  }
};
