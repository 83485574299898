import { rootApi } from "../serviceRoot";
import { MapLayerType, TGetMapObjectInfoRequest } from "./Types";

export const mapLayerApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllMapLayers: builder.query<MapLayerType[], void>({
      query: () => "api/MapLayer/GetAll",
      providesTags: ["mapLayers"],
    }),
    addNewMapLayer: builder.mutation({
      query: (payload) => ({
        url: "api/MapLayer/Create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["mapLayers"],
    }),
    updateMapLayer: builder.mutation({
      query: (payload) => ({
        url: "api/MapLayer/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["mapLayers"],
    }),
    deleteMapLayer: builder.mutation({
      query: (payload) => ({
        url: "api/MapLayer/Delete",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["mapLayers"],
    }),
    getMapLayerById: builder.mutation({
      query: (id) => ({
        url: `api/MapLayer/Get?ID=${id}`,
      }),
    }),
    getMapObjectInfo: builder.query<any, TGetMapObjectInfoRequest>({
      query: (params) => {
        return {
          url: `api/MapObject/GetObjectInfo`,
          params: {
            mapObjectID: params.mapObjectID,
            dashboardID: params.dashboardID,
            startDate: params.startDate,
            endDate: params.endDate,
            periodType: params.periodType,
            objectId: params.objectId,
            "SortOptions.ColumnName": params.sortOptions?.columnName,
            "SortOptions.OrderType": params.sortOptions?.orderType,
          },
        };
      },
    }),
  }),
});

export const {
  useGetAllMapLayersQuery,
  useAddNewMapLayerMutation,
  useUpdateMapLayerMutation,
  useDeleteMapLayerMutation,
  useGetMapLayerByIdMutation,
  useLazyGetMapObjectInfoQuery,
} = mapLayerApiSlice;
