import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DbaSimpleSelect,
  DbaTextField,
  DbaButton,
  DbaQueryCommands,
  DbaDataFetchSelect,
  DbaCheckbox,
  DbaIconButton,
  DbaColorInput,
} from "../../DbaComponents";
import { Stack, Slider, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import {
  useCreateMapObjectMutation,
  useUpdateMapObjectMutation,
  useLazyGetMapObjectQuery,
} from "../../features/serviceSlices/serviceHooks";
import {
  SaveButton,
  LoadingScreen,
  ErrorMessage,
} from "../../components/common";
import { useIntl } from "react-intl";
import {
  MapObjectType,
  MapActionTypes,
} from "../../features/serviceSlices/MapObjects/Types";
import {
  QueryCommandObject,
  TFilterDefaultValueFromJson,
} from "../../features/serviceSlices/SharedTypes";
import {
  CSSErrorMessagesContainer,
  CSSFieldsContainer,
  CSSLabel,
  CSSInputBlock,
  CSSSelectReferenceContainer,
  CSSColorInput,
  CSSSliderContainer,
  CSSSlider,
  CSSSliderInput,
} from "./MapObjects.styles";
import { MapObjectActionTypes } from "./MapObject.options";
import ClearIcon from "@mui/icons-material/Clear";
import { DBAMarkerTypeInput } from "../../DbaComponents/DBAMarkerTypeInput/DBAMarkerTypeInput";
import {
  MarkersValuesType,
  OptionType,
} from "../../DbaComponents/DBAMarkerTypeInput/Types";
import { getReferenceHeaderResponse } from "../../features/serviceSlices/ReferenceHeader/Types";
import { parseJSON } from "../../utils/helpers/functions";

const DEFAULT_COLOR = "#6F9FD8";

const DEFAULT_MARKER_SIZE_MULTIPLIER = 1;
const MARKER_SIZE_MULTIPLIER_MIN = 0.3;
const MARKER_SIZE_MULTIPLIER_MAX = 10;
const MARKER_SIZE_MULTIPLIER_STEP = 0.1;

const DEFAULT_BORDER_SIZE = 1;
const BORDER_SIZE_MIN = 1;
const BORDER_SIZE_MAX = 15;

const DEFAULT_OPACITY = 50;
const OPACITY_MIN = 1;
const OPACITY_MAX = 100;

const FEATURE_CUSTOM_COLOR = false;

const DEFAULT_MARKER_VALUE = "pin1";
const DEFAULT_MARKER_VALUE_ON_UPDATE = "square";

const DEFAULT_COLOR_FIELD = "color";

export const MapObject = () => {
  const params = useParams<Record<string, string | undefined>>();
  const navigate = useNavigate();
  const editMode = Boolean(params.id);
  const intl = useIntl();

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState<string>(DEFAULT_COLOR);
  const [colorField, setColorField] = useState<string>(DEFAULT_COLOR_FIELD);
  const [marker, setMarker] = useState<MarkersValuesType | null>(
    DEFAULT_MARKER_VALUE
  );
  const [markerSizeMultiplier, setMarkerSizeMultiplier] = useState<number>(
    DEFAULT_MARKER_SIZE_MULTIPLIER
  );
  const [borderSize, setBorderSize] = useState<number>(DEFAULT_BORDER_SIZE);
  const [opacity, setOpacity] = useState<number>(DEFAULT_OPACITY);
  const [featureCustomColor, setFeatureCustomColor] =
    useState<boolean>(FEATURE_CUSTOM_COLOR);
  const [actionType, setActionType] = useState<MapActionTypes | null>("Object");
  const [variable, setVariable] = useState("");
  const [variableDefaultValue, setVariableDefaultValue] =
    useState<TFilterDefaultValueFromJson>();
  const [reference, setReference] = useState<getReferenceHeaderResponse | null>(
    null
  );
  const [queriesCommands, setQueriesCommands] = useState<
    QueryCommandObject[] | []
  >([]);
  const [queriesCommandsInfo, setQueriesCommandsInfo] = useState<
    QueryCommandObject[] | []
  >([]);
  const [selectedType, setSelectedType] = useState<MapObjectType | null>(null);

  const [getMapObjectData, mapObjectData] = useLazyGetMapObjectQuery();
  const [addMapObject, response] = useCreateMapObjectMutation();
  const [updateMapObject, updateResponse] = useUpdateMapObjectMutation();
  const [isModalVertical, setIsModalVertical] = useState(false);

  const [error, setError] = useState(false);

  const onSubmitMapObjectFormHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (
      editMode
        ? updateResponse.status === "pending" ||
          updateResponse.status === "fulfilled"
        : response.status === "pending" || response.status === "fulfilled"
    )
      return;
    if (
      !name.trim() ||
      !selectedType?.id ||
      queriesCommands.length === 0 ||
      (actionType === "Filter" && variable === "") ||
      (actionType === "Information" && queriesCommandsInfo.length === 0) ||
      !actionType ||
      (featureCustomColor && !colorField)
    ) {
      setError(true);
      return;
    }
    setError(false);
    const newMapObject = {
      id: editMode ? params?.id! : null,
      name: name.trim(),
      description: description.trim(),
      type: selectedType?.id ?? 0,
      queriesCommands,
      actionType,
      variable: variable.trim(),
      defaultValue: variableDefaultValue?.value
        ? JSON.stringify({
            ...variableDefaultValue,
            value: variableDefaultValue.value.trim(),
          })
        : undefined,
      queriesCommandsInfo,
      visualSettings: {
        key: "value",
        color,
        marker,
        size: markerSizeMultiplier,
        borderSize,
        opacity,
        featureCustomColor,
        colorField,
      },
      isModalVertical: isModalVertical ? isModalVertical : false,
      referenceHeaderID: reference?.id ? reference.id : null,
    };
    editMode ? updateMapObject(newMapObject) : addMapObject(newMapObject);
  };

  const onColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
  };

  const onMarkerInputChange = (value: OptionType | null) =>
    setMarker(value?.value ?? null);

  const onMarkerSizeMultiplierChange = (e: Event, value: number | number[]) =>
    setMarkerSizeMultiplier(value as number);

  const onBorderSizeChange = (e: Event, value: number | number[]) =>
    setBorderSize(value as number);

  const onOpacityChange = (e: Event, value: number | number[]) =>
    setOpacity(value as number);

  useEffect(() => {
    if (editMode) {
      getMapObjectData(params.id!);
    }
  }, [editMode, getMapObjectData, params.id]);

  useEffect(() => {
    if (mapObjectData.data) {
      setName(mapObjectData.data.name);
      setDescription(mapObjectData.data.description);
      setQueriesCommands(mapObjectData.data.queriesCommands);
      setColor(
        (mapObjectData.data?.visualSettings?.color as string) ?? DEFAULT_COLOR
      );
      setColorField(
        (mapObjectData.data?.visualSettings?.colorField as string) ??
          DEFAULT_COLOR_FIELD
      );
      setMarker(
        (mapObjectData.data?.visualSettings?.marker as string) ??
          DEFAULT_MARKER_VALUE_ON_UPDATE
      );
      setMarkerSizeMultiplier(
        (mapObjectData.data?.visualSettings?.size as number) ??
          DEFAULT_MARKER_SIZE_MULTIPLIER
      );
      setBorderSize(
        (mapObjectData.data?.visualSettings?.borderSize as number) ??
          DEFAULT_BORDER_SIZE
      );
      setOpacity(
        (mapObjectData.data?.visualSettings?.opacity as number) ??
          DEFAULT_OPACITY
      );
      setFeatureCustomColor(
        !!mapObjectData.data?.visualSettings?.featureCustomColor ??
          FEATURE_CUSTOM_COLOR
      );
      setActionType(mapObjectData.data.actionType);
      setIsModalVertical(mapObjectData.data.isModalVertical);
      mapObjectData.data?.variable && setVariable(mapObjectData.data.variable);
      setVariableDefaultValue(parseJSON(mapObjectData.data?.defaultValue));
      mapObjectData.data?.actionType &&
        setActionType(mapObjectData.data.actionType);
      mapObjectData.data?.queriesCommandsInfo &&
        setQueriesCommandsInfo(mapObjectData.data.queriesCommandsInfo);
    }
  }, [mapObjectData.data]);

  const mapObjectActionOptions =
    selectedType?.id === "Layer"
      ? MapObjectActionTypes
      : MapObjectActionTypes.filter(
          (actionType) => actionType.value !== "Filter"
        );

  if (editMode && mapObjectData.isError) {
    return <ErrorMessage />;
  }

  if (editMode && mapObjectData.isLoading) {
    return <LoadingScreen />;
  }

  return !editMode || (editMode && mapObjectData.isSuccess) ? (
    <Box component="form" onSubmit={onSubmitMapObjectFormHandler} noValidate>
      <Stack direction="row" spacing={2} sx={{ mb: "1rem" }}>
        <DbaButton
          onClick={() => navigate(-1)}
          color="error"
          variant="contained"
          text="cancel"
        />
        <SaveButton
          type="submit"
          redirectUrl="/mapobjects"
          status={editMode ? updateResponse.status : response.status}
        />
      </Stack>
      <Stack direction="column" spacing={2}>
        <CSSInputBlock>
          <CSSLabel>{intl.messages["mainFields"]}:</CSSLabel>
          <CSSFieldsContainer>
            <DbaTextField
              required
              error={error && !name.trim()}
              value={name}
              setValue={setName}
              label="name"
              helperText="fieldIsEmptyError"
            />
            <DbaTextField
              value={description}
              setValue={setDescription}
              label="description"
            />

            <DbaDataFetchSelect
              required
              error={error && !selectedType}
              url="api/types/Get?Name=MapObjectType"
              label="type"
              labelIndex="id"
              keyIndex={mapObjectData?.data?.type}
              selectedValue={selectedType}
              setSelectedValue={setSelectedType}
            />

            <DbaCheckbox
              checked={featureCustomColor}
              setChecked={setFeatureCustomColor}
              label="individualObjectsColor"
            />

            {!featureCustomColor ? (
              <CSSColorInput>
                <CSSLabel>{intl.messages["color"]}:</CSSLabel>
                <DbaColorInput value={color} onChange={onColorChange} />
              </CSSColorInput>
            ) : null}

            {featureCustomColor ? (
              <DbaTextField
                required
                error={error && !colorField.length}
                value={colorField}
                setValue={setColorField}
                label="fieldNameWithColor"
              />
            ) : null}

            <>
              <DBAMarkerTypeInput
                value={marker}
                handleChange={onMarkerInputChange}
                required={true}
              />

              <CSSSliderContainer widthClamp="100px, 265px, 300px">
                <p>{intl.messages["markerSizeMultiplier"]}</p>
                <CSSSliderInput width="51px">
                  <TextField disabled={true} value={markerSizeMultiplier} />
                </CSSSliderInput>
                <CSSSlider>
                  <Slider
                    valueLabelDisplay="auto"
                    min={MARKER_SIZE_MULTIPLIER_MIN}
                    max={MARKER_SIZE_MULTIPLIER_MAX}
                    step={MARKER_SIZE_MULTIPLIER_STEP}
                    value={markerSizeMultiplier}
                    onChange={onMarkerSizeMultiplierChange}
                  />
                </CSSSlider>
              </CSSSliderContainer>
            </>

            <>
              <CSSSliderContainer widthClamp="160px, 160px, 300px">
                <p>{intl.messages["lineWidth"]}</p>
                <CSSSliderInput width="56px">
                  <TextField disabled={true} value={borderSize} />
                </CSSSliderInput>
                <CSSSlider>
                  <Slider
                    valueLabelDisplay="auto"
                    min={BORDER_SIZE_MIN}
                    max={BORDER_SIZE_MAX}
                    value={borderSize}
                    onChange={onBorderSizeChange}
                  />
                </CSSSlider>
              </CSSSliderContainer>

              <CSSSliderContainer widthClamp="160px, 160px, 300px">
                <p>{intl.messages["opacity"]}</p>
                <CSSSliderInput width="56px">
                  <TextField disabled={true} value={opacity} />
                </CSSSliderInput>
                <CSSSlider>
                  <Slider
                    valueLabelDisplay="auto"
                    min={OPACITY_MIN}
                    max={OPACITY_MAX}
                    value={opacity}
                    onChange={onOpacityChange}
                  />
                </CSSSlider>
              </CSSSliderContainer>
            </>

            <DbaSimpleSelect
              label="MapObjectActionType"
              options={mapObjectActionOptions}
              selectedValue={actionType}
              setSelectedValue={setActionType}
              size="medium"
              error={error && !actionType}
              required
            />
            {actionType === "Filter" && actionType && (
              <>
                <DbaTextField
                  required
                  error={error && variable.length === 0}
                  value={variable}
                  setValue={setVariable}
                  label="variable"
                />
                <DbaTextField
                  value={variableDefaultValue?.value ?? ""}
                  setValue={(value) => {
                    setVariableDefaultValue({ value });
                  }}
                  label="defaultVariableValue"
                />
              </>
            )}
          </CSSFieldsContainer>
        </CSSInputBlock>
        {actionType === "Information" && (
          <CSSSelectReferenceContainer>
            <DbaDataFetchSelect
              url="api/referenceHeader/getAll"
              label="references"
              labelIndex="id"
              keyIndex={mapObjectData?.data?.referenceHeaderID}
              selectedValue={reference}
              setSelectedValue={setReference}
            />
            <DbaIconButton
              icon={<ClearIcon />}
              onClick={() => setReference(null)}
            />
          </CSSSelectReferenceContainer>
        )}
        <CSSLabel>{intl.messages["queriesCommands"]}:</CSSLabel>
        <DbaQueryCommands
          data={queriesCommands}
          setData={setQueriesCommands}
          label={name}
        />

        {actionType === "Information" && (
          <>
            <DbaCheckbox
              checked={isModalVertical}
              setChecked={setIsModalVertical}
              label="verticalModal"
            />
            <CSSLabel>{intl.messages["queriesCommandsInfo"]}:</CSSLabel>
            <DbaQueryCommands
              data={queriesCommandsInfo}
              setData={setQueriesCommandsInfo}
              label={name}
            />
          </>
        )}
      </Stack>
      <CSSErrorMessagesContainer>
        {error && queriesCommands.length === 0 && (
          <ErrorMessage title="queryCommandError" />
        )}
        {(updateResponse.isError || response.isError) && <ErrorMessage />}
      </CSSErrorMessagesContainer>
    </Box>
  ) : null;
};
