import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useIntl } from "react-intl";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import {
  CSSForm,
  CSSFormBox,
  CSSLoginFormContainer,
  CSSLogoBox,
  CSSLogoContainer,
  CSSPasswordContainer,
  CSSWelcomeText,
} from "./AuthStyles";
import { useRecoveryPasswordMutation } from "../../features/serviceSlices/serviceHooks";
import { FetchBaseQueryErrorType } from "../../features/serviceSlices/SharedTypes";

const SNACKBAR_HIDE_DURATION: number = 3000;
const REDIRECT_TIMEOUT_IF_SUCCESS: number = 3500;

export const RecoveryForm = () => {
  const params = useParams<Record<string, string | undefined>>();
  const intl = useIntl();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const [error, setError] = useState<boolean>(false);

  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

  const [recovery, recoveryResponse] = useRecoveryPasswordMutation();

  const isFetchBaseQueryErrorType = (
    error: any
  ): error is FetchBaseQueryErrorType => "status" in error;

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (
      password.length < 5 ||
      repeatPassword.length < 5 ||
      password !== repeatPassword
    ) {
      setError(true);
    } else {
      setError(false);
      recovery({ password: password, recoveryToken: params.id ?? "" });
    }
  };

  useEffect(() => {
    if (recoveryResponse.isError) {
      if (isFetchBaseQueryErrorType(recoveryResponse.error)) {
        setSnackbarMessage(
          recoveryResponse.error.data?.message
            ? recoveryResponse.error.data?.message
            : (intl.messages["error"] as string)
        );
        setOpenSnackbar(true);
      }
    } else if (recoveryResponse.isSuccess) {
      setSnackbarMessage(`${intl.messages["recoverySuccess"]}`);
      setOpenSnackbar(true);
      setTimeout(() => navigate("/login"), REDIRECT_TIMEOUT_IF_SUCCESS);
    }
  }, [recoveryResponse, intl.messages, navigate]);

  return (
    <CSSLoginFormContainer>
      <CSSFormBox>
        <CSSLogoContainer>
          <CSSLogoBox>
            <Logo />
          </CSSLogoBox>
          <CSSWelcomeText>{intl.messages["recoveryPassword"]}</CSSWelcomeText>
        </CSSLogoContainer>
        <CSSForm autoComplete="off" onSubmit={handleSubmit}>
          {/* 2 поля снизу необходимы для отключения автозаполнения тестовых инпутов в хроме */}
          <input
            style={{
              position: "absolute",
              bottom: 0,
              opacity: 0,
              left: 0,
              pointerEvents: "none",
            }}
            type="text"
            name="fakeusernameremembered"
          />
          <input
            style={{
              position: "absolute",
              bottom: 0,
              opacity: 0,
              left: 0,
              pointerEvents: "none",
            }}
            type="password"
            name="fakepasswordremembered"
          />
          <CSSPasswordContainer>
            <TextField
              error={error && password.length < 5}
              helperText={
                error && password.length === 0
                  ? intl.messages["requiredField"]
                  : error && password.length < 5
                  ? intl.messages["passwordLength"]
                  : ""
              }
              required
              type={showPassword ? "text" : "password"}
              inputProps={{ maxLength: 256 }}
              InputProps={{
                endAdornment:
                  password.length > 0 ? (
                    <InputAdornment
                      onClick={() => setShowPassword(!showPassword)}
                      position="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ cursor: "pointer" }} />
                      ) : (
                        <Visibility sx={{ cursor: "pointer" }} />
                      )}
                    </InputAdornment>
                  ) : (
                    ""
                  ),
              }}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Пароль"
              variant="outlined"
            />
            <TextField
              error={
                (error && repeatPassword.length < 5) ||
                (error && repeatPassword !== password)
              }
              helperText={
                error && repeatPassword.length === 0
                  ? intl.messages["requiredField"]
                  : error && repeatPassword.length < 5
                  ? intl.messages["passwordLength"]
                  : error && repeatPassword !== password
                  ? intl.messages["passwordMatch"]
                  : ""
              }
              required
              type={showRepeatPassword ? "text" : "password"}
              inputProps={{ maxLength: 256 }}
              InputProps={{
                endAdornment:
                  password.length > 0 ? (
                    <InputAdornment
                      onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                      position="end"
                    >
                      {showRepeatPassword ? (
                        <VisibilityOff sx={{ cursor: "pointer" }} />
                      ) : (
                        <Visibility sx={{ cursor: "pointer" }} />
                      )}
                    </InputAdornment>
                  ) : (
                    ""
                  ),
              }}
              fullWidth
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              label="Повторите пароль"
              variant="outlined"
            />
          </CSSPasswordContainer>
          <Button type="submit" variant="contained">
            {intl.messages["recovery"]}
          </Button>
          <Button onClick={() => navigate("/login")} variant="contained">
            {intl.messages["goToMain"]}
          </Button>
        </CSSForm>
      </CSSFormBox>
      <Snackbar
        autoHideDuration={SNACKBAR_HIDE_DURATION}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity={recoveryResponse.isError ? "error" : "success"}>
          {snackbarMessage.length !== 0
            ? snackbarMessage
            : intl.messages["success"]}
        </Alert>
      </Snackbar>
    </CSSLoginFormContainer>
  );
};
