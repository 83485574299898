import { useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import {
  useLazyGetDiagramWidgetDataQuery
} from "../../features/serviceSlices/serviceHooks";
import { ErrorMessage } from "../../components/common";
import { useAppSelector } from "../../utils/reduxHooks";
import { FullScreenDiagram } from "./FullScreenDiagram";
import Skeleton from "@mui/material/Skeleton";
import { GeoMap } from "../../components/Widgets/Widgets";
import {
  CSSFullScreenMapContainer,
  CSSWidgetContainer,
} from "./FullScreenWidget.styles";

export const FullScreenWidget = () => {
  const { startDate, endDate, type, groupingFunction, filters } =
    useAppSelector((state) => state.app);

  const [searchParams] = useSearchParams();
  const [getDiagramWidget, DiagramWidgetResponse] =
    useLazyGetDiagramWidgetDataQuery();

  const params = useParams();
  const widgetId = searchParams.get("id");
  const widgetType = searchParams.get("widgetType");

  useEffect(() => {
    if (
      widgetId &&
      params.dashboardID &&
      widgetType === "Diagram"
    ) {
      getDiagramWidget({
        widgetId,
        dashboardId: params.dashboardID,
        startDate,
        endDate,
        periodType: type,
        filters: JSON.stringify(filters),
      });
    }
  }, [
    endDate,
    filters,
    getDiagramWidget,
    groupingFunction,
    params.dashboardID,
    startDate,
    type,
    widgetId,
    widgetType,
  ]);

  const response = DiagramWidgetResponse;

  switch (widgetType) {
    case "Map":
      return widgetId ? (
        <CSSFullScreenMapContainer>
          <GeoMap id={widgetId} />
        </CSSFullScreenMapContainer>
      ) : null;
    case "Diagram":
    case "Default":
      if (response.isError) {
        return <ErrorMessage />;
      }
      if (response.isLoading) {
        return (
          <CSSWidgetContainer>
            <Skeleton
              sx={{ borderRadius: "8px" }}
              variant="rectangular"
              width="100%"
              height="100%"
            />
          </CSSWidgetContainer>
        );
      }
      return response.isSuccess ? (
        <CSSWidgetContainer>
          <FullScreenDiagram
            data={response.data.widgetData}
            isFetching={response.isFetching}
          />
        </CSSWidgetContainer>
      ) : null;
    default:
      return null;
  }
};
