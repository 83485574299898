import { useState } from "react";
import { MacrosType } from "../QueryConstructor.types";
import { CSSExpressionContainer } from "../QueryConstructor.styles";
import { DbaDataFetchSelect } from "../../../DbaComponents";
import { QueryConstructorModal } from "./QueryConstructorModal";

export const Macros = ({
  data,
  onSaveChanges,
  deleteHandler,
}: {
  data: MacrosType;
  onSaveChanges: (arg: MacrosType, initialState: MacrosType) => void;
  deleteHandler: (arg: MacrosType) => void;
}) => {
  const [macrosType, setMacrosType] = useState<
    { id: string; name: string } | string
  >(data.macrosType);

  const [open, setOpen] = useState(data.macrosType === "" ? true : false);
  const handleOpen = () => setOpen(true);
  const [error, setError] = useState(false);

  const onSaveExpressionHandler = () => {
    if (typeof macrosType === "object" && macrosType?.id) {
      onSaveChanges(
        {
          macrosType: macrosType.id,
        },
        data
      );
      setOpen(false);
    } else {
      setError(true);
    }
  };

  const onDeleteOrderHandler = () => {
    deleteHandler(data);
    setOpen(false);
  };

  const getMacrosTypeKeyIndex = () => {
    if (!macrosType) {
      return "";
    }
    if (typeof macrosType === "string") {
      return macrosType;
    }
    return macrosType.id;
  };

  return (
    <>
      <CSSExpressionContainer onClick={handleOpen}>
        Macros:
        <span>{data.macrosType}</span>
      </CSSExpressionContainer>
      <QueryConstructorModal
        open={open}
        title="WHERE - Macros"
        saveHandler={onSaveExpressionHandler}
        deleteHandler={onDeleteOrderHandler}
      >
        <DbaDataFetchSelect
          required
          error={error && !macrosType}
          disableClearable
          size="small"
          url={`api/Types/Get?Name=MacrosType`}
          label="macros"
          selectedValue={macrosType}
          setSelectedValue={setMacrosType}
          labelIndex="id"
          keyIndex={getMacrosTypeKeyIndex()}
        />
      </QueryConstructorModal>
    </>
  );
};
