import {
  Alert,
  Button,
  Snackbar,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { DbaModal, DbaTextField } from "../../DbaComponents";
import { useResetPasswordMutation } from "../../features/serviceSlices/serviceHooks";
import { useSnackbar } from "../../utils/hooks/useSnackbar";
import { isFetchBaseQueryErrorTypeGuard } from "../../utils/TypeGuards";

type ResetPasswordProps = {
  setOpen: (arg: any) => void;
  id: number | null;
};

const CLOSING_TIME_IF_SUCCESS: number = 2000;

export const ResetPassword = ({ setOpen, id }: ResetPasswordProps) => {
  const intl = useIntl();
  const [resetPassword, resetPasswordResponse] = useResetPasswordMutation();
  const [password, setPassword] = useState<string>("");
  const [checkedAutoGen, setCheckedAutoGen] = useState<boolean>(true);
  const { isShowSnackbar, snackbarMessage, snackbarSeverity, blinkSnackbar } =
    useSnackbar();

  const getSnackbar = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isShowSnackbar}
      autoHideDuration={null}
    >
      <Alert elevation={6} severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  const handleResetClick = () => {
    if (id !== undefined && id !== null) {
      resetPassword({ password, userId: id })
        .unwrap()
        .then(async () => {
          blinkSnackbar(`${intl.messages["success"]}`);
          setTimeout(() => {
            setOpen(null);
          }, CLOSING_TIME_IF_SUCCESS);
        })
        .catch((error) => {
          blinkSnackbar(
            isFetchBaseQueryErrorTypeGuard(error)
              ? error.data.message
              : (intl.messages["errorMessage"] as string),
            "error"
          );
        });
    }
  };

  const handleSwitchPassGen = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAutoGen(event.target.checked);
    setPassword("");
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <DbaModal
        onCloseHandler={handleClose}
        fullWidth
        title={intl.messages["resetPassword"] as string}
        open={!!id}
        setOpen={setOpen}
      >
        <Stack direction="column" spacing={2} sx={{ mb: "1rem" }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              onClick={handleClose}
              sx={{ width: "fit-content" }}
              color="error"
              variant="contained"
            >
              {intl.messages["cancel"]}
            </Button>
            <Button
              onClick={() => handleResetClick()}
              sx={{ width: "fit-content" }}
              variant="contained"
              disabled={password.length < 5 && !checkedAutoGen}
              color={resetPasswordResponse.isSuccess ? "success" : "primary"}
            >
              {resetPasswordResponse.isSuccess
                ? intl.messages["success"]
                : intl.messages["reset"]}
            </Button>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="button">
              {intl.messages["generatePassword"]}
            </Typography>
            <Switch
              checked={checkedAutoGen}
              onChange={handleSwitchPassGen}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>
          {!checkedAutoGen ? (
            <DbaTextField
              value={password}
              setValue={setPassword}
              size="medium"
              label="password"
              type="password"
              error={password.length < 5}
              helperText={intl.messages["passwordLength"] as string}
            />
          ) : (
            ""
          )}
        </Stack>
      </DbaModal>
      {getSnackbar}
    </>
  );
};
