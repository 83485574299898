import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DbaTable } from "../DbaTable/DbaTable";
import { DbaQueryCommand } from "./DbaQueryCommand";
import { DbaQueryCommandsProps } from "./DbaQueryCommand.types";
import { useIntl } from "react-intl";
import { CSSQueryCommandContainer } from "./DbaQueryCommand.styles";
import { ThemeContext } from "../../utils/ThemeContext";
import Typography from "@mui/material/Typography";
import { ColumnDef } from "@tanstack/react-table";
import { QueryCommandObject } from "../../features/serviceSlices/SharedTypes";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

export const DbaQueryCommands = ({
  widgetType,
  data,
  setData,
  label,
  showAddButton = true,
}: DbaQueryCommandsProps) => {
  const intl = useIntl();
  const [selectedCommand, setSelectedCommand] = useState<
    string | QueryCommandObject | null
  >(null);
  const [tableData, setTableData] = useState(data);
  const { darkMode } = useContext(ThemeContext);

  useEffect(() => setTableData(data), [data]);

  const onAddButtonClick = useCallback(() => {
    setSelectedCommand("new");
  }, []);

  const deleteHandler = useCallback(
    (obj) => {
      const newArr = [...tableData].filter((item) => item !== obj);
      setData(newArr);
    },
    [setData, tableData]
  );

  const editHandler = useCallback((data) => {
    setSelectedCommand(data);
  }, []);

  const columns: ColumnDef<QueryCommandObject>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.caption,
        size: Number.MAX_SAFE_INTEGER,
      },
      {
        id: "edition",
        header: "",
        size: 48,
        cell: (props) => (
          <EditButton
            editDataHandler={editHandler}
            row={props.row}
            searchField="widgetType"
          />
        ),
      },
      {
        id: "deletion",
        header: "",
        size: 48,
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
      },
    ],
    [deleteHandler, editHandler, intl.messages]
  );

  return selectedCommand ? (
    <CSSQueryCommandContainer darkMode={darkMode}>
      <Typography
        variant="h5"
        sx={{ textAlign: "center", paddingBottom: "1rem" }}
      >
        {intl.messages["queryCommandsManagement"]}
      </Typography>
      <DbaQueryCommand
        commandTitles={tableData.map((command) => command.caption)}
        widgetType={widgetType}
        data={selectedCommand}
        setSelectedQueryCommand={setSelectedCommand}
        setData={setData}
        defaultCaption={label}
      />
    </CSSQueryCommandContainer>
  ) : (
    <DbaTable
      columns={columns}
      data={tableData}
      headToolbar={{
        onAddButtonClick: showAddButton ? onAddButtonClick : undefined,
      }}
      stylesSettings={{ autoColumnsWidth: false }}
      fixControls={{ enableFixControls: false }}
    />
  );
};
