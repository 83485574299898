import { TDataWidgetData } from "../../../../features/serviceSlices/SpecificWidgets/DataWidget/Types";
import { roundNumber } from "../../../../utils/helpers/functions";

const getDifferenceValue = (data: TDataWidgetData) => {
  if (
    data?.prevValue === null ||
    data?.value === null ||
    !Boolean(data?.value?.[0]?.data?.[0]?.value)
  ) {
    return "";
  }

  if (data.prevValue[0].data === null)
    return "";

  if (data.prevValue[0].data.length === 0)
    return "";

  const rawDifferenceValue =
    data.value[0].data[0].value - data.prevValue[0].data[0].value;

  return data.roundToNumber >= 0
    ? roundNumber(rawDifferenceValue, data.roundToNumber)
    : rawDifferenceValue;
};

const getPreviousValue = (data: TDataWidgetData) => {
  if (
    data.prevValue &&
    typeof data.prevValue?.[0]?.data?.[0]?.value === "number"
  ) {
    const rawPreviousValue = data.prevValue?.[0].data?.[0]?.value;
    return data.roundToNumber >= 0
      ? roundNumber(rawPreviousValue, data.roundToNumber)
      : rawPreviousValue;
  }
  return "";
};

const getValue = (data: TDataWidgetData) => {
  const rawValue = data?.value?.[0]?.data?.[0]?.value;
  if (rawValue) {
    return data.roundToNumber >= 0
      ? roundNumber(rawValue, data.roundToNumber)
      : rawValue;
  }
  return "";
};

export { getDifferenceValue, getPreviousValue, getValue };
