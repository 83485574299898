import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DbaTextField,
  DbaButton,
  DbaQueryCommands,
  DbaDataFetchSelect,
  DbaIconButton,
} from "../../DbaComponents";
import { Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import {
  useCreateReportMutation,
  useUpdateReportMutation,
  useLazyGetReportQuery,
} from "../../features/serviceSlices/serviceHooks";
import {
  SaveButton,
  LoadingScreen,
  ErrorMessage,
} from "../../components/common";
import { useIntl } from "react-intl";
import {
  TCreateReportRequest,
  TUpdateReportRequest,
} from "../../features/serviceSlices/Reports/Types";
import { QueryCommandObject } from "../../features/serviceSlices/SharedTypes";
import {
  CSSErrorMessagesContainer,
  CSSFieldsContainer,
  CSSLabel,
  CSSInputBlock,
  CSSSelectReferenceContainer,
} from "./Reports.styles";
import ClearIcon from "@mui/icons-material/Clear";
import { getReferenceHeaderResponse } from "../../features/serviceSlices/ReferenceHeader/Types";

export const Report = () => {
  const params = useParams<Record<string, string | undefined>>();
  const navigate = useNavigate();
  const editMode = Boolean(params.id);
  const intl = useIntl();

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [reference, setReference] = useState<getReferenceHeaderResponse | null>(
    null
  );
  const [queriesCommands, setQueriesCommands] = useState<QueryCommandObject[]>(
    []
  );

  const [getReportData, reportData] = useLazyGetReportQuery();
  const [addReport, response] = useCreateReportMutation();
  const [updateReport, updateResponse] = useUpdateReportMutation();

  const [error, setError] = useState<boolean>(false);

  const onSubmitReportFormHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      editMode
        ? updateResponse.status === "pending" ||
          updateResponse.status === "fulfilled"
        : response.status === "pending" || response.status === "fulfilled"
    ) {
      return;
    }
    if (!name.trim() || !queriesCommands.length) {
      setError(true);
      return;
    }
    setError(false);
    const report = {
      id: editMode ? params?.id! : undefined,
      name: name.trim(),
      description: description.trim(),
      queriesCommands,
      referenceHeaderID: reference?.id ? reference.id : undefined,
      isDeleted: false,
    };
    editMode
      ? updateReport(report as TUpdateReportRequest)
      : addReport(report as TCreateReportRequest);
  };

  const onCancelClick = () => navigate(-1);

  const onClearReferenceClick = () => setReference(null);

  useEffect(() => {
    if (editMode) {
      getReportData({ id: params.id! });
    }
  }, [editMode, getReportData, params.id]);

  useEffect(() => {
    if (reportData.data) {
      setName(reportData.data.name);
      setDescription(reportData.data.description);
      setQueriesCommands(reportData.data.queriesCommands);
    }
  }, [reportData.data]);

  if (editMode && reportData.isError) {
    return <ErrorMessage />;
  }

  if (editMode && reportData.isLoading) {
    return <LoadingScreen />;
  }

  return !editMode || (editMode && reportData.isSuccess) ? (
    <Box component="form" onSubmit={onSubmitReportFormHandler} noValidate>
      <Stack direction="row" spacing={2} sx={{ mb: "1rem" }}>
        <DbaButton
          onClick={onCancelClick}
          color="error"
          variant="contained"
          text="cancel"
        />
        <SaveButton
          type="submit"
          redirectUrl="/reports"
          status={editMode ? updateResponse.status : response.status}
        />
      </Stack>
      <Stack direction="column" spacing={2}>
        <CSSInputBlock>
          <CSSLabel>{intl.messages["mainFields"]}:</CSSLabel>
          <CSSFieldsContainer>
            <DbaTextField
              required
              error={error && !name.trim()}
              value={name}
              setValue={setName}
              label="name"
              helperText="fieldIsEmptyError"
            />
            <DbaTextField
              value={description}
              setValue={setDescription}
              label="description"
            />
          </CSSFieldsContainer>
        </CSSInputBlock>
        <CSSSelectReferenceContainer>
          <DbaDataFetchSelect
            url="api/referenceHeader/getAll"
            label="references"
            labelIndex="id"
            keyIndex={reportData?.data?.referenceHeaderID}
            selectedValue={reference}
            setSelectedValue={setReference}
          />
          <DbaIconButton icon={<ClearIcon />} onClick={onClearReferenceClick} />
        </CSSSelectReferenceContainer>
        <CSSLabel>{intl.messages["queriesCommands"]}:</CSSLabel>
        <DbaQueryCommands
          data={queriesCommands}
          setData={setQueriesCommands}
          label={name}
        />
      </Stack>
      <CSSErrorMessagesContainer>
        {error && queriesCommands.length === 0 ? (
          <ErrorMessage title="queryCommandError" />
        ) : (
          <div />
        )}
        {updateResponse.isError || response.isError ? (
          <ErrorMessage />
        ) : (
          <div />
        )}
      </CSSErrorMessagesContainer>
    </Box>
  ) : null;
};
