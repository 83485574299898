import { DataWidgetProps } from "./DataWidget.types";
import { ValueSpan } from ".";
import {
  WidgetLayout,
  TextContainer,
  ValueBox,
  ValueContainer,
  MeasureContainer,
  ValueDifferenceContainer,
  PreviousValue,
} from "..";
import { TrendIcon } from "../../../common";
import {
  getDifferenceValue,
  getPreviousValue,
  getValue,
} from "./DataWidgetUtils";
import { useIntl } from "react-intl";

export const DataWidget = ({
  editMode,
  data,
  selectedElement,
  setSelectedElement,
}: DataWidgetProps) => {
  const intl = useIntl();

  const differenceValue = getDifferenceValue(data).toLocaleString(
    window.__env__.REACT_APP_LOCALE
  );

  const prevValue = getPreviousValue(data).toLocaleString(
    window.__env__.REACT_APP_LOCALE
  );

  const rawValueNumber = getValue(data);
  const value = rawValueNumber.toLocaleString(window.__env__.REACT_APP_LOCALE);

  const positiveTrend = () => {
    const rawValue = getDifferenceValue(data);
    if (
      typeof rawValue !== "number" ||
      !data.widgetSettings.settings.widget.difference?.positiveTrend ||
      rawValue === 0
    ) {
      return null;
    }
    switch (data.widgetSettings.settings.widget.difference?.positiveTrend) {
      case "decrease":
        return Math.sign(rawValue) === -1 ? true : false;
      case "increase":
        return Math.sign(rawValue) === -1 ? false : true;
    }
  };

  const isThresholdFontColor =
    data.widgetSettings.settings.widget?.colorScheme === "thresholds";

  const getShowedColor = () => {
    if (
      isThresholdFontColor &&
      typeof rawValueNumber === "number" &&
      data.widgetSettings.settings.widget?.thresholds
    ) {
      const obj = data.widgetSettings.settings.widget.thresholds.find(
        (item) => rawValueNumber > item.value
      );
      if (obj) {
        return obj?.color;
      }
      return data.widgetSettings.settings.widget.tresholdBaseColor ?? "#FF0000";
    }
    return data.widgetSettings.settings?.widget?.fontColor;
  };

  const showedFontColor = getShowedColor();
  const isGradient = isThresholdFontColor
    ? null
    : data.widgetSettings.settings?.widget?.gradientColor;

  return (
    <WidgetLayout
      settings={data.widgetSettings.settings.widget}
      label={data.label}
      data={data}
      type="Default"
      editMode={editMode}
      setSelectedElement={setSelectedElement}
      selectedElement={selectedElement}
      settingsId={data.widgetSettings.id}
      dashboardId={data.widgetSettings.dashboardID}
      widgetID={data.widgetSettings.widgetID}
      fontColor={showedFontColor}
      thresholdFontColor={isThresholdFontColor}
    >
      <TextContainer>
        <ValueBox>
          <ValueContainer
            fontColor={showedFontColor}
            gradientColor={isGradient}
            settings={data.widgetSettings.settings?.widget?.value}
          >
            <ValueSpan
              editMode={editMode}
              onClick={
                setSelectedElement
                  ? () => setSelectedElement("value")
                  : () => null
              }
              selected={selectedElement === "value"}
            >
              {value}
            </ValueSpan>{" "}
            <MeasureContainer
              editMode={editMode}
              selected={selectedElement === "measure"}
              onClick={
                setSelectedElement
                  ? () => setSelectedElement("measure")
                  : () => null
              }
              settings={data.widgetSettings.settings?.widget?.measure}
              fontColor={showedFontColor}
              gradientColor={isGradient}
            >
              {data.measure || ""}
            </MeasureContainer>
          </ValueContainer>
          <ValueDifferenceContainer
            editMode={editMode}
            selected={selectedElement === "difference"}
            onClick={
              setSelectedElement
                ? () => setSelectedElement("difference")
                : () => null
            }
            settings={data.widgetSettings.settings?.widget?.difference}
            positiveTrend={positiveTrend()}
            fontColor={showedFontColor}
            gradientColor={isGradient}
          >
            <TrendIcon
              transparentBackground={
                !data.widgetSettings.settings.widget.body.visible
              }
              customBackgroundColor={Boolean(
                data.widgetSettings.settings.widget.body.backgroundColor
              )}
              positiveTrend={positiveTrend()}
            />
            {differenceValue === "0"
              ? intl.messages["noChanges"]
              : differenceValue}
          </ValueDifferenceContainer>
        </ValueBox>
        <PreviousValue
          editMode={editMode}
          fontColor={showedFontColor}
          selected={selectedElement === "previousValue"}
          onClick={
            setSelectedElement
              ? () => setSelectedElement("previousValue")
              : () => null
          }
          settings={data.widgetSettings.settings?.widget?.previousValue}
          gradientColor={isGradient}
        >
          {`${prevValue} ${data.measure} ${data.prevValueCaption || ""}`}
        </PreviousValue>
      </TextContainer>
    </WidgetLayout>
  );
};
