import { useState, useEffect, useRef, useContext } from "react";
import {
  DbaTextField,
  DbaDataFetchSelect,
  DbaInputsGenerator,
  DbaButton,
  DbaTabPanel,
  DbaDataRangePicker,
  DbaErrorBoundary,
  DbaSnackbar,
} from "..";
import {
  useGetProviderInfoMutation,
  useLazyGetQueryCommandsDataQuery,
} from "../../features/serviceSlices/serviceHooks";
import { ProviderSettingsField } from "../../features/serviceSlices/Providers/Types";
import { QueryCommandObject } from "../../features/serviceSlices/SharedTypes";
import {
  CSSInputsContainer,
  CSSButtonsBlock,
  CSSGetTableDataContainer,
  CSSEditorContainer,
  CSSFieldsContainer,
  CSSGetTableDataRow,
} from "./DbaQueryCommand.styles";
import Editor from "@monaco-editor/react";
import { ThemeContext } from "../../utils/ThemeContext";
import {
  findUnfilledInput,
  cleanSettings,
} from "../../utils/helpers/functions";
import { ErrorMessage } from "../../components/common";
import { ScrollTop } from "./ScrollToTop";
import { useIntl } from "react-intl";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import GetAppIcon from "@mui/icons-material/GetApp";
import Skeleton from "@mui/material/Skeleton";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { DbaQueryCommandProps } from "./DbaQueryCommand.types";
import { DbaQueryCommandTable } from "./DbaQueryCommandTable";
import { DataSource } from "../../features/serviceSlices/DataSources/Types";
import DbaDictionaryInput from "../DbaDictionaryInput/DbaDictionaryInput";
import { DictionaryElement } from "../DbaDictionaryInput/DbaDictionaryInput.types";

export const DbaQueryCommand = ({
  data = {},
  setSelectedQueryCommand,
  setData,
  defaultCaption,
  widgetType,
  commandTitles,
}: DbaQueryCommandProps) => {
  const intl = useIntl();
  const editMode = Object.keys(data).length > 0 && data !== "new";
  const { darkMode } = useContext(ThemeContext);
  const editorRef = useRef(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dataSource, setDataSource] = useState<DataSource | null>(null);
  const [caption, setCaption] = useState(defaultCaption ? defaultCaption : "");
  const [dalScript, setDalScript] = useState("");
  const [command, setCommand] = useState("{}");
  const [filterList, setFilterList] = useState<DictionaryElement[]>([]);

  const [getProviderInfo, providerInfo] = useGetProviderInfoMutation();
  const [getTableData, tableDataResponse] = useLazyGetQueryCommandsDataQuery();
  const [commandSettingsField, setCommandSettingsField] = useState<
    ProviderSettingsField[] | []
  >([]);
  const [error, setError] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (editMode) {
      setCaption(data.caption);
      setDalScript(data.dataAccessLanguageScript);
      setCommand(data.command);
    }
  }, [editMode, data]);

  useEffect(() => {
    if (dataSource && dataSource.pluginType && dataSource.pluginType !== "") {
      getProviderInfo({ name: dataSource.pluginType });
    }
  }, [dataSource, getProviderInfo]);

  useEffect(() => {
    if (providerInfo.data?.commandSettingsField) {
      setCommandSettingsField(providerInfo?.data?.commandSettingsField!);
    }
  }, [providerInfo.data]);

  function handleEditorDidMount(editor: any) {
    editorRef.current = editor;
  }

  const isExistingCommandTitle = editMode
    ? commandTitles.filter((x) => x !== data.caption).indexOf(caption) !== -1
    : commandTitles.indexOf(caption) !== -1;
  const unfilledInput = findUnfilledInput(command, commandSettingsField);
  const missingFieldsExist =
    !caption.trim() || !dataSource || Boolean(unfilledInput);

  const onSaveHandler = () => {
    if (missingFieldsExist) {
      setError(true);
      typeof unfilledInput === "object" && Boolean(unfilledInput)
        ? setSnackbarMessage(
            `${intl.messages["missingField"]}: ${unfilledInput.caption}`
          )
        : setSnackbarMessage(intl.messages["missingField"] as string);
      return;
    }
    if (isExistingCommandTitle) {
      setError(true);
      return;
    }

    setData((prevState: QueryCommandObject[]) => {
      const newArr = prevState.filter((item) => item !== data);
      const newCommand: QueryCommandObject = {
        caption,
        command: cleanSettings(command, commandSettingsField),
        dataSourceId: dataSource?.id ? dataSource.id : "",
        dataAccessLanguageScript: dalScript,
      };
      newArr.push(newCommand);
      return newArr;
    });
    setSelectedQueryCommand(null);
  };
  const getTableDataHandler = () => {
    getTableData({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      queriesCommands: [
        {
          caption,
          command: cleanSettings(command, commandSettingsField),
          dataSourceId: dataSource?.id ? dataSource.id : "",
          dataAccessLanguageScript: dalScript,
        },
      ],
      filters: [
        Object.assign({}, ...filterList.map((x) => ({ [x.key]: x.value }))),
      ],
    });
  };

  const getErrorMessage = (error: any) => {
    if ("status" in error && error.data) {
      return error?.data?.message;
    } else {
      return intl.messages["checkDataRequest"];
    }
  };

  const getCaptionValidationText = (): string | undefined => {
    if (error && !!caption.trim()) return "queryCommandTitleError";
    if (error && !caption.trim()) return "fieldIsEmptyError";
  };

  const getContent = () => {
    if (tableDataResponse.isLoading) {
      return (
        <Skeleton
          sx={{ borderRadius: "4px" }}
          variant="rectangular"
          width="100%"
          height={330}
        />
      );
    }
    if (tableDataResponse.isError) {
      return (
        <Alert severity="error">
          <AlertTitle>{intl.messages["errorDataFetching"]}</AlertTitle>
          {getErrorMessage(tableDataResponse.error)}
        </Alert>
      );
    }
    if (tableDataResponse.isSuccess) {
      return (
        <DbaQueryCommandTable
          data={tableDataResponse.data}
          isFetching={tableDataResponse.isFetching}
        />
      );
    }

    return null;
  };

  return (
    <CSSInputsContainer>
      <CSSButtonsBlock darkMode={darkMode} id="back-to-top-anchor">
        <DbaButton
          startIcon={<CancelIcon />}
          color="error"
          text="cancel"
          onClick={() => setSelectedQueryCommand(null)}
        />
        <DbaButton
          startIcon={<SaveIcon />}
          text="save"
          onClick={onSaveHandler}
        />
      </CSSButtonsBlock>
      {error && missingFieldsExist && (
        <Alert severity="error">
          <AlertTitle>{snackbarMessage}</AlertTitle>
        </Alert>
      )}
      <Tabs variant="fullWidth" value={value} onChange={handleChange}>
        <Tab label={intl.messages["requests"]} />
        <Tab label={intl.messages["data"]} />
      </Tabs>
      <DbaTabPanel value={value} index={0}>
        <CSSFieldsContainer>
          <DbaTextField
            required
            error={error && (!caption.trim() || isExistingCommandTitle)}
            value={caption}
            setValue={setCaption}
            label="label"
            helperText={getCaptionValidationText()}
          />

          <DbaDataFetchSelect
            required
            error={error && !dataSource}
            url="api/datasource/getall"
            label="dataSource"
            selectedValue={dataSource}
            setSelectedValue={setDataSource}
            labelIndex="id"
            keyIndex={editMode ? data.dataSourceId : null}
          />

          {providerInfo.isError ? (
            <ErrorMessage />
          ) : (
            providerInfo.isSuccess &&
            commandSettingsField.length > 0 &&
            dataSource && (
              <DbaInputsGenerator
                fields={commandSettingsField}
                state={command}
                setState={setCommand}
                error={error}
                widgetType={widgetType}
                dataSourceId={dataSource.id}
              />
            )
          )}
        </CSSFieldsContainer>
        <CSSEditorContainer>
          <Typography variant="h6">{intl.messages["dalScript"]}</Typography>

          <Editor
            theme={darkMode ? "vs-dark" : "light"}
            height="50vh"
            defaultLanguage="sql"
            value={dalScript}
            onMount={handleEditorDidMount}
            onChange={(value) => setDalScript(value ?? "")}
            loading={
              <Skeleton
                sx={{ borderRadius: "4px" }}
                variant="rectangular"
                width="100%"
                height="100%"
              />
            }
            options={{
              scrollbar: {
                alwaysConsumeMouseWheel: false,
              },
            }}
          />
        </CSSEditorContainer>
        <ScrollTop />
      </DbaTabPanel>
      <DbaTabPanel value={value} index={1}>
        <CSSGetTableDataContainer>
          <DbaErrorBoundary>
            <DbaDictionaryInput
              data={filterList}
              setData={setFilterList}
              nameOfArrayPropertyWithKeys={"filters"}
              keyPropertyName={"variable"}
              initData={command}
            />
            <Typography>{intl.messages["chooseGetDataPeriod"]}</Typography>
            <CSSGetTableDataRow>
              <DbaDataRangePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
              <DbaButton
                startIcon={<GetAppIcon />}
                text="getData"
                onClick={getTableDataHandler}
              />
            </CSSGetTableDataRow>
            <DbaErrorBoundary resetKeys={[tableDataResponse.isFetching]}>
              {getContent()}
            </DbaErrorBoundary>
          </DbaErrorBoundary>
        </CSSGetTableDataContainer>
      </DbaTabPanel>
      <DbaSnackbar
        error={
          error &&
          Boolean(snackbarMessage) &&
          Boolean(findUnfilledInput(command, commandSettingsField))
        }
        errorMessage={snackbarMessage as string}
      />
    </CSSInputsContainer>
  );
};
