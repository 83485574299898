export type TimePeriods = "Day" | "Month" | "Year" | "Custom" | string;
export type TISODateString =
  | `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`
  | `${number}-${number}-${number}T${number}:${number}:${number}Z`;
export type TYYYYDDmmString = `${number}/${number}/${number}`;

export type ENUM = {
  id: string;
  name: string;
};

export type QueryCommandObject = {
  caption: string;
  command: string;
  dataAccessLanguageScript: string | null;
  dataSourceId: string | null;
};

export type FetchBaseQueryErrorType = {
  status: number;
  data: {
    message: string;
    innerExceptionMessage: string | null;
    innerException: string | null;
    type: string | null;
  };
};

export enum EReferenceHeaderDateFormats {
  DATE_AND_TIME = "Date",
  ONLY_DATE = "DateOnly",
}
export const TABLE_HEADER_COLUMN_FIELD_STRING_TYPE = "String";

export type TTableHeaderColumnFieldStringType =
  typeof TABLE_HEADER_COLUMN_FIELD_STRING_TYPE;
  
export type TTableHeaderColumnFieldType =
  | EReferenceHeaderDateFormats
  | TTableHeaderColumnFieldStringType
  | string;
export type TTableHeaderColumn = {
  field: string;
  type: TTableHeaderColumnFieldType;
  order: number;
  width: number;
  headerName: string;
};

export enum EErrorCodes {
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

declare global {
  interface Window {
    __env__: any;
  }
}

export type TRequestStatus =
  | "fulfilled"
  | "pending"
  | "rejected"
  | "uninitialized";

export type TFilterDefaultValueJson = string | undefined;
export type TFilterDefaultValueFromJson = Record<string, any> | undefined;
export type TFilterAddressRestriction = {
  region?: string;
  area?: string;
  city?: string;
  settlement?: string;
  street?: string;
  fullAddress?: string;
}
