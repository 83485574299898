import React, { useRef, useState, useEffect } from "react";
import { ErrorMessage } from "../../../components/common";
import {
  DbaTextField,
  DbaDataFetchSelect,
  DbaNumberInput,
  DbaSlider,
  DbaSelect,
  DbaQueryCommands,
  FetchSelect,
} from "../../../DbaComponents";
import { FirstStepBaseSettingsProps } from "./WidgetCreatingSteps.types";
import { WidgetStepperSkeleton } from "../StyledComponents";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import {
  Typography,
  Switch,
  FormControlLabel,
  Stack,
  Box,
} from "@mui/material";
import { FilterSelectTypeDefaultValue } from "../components/FilterSelectTypeDefaultValue";
import constants from "../../../utils/constants";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  AddressSuggestions,
  DaDataSuggestion,
  DaDataAddress,
} from "react-dadata";
import { FilterAddressRestrictions } from "../../../components/Widgets/common/FilterWidget/DataInputs/AddressInput/AddressRestrictions/FilterAddressRestrictions";
import { ENUM } from "../../../features/serviceSlices/SharedTypes";

const DELAY_TO_FILTER_APPLY = 700;
const MIN_CHARS_TO_FILTER_APPLY = 3;

const CSSSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CSSFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CSSDynamicPositioningSwitch = styled.div<{ isMarginBottom: boolean }>`
  margin-bottom: ${({ isMarginBottom }) => (isMarginBottom ? "18px" : "6px")};
`;

const StyledAddressInput: React.FC<{
  [key: string]: any;
  options: Record<string, any>;
}> = (props) => (
  <TextField
    {...props}
    sx={{ height: "20%" }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={props.options?.onCloserPress} size="small">
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

export const FirstStepBaseSettings = ({
  editMode,
  name,
  setName,
  label,
  setLabel,
  description,
  setDescription,
  widgetType,
  setWidgetType,
  referenceHeader,
  setReferenceHeader,
  widgetTypeResponse,
  measureResponse,
  measure,
  setMeasure,
  roundToNumber,
  setRoundToNumber,
  filterType,
  setFilterType,
  filterDefaultValue,
  setFilterDefaultValue,
  filterRestrictions,
  setFilterRestrictions,
  error,
  loading,
  success,
  variable,
  setVariable,
  clustering,
  setClustering,
  isDynamicPositioning,
  setIsDynamicPositioning,
  isShowTooltips,
  setIsShowTooltips,
  zoomLevel,
  setZoomLevel,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  mapLayers,
  setMapLayers,
  activeMapLayer,
  setActiveMapLayer,
  videoSourceUrl,
  setVideoSourceUrl,
  queriesCommands,
  setQueriesCommands,
}: FirstStepBaseSettingsProps) => {
  const intl = useIntl();
  const errorRoundToNumber =
    isNaN(roundToNumber) || roundToNumber < 0 || roundToNumber > 7;

  const [addressText, setAddressText] = useState<string>("");
  const [isDefaultValueFirstlySet, setIsDefaultValueFirstlySet] =
    useState<boolean>(false);
  const addressSuggestionsRef = useRef<AddressSuggestions>(null);
  const isFilter = widgetType?.id === "Filter";
  const isFilterTypeInputActive = isFilter && filterType === "Input";
  const isFilterTypeSelectActive = isFilter && filterType === "Select";
  const isFilterTypeAddressActive = isFilter && filterType === "Address";
  const onAddressChange = (data: DaDataSuggestion<DaDataAddress> | undefined) =>
    setFilterDefaultValue(
      data
        ? {
            value: data.value,
            id: data.data?.fias_id,
          }
        : undefined
    );

  const onAddressInputTextChange = (event: any) => {
    setAddressText(event.target.value);
  };

  const onAddressInputTextBlur = () => {
    if (!addressText) {
      onAddressReset();
    }
  };

  const onAddressInputTextKeyPress = (event: any) => {
    if (event.key === "Enter" && !addressText) {
      onAddressReset();
    }
  };

  const onAddressReset = () => {
    if (addressSuggestionsRef.current) {
      let addressSuggestionsNewState = {
        ...addressSuggestionsRef.current.state,
      };
      addressSuggestionsNewState.suggestions = [];
      addressSuggestionsRef.current.setState(addressSuggestionsNewState);
      addressSuggestionsRef.current.setInputValue("");
      setFilterDefaultValue(undefined);
    }
  };

  useEffect(() => {
    if (!addressText && !isDefaultValueFirstlySet) {
      const newAddressText =
        filterDefaultValue?.id && filterDefaultValue?.value
          ? filterDefaultValue.value
          : "";
      if (newAddressText) {
        setAddressText(
          filterDefaultValue?.id && filterDefaultValue?.value
            ? filterDefaultValue.value
            : ""
        );
        setIsDefaultValueFirstlySet(true);
      }
    }
  }, [
    addressText,
    filterDefaultValue?.id,
    filterDefaultValue?.value,
    isDefaultValueFirstlySet,
  ]);

  const clearDefaultValueCallback = () => {
    onAddressReset();
  };

  const clearDefaultFilterValue = () => {
    setFilterDefaultValue(undefined);
    const inputToClear: HTMLInputElement | null = document.querySelector(
      "#js-isFilterTypeInputActive-defaultValue input"
    );
    if (inputToClear) {
      inputToClear.value = "";
    }
  };

  const onChangeSelectedFilterType = (value: ENUM) => {
    setFilterType(value.id);
    clearDefaultFilterValue();
  };

  if (error) {
    return <ErrorMessage />;
  }
  if (loading) {
    return <WidgetStepperSkeleton />;
  }
  if (success) {
    return (
      <CSSFieldsContainer>
        <DbaTextField
          required
          label="name"
          value={name}
          setValue={setName}
          error={name.length !== 0 && !name.trim()}
          helperText="fieldIsEmptyError"
        />
        <DbaTextField
          required
          label="showedName"
          value={label}
          setValue={setLabel}
          error={label.length !== 0 && !label.trim()}
          helperText="fieldIsEmptyError"
        />
        <DbaTextField
          label="description"
          value={description}
          setValue={setDescription}
        />
        <DbaDataFetchSelect
          required
          url="api/Types/Get?Name=WidgetType"
          label="widgetType"
          selectedValue={widgetType}
          setSelectedValue={setWidgetType}
          labelIndex="id"
          keyIndex={widgetTypeResponse}
          disabled={editMode}
        />
        {widgetType?.id === "Table" ? (
          <DbaDataFetchSelect
            url="api/referenceHeader/getAll"
            label="references"
            labelIndex="id"
            keyIndex={referenceHeader?.id}
            selectedValue={referenceHeader}
            setSelectedValue={setReferenceHeader}
          />
        ) : null}
        {(widgetType?.id === "Default" || widgetType?.id === "Diagram") && (
          <DbaDataFetchSelect
            required
            url="api/measure/getall"
            label="measures"
            selectedValue={measure}
            setSelectedValue={setMeasure}
            labelIndex="id"
            keyIndex={measureResponse}
          />
        )}
        {widgetType?.id === "Default" && (
          <DbaNumberInput
            label="roundTo"
            error={errorRoundToNumber}
            value={roundToNumber}
            setValue={setRoundToNumber}
            helperText={errorRoundToNumber ? "rangeAacceptableValues" : ""}
          />
        )}
        {widgetType?.id === "Filter" && (
          <>
            <DbaTextField
              required
              label="variable"
              value={variable}
              setValue={setVariable}
            />
            <FetchSelect
              required
              label="filterType"
              url="api/Types/Get?Name=FilterType"
              optionLabel="name"
              optionValue="id"
              selectedValue={filterType}
              setSelectedValue={(value: ENUM) =>
                onChangeSelectedFilterType(value)
              }
            />
          </>
        )}
        {widgetType?.id === "Map" && (
          <>
            <CSSSliderContainer>
              <Typography>{intl.messages["clustering"]}:</Typography>
              <DbaSlider
                fullWidth
                value={clustering}
                min={1}
                max={20}
                onChange={(value) => setClustering(value as number)}
              />
            </CSSSliderContainer>
            <CSSDynamicPositioningSwitch isMarginBottom={isDynamicPositioning}>
              <Stack>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isDynamicPositioning}
                      onChange={(e) =>
                        setIsDynamicPositioning(e.target.checked)
                      }
                    />
                  }
                  label={
                    (isDynamicPositioning
                      ? intl.messages["dynamicPositioningEnabled"]
                      : intl.messages["dynamicPositioningDisabled"]) as string
                  }
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={isShowTooltips}
                      onChange={(e) => setIsShowTooltips(e.target.checked)}
                    />
                  }
                  label={
                    (isShowTooltips
                      ? intl.messages["isShowTooltipsEnabled"]
                      : intl.messages["isShowTooltipsDisabled"]) as string
                  }
                />
              </Stack>
            </CSSDynamicPositioningSwitch>
            {!isDynamicPositioning ? (
              <>
                <CSSSliderContainer>
                  <Typography>{intl.messages["zoomLevel"]}:</Typography>
                  <DbaSlider
                    fullWidth
                    value={zoomLevel}
                    min={1}
                    max={20}
                    step={0.25}
                    onChange={(value) => setZoomLevel(value as number)}
                  />
                </CSSSliderContainer>
                <DbaNumberInput
                  required
                  label="latitude"
                  value={latitude}
                  setValue={setLatitude}
                  min={-90}
                  max={90}
                />
                <DbaNumberInput
                  required
                  label="longitude"
                  value={longitude}
                  setValue={setLongitude}
                  min={-180}
                  max={180}
                />
              </>
            ) : null}
            <DbaDataFetchSelect
              multiple
              required
              url="api/MapLayer/GetAll"
              selectedValue={mapLayers}
              setSelectedValue={setMapLayers}
              label="mapLayers"
            />
            {mapLayers && mapLayers?.length > 0 && (
              <DbaSelect
                required
                selectedValue={activeMapLayer}
                setSelectedValue={setActiveMapLayer}
                options={mapLayers?.filter(
                  (mapLayer: any) => mapLayer?.id !== activeMapLayer?.id
                )}
                label="activeMapLayer"
              />
            )}
          </>
        )}
        {widgetType?.id === "Video" ? (
          <DbaTextField
            required
            label="videoStreamSourceUrl"
            value={videoSourceUrl}
            setValue={setVideoSourceUrl}
          />
        ) : null}
        {isFilterTypeInputActive ? (
          <Box id="js-isFilterTypeInputActive-defaultValue">
            <DbaTextField
              label={intl.messages["defaultValue"] as string}
              value={filterDefaultValue?.value ?? ""}
              setValue={(value) => {
                setFilterDefaultValue({ value });
              }}
            />
          </Box>
        ) : null}
        {isFilterTypeSelectActive ? (
          <>
            <DbaQueryCommands
              widgetType={widgetType.id as "Filter"}
              label={label}
              data={queriesCommands}
              setData={setQueriesCommands}
              showAddButton={queriesCommands.length < 1}
            />
            <FilterSelectTypeDefaultValue
              value={filterDefaultValue?.value}
              setValue={(value) => {
                setFilterDefaultValue({ value });
              }}
              queryCommand={queriesCommands[0]}
            />
          </>
        ) : null}
        {isFilterTypeAddressActive ? (
          <FilterAddressRestrictions
            filterRestrictions={filterRestrictions}
            setFilterRestrictions={setFilterRestrictions}
            clearDefaultValueCallback={clearDefaultValueCallback}
            filterDefaultValue={filterDefaultValue}
          />
        ) : null}
        {isFilterTypeAddressActive ? (
          <AddressSuggestions
            ref={addressSuggestionsRef}
            token={constants.daDataApiKey}
            defaultQuery={
              filterDefaultValue?.id && filterDefaultValue?.value
                ? filterDefaultValue.value
                : undefined
            }
            value={
              filterDefaultValue?.id && filterDefaultValue?.value
                ? (filterDefaultValue as DaDataSuggestion<DaDataAddress>)
                : undefined
            }
            onChange={onAddressChange}
            delay={DELAY_TO_FILTER_APPLY}
            minChars={MIN_CHARS_TO_FILTER_APPLY}
            filterFromBound="country"
            // Параметры в react-dadata не совпадают с параметрами оригинальной dadata
            // @ts-ignore
            filterToBound="house"
            inputProps={{
              onChange: onAddressInputTextChange,
              onBlur: onAddressInputTextBlur,
              onKeyDown: onAddressInputTextKeyPress,
              label: intl.messages["defaultValue"] as string,
              // По другому не передать кастомные свойства
              //@ts-ignore
              options: {
                onCloserPress: onAddressReset,
              },
            }}
            customInput={StyledAddressInput}
            filterLocations={filterRestrictions}
          />
        ) : null}
      </CSSFieldsContainer>
    );
  }
  return null;
};
