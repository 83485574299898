import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetWidgetSettingsQuery,
  useSaveWidgetSettingsMutation,
  useLazyGetDiagramWidgetDataQuery,
  useLazyGetTableWidgetDataQuery,
  useLazyGetDataWidgetDataQuery,
  useLazyGetFilterWidgetDataQuery,
  useLazyGetMapWidgetQuery,
} from "../../features/serviceSlices/serviceHooks";
import {
  SaveButton,
  LoadingScreen,
  ErrorMessage,
} from "../../components/common";
import { getInitialSettingsState } from "./InitialSettingsState";
import {
  TGetWidgetDataRequest,
  WidgetTypes,
} from "../../features/serviceSlices/Widget/Types";
import { TimePeriods } from "../../features/serviceSlices/SharedTypes";
import {
  CSSButtonBlock,
  CSSWidgetEditorContainer,
  CSSWidgetConstructorContainer,
} from "./WidgetEditor.styles";
import { DbaButton, DbaErrorBoundary } from "../../DbaComponents";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { WidgetEditorPeriodSelector } from "./WidgetEditorPeriodSelector";
import { DataWidgetConstructor } from "./Constructors/DataWidgetConstructor";
import { DiagramWidgetConstructor } from "./Constructors/DiagramWidgetConstructor";
import { TableWidgetConstructor } from "./Constructors/TableWidgetConstructor";
import { FilterWidgetConstructor } from "./Constructors/FilterWidgetConstructor";
import { MapWidgetConstructor } from "./Constructors/MapWidgetConstructor";
import { TDataWidgetData } from "../../features/serviceSlices/SpecificWidgets/DataWidget/Types";
import { TDiagramWidgetData } from "../../features/serviceSlices/SpecificWidgets/DiagramWidget/Types";
import { TFilterWidgetData } from "../../features/serviceSlices/SpecificWidgets/FilterWidget/Types";
import { TTableWidgetData } from "../../features/serviceSlices/SpecificWidgets/TableWidget/Types";
import { TMapWidgetResponse } from "../../features/serviceSlices/SpecificWidgets/MapWidget/Types";
import {
  createNewDateOnPeriodStart,
  createNewDateOnPeriodEnd,
} from "../../utils/helpers/dateTime";

const getWidgetConstructor = (
  widgetType: WidgetTypes,
  settings: any,
  setSettings: (arg: any) => void,
  widgetData:
    | TDataWidgetData
    | TDiagramWidgetData
    | TTableWidgetData
    | TFilterWidgetData
    | TMapWidgetResponse
) => {
  switch (widgetType) {
    case "Default":
      return (
        <DataWidgetConstructor
          settings={settings}
          setSettings={setSettings}
          widgetData={widgetData as TDataWidgetData}
        />
      );
    case "Diagram":
      return (
        <DiagramWidgetConstructor
          settings={settings}
          setSettings={setSettings}
          widgetData={widgetData as TDiagramWidgetData}
        />
      );
    case "Table":
      return (
        <TableWidgetConstructor
          settings={settings}
          setSettings={setSettings}
          widgetData={widgetData as TTableWidgetData}
        />
      );
    case "Filter":
      return (
        <FilterWidgetConstructor
          settings={settings}
          setSettings={setSettings}
          widgetData={widgetData as TFilterWidgetData}
        />
      );
    case "Map":
      return (
        <MapWidgetConstructor
          settings={settings}
          setSettings={setSettings}
          widgetData={widgetData as TMapWidgetResponse}
        />
      );
    default:
      return null;
  }
};

export const WidgetEditor = () => {
  const [getDiagramData, DiagramDataResponse] =
    useLazyGetDiagramWidgetDataQuery();
  const [getTableWidgetData, TableWidgetDataResponse] =
    useLazyGetTableWidgetDataQuery();
  const [getDataWidgetData, DataWidgetResponse] =
    useLazyGetDataWidgetDataQuery();
  const [getFilterWidgetData, FilterWidgetResponse] =
    useLazyGetFilterWidgetDataQuery();
  const [getMapWidgetData, MapWidgetResponse] = useLazyGetMapWidgetQuery();
  const params = useParams<Record<string, string>>();
  const navigate = useNavigate();
  const widgetSettings = useGetWidgetSettingsQuery({ settingsID: params.id! });
  const [settings, setSettings] = useState<any>(null);
  const [saveSettings, saveSettingsResponse] = useSaveWidgetSettingsMutation();
  const [startDate, setStartDate] = useState<Date>(
    createNewDateOnPeriodStart("Month")!
  );
  const [endDate, setEndDate] = useState<Date>(
    createNewDateOnPeriodEnd("Month")!
  );
  const [timePeriod, setTimePeriod] = useState<TimePeriods>("Day");

  const getData = useCallback(
    (widgetID: string, dashboardId: string, widgetType: WidgetTypes) => {
      const requestPayload: TGetWidgetDataRequest = {
        widgetId: widgetID,
        dashboardId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        periodType: timePeriod,
        filters: JSON.stringify([]),
      };
      switch (widgetType) {
        case "Diagram":
          getDiagramData(requestPayload);
          break;
        case "Table":
          getTableWidgetData(requestPayload);
          break;
        case "Default":
          getDataWidgetData(requestPayload);
          break;
        case "Filter":
          getFilterWidgetData(requestPayload);
          break;
        case "Map":
          getMapWidgetData({ widgetID });
          break;
        default:
          return;
      }
    },
    [
      endDate,
      getDataWidgetData,
      getDiagramData,
      getTableWidgetData,
      getFilterWidgetData,
      getMapWidgetData,
      startDate,
      timePeriod,
    ]
  );

  useEffect(() => {
    if (widgetSettings.data) {
      setSettings(widgetSettings.data.settings);
      getData(
        widgetSettings.data.widgetID,
        widgetSettings.data.dashboardID,
        widgetSettings.data.widgetType
      );
    }
  }, [getData, widgetSettings.data]);

  useEffect(() => {
    if (
      settings?.diagram?.diagramType &&
      widgetSettings.data?.widgetType &&
      widgetSettings.data?.widgetType === "Diagram" &&
      Object.keys(widgetSettings.data?.settings?.diagram).length === 0
    ) {
      setSettings(
        getInitialSettingsState(
          widgetSettings.data?.widgetType,
          settings.diagram?.diagramType
        )
      );
    }
  }, [
    settings?.diagram?.diagramType,
    widgetSettings.data?.settings?.diagram,
    widgetSettings.data?.widgetType,
  ]);

  const onSaveSettingsHandler = () => {
    if (
      widgetSettings.data?.id &&
      widgetSettings.data.dashboardID &&
      widgetSettings.data.widgetID
    ) {
      saveSettings({
        id: widgetSettings.data.id,
        dashboardID: widgetSettings.data.dashboardID,
        widgetID: widgetSettings.data.widgetID,
        settings,
      });
    }
  };

  const onPeriodChange = (
    event: React.BaseSyntheticEvent,
    value: TimePeriods
  ) => {
    if (value) {
      setTimePeriod(value);
    }
  };

  const loadingState =
    widgetSettings.isLoading ||
    DiagramDataResponse.isLoading ||
    TableWidgetDataResponse.isLoading ||
    DataWidgetResponse.isLoading ||
    FilterWidgetResponse.isLoading ||
    MapWidgetResponse.isLoading ||
    settings === null;

  const errorState =
    widgetSettings.isError ||
    DiagramDataResponse.isError ||
    TableWidgetDataResponse.isError ||
    DataWidgetResponse.isError ||
    FilterWidgetResponse.isError ||
    MapWidgetResponse.isError;

  const successState =
    widgetSettings.isSuccess &&
    (DiagramDataResponse.isSuccess ||
      TableWidgetDataResponse.isSuccess ||
      DataWidgetResponse.isSuccess ||
      FilterWidgetResponse.isSuccess ||
      MapWidgetResponse.isSuccess);

  const widgetData = (widgetType: WidgetTypes) => {
    switch (widgetType) {
      case "Diagram":
        return DiagramDataResponse.data?.widgetData as TDiagramWidgetData;
      case "Table":
        return TableWidgetDataResponse.data?.widgetData as TTableWidgetData;
      case "Filter":
        return FilterWidgetResponse.data?.widgetData as TFilterWidgetData;
      case "Map":
        return MapWidgetResponse.data as TMapWidgetResponse;
      default:
        return DataWidgetResponse.data?.widgetData as TDataWidgetData;
    }
  };

  const isDiagramDataEmpty =
    widgetSettings.data?.widgetType === "Diagram" &&
    DiagramDataResponse.data &&
    DiagramDataResponse.data.widgetData.data.find(
      (diagramDataObject) =>
        Array.isArray(diagramDataObject.data) &&
        diagramDataObject.data.length === 0
    );

  if (errorState) {
    return <ErrorMessage />;
  }
  if (loadingState) {
    return <LoadingScreen />;
  }
  if (successState && isDiagramDataEmpty) {
    return (
      <CSSWidgetEditorContainer>
        <DbaErrorBoundary>
          <WidgetEditorPeriodSelector
            onStartDateChange={(date) => setStartDate(date)}
            onEndDateChange={(date) => setEndDate(date)}
            onPeriodChange={onPeriodChange}
            startDate={startDate}
            endDate={endDate}
            type={timePeriod}
          />
        </DbaErrorBoundary>
      </CSSWidgetEditorContainer>
    );
  }
  return successState ? (
    <CSSWidgetEditorContainer>
      <DbaErrorBoundary>
        <CSSWidgetConstructorContainer>
          {getWidgetConstructor(
            widgetSettings.data.widgetType,
            settings,
            setSettings,
            widgetData(widgetSettings.data.widgetType)
          )}
        </CSSWidgetConstructorContainer>
      </DbaErrorBoundary>
      <CSSButtonBlock>
        <DbaButton
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          text="goBack"
          onClick={() => navigate(-1)}
        />
        <SaveButton
          redirectUrl={`/${widgetSettings.data?.dashboardID ?? ""}`}
          onClick={onSaveSettingsHandler}
          status={saveSettingsResponse.status}
        />
      </CSSButtonBlock>
    </CSSWidgetEditorContainer>
  ) : null;
};
